import React from 'react'
// lib
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { pure } from 'recompose'
// components
import { Label } from 'reactstrap'

const FormLabel = ({
    label,
    mandatory
}) => {
    return (
        <Label className={classnames('form-label', { 'champ-obligatoire': !mandatory })}>
            {label}
            {
                !mandatory && (
                    <FormattedMessage id="global.optional">
                        {
                            (message) => {
                                return <span className="label"> -&nbsp;{message}</span>
                            }
                        }
                    </FormattedMessage>
                )
            }
        </Label>
    )
}

FormLabel.propTypes = {
    label: PropTypes.node.isRequired,
    mandatory: PropTypes.bool
}

FormLabel.defaultProps = {
    mandatory: true
}

export default pure(FormLabel)