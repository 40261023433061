import { combineReducers } from 'redux'
import {
    GET_DEMANDE,
    GET_DEMANDE_ERROR,
    GET_DEMANDE_LOADING,
    UPDATE_DEMANDE
} from './demandeActionsTypes'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_DEMANDE_LOADING:
            return true
        case GET_DEMANDE:
        case GET_DEMANDE_ERROR:
            return false
        default:
            return state
    }
}

const error = (state = false, action) => {
    switch (action.type) {
        case GET_DEMANDE:
            return false
        case GET_DEMANDE_ERROR:
            return true
        default:
            return state
    }
}

const request = (state = null, action) => {
    switch (action.type) {
        case GET_DEMANDE:
        case UPDATE_DEMANDE:
            return action.payload
        default:
            return state
    }
}

const requestPage = combineReducers({
    error,
    loading,
    request
})

export default requestPage
