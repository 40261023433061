import {
    getDraftRequests as getDraftRequestsApi,
    updateHostingSpaces as updateHostingSpacesApi,
    updateBadgeType as updateBadgeTypeApi,
    updateDraftRequestStatus as updateDraftRequestStatusApi,
    addNote as addNoteApi,
    getDraftRequest as getDraftRequestApi,
    changeVisitorStatus as changeVisitorStatusApi,
    updateTypeIntervention as updateTypeInterventionApi
} from './api'
import { DRAFT_REQUEST_STATUS, DRAFT_REQUEST_STATUS_VALUES } from './const'

const prefix = 'DRAFTS/'

export const GET_DRAFT_REQUESTS = `${prefix}GET_DRAFT_REQUESTS`
export const GET_DRAFT_REQUESTS_LOADING = `${prefix}GET_DRAFT_REQUESTS_LOADING`
export const GET_DRAFT_REQUESTS_ERROR = `${prefix}GET_DRAFT_REQUESTS_ERROR`

export const GET_DRAFT_REQUEST = `${prefix}GET_DRAFT_REQUEST`
export const GET_DRAFT_REQUEST_LOADING = `${prefix}GET_DRAFT_REQUEST_LOADING`
export const GET_DRAFT_REQUEST_ERROR = `${prefix}GET_DRAFT_REQUEST_ERROR`

export const LOAD_MORE_DRAFT_REQUESTS = `${prefix}LOAD_MORE_DRAFT_REQUESTS`
export const LOAD_MORE_DRAFT_REQUESTS_LOADING = `${prefix}LOAD_MORE_DRAFT_REQUESTS_LOADING`
export const LOAD_MORE_DRAFT_REQUESTS_ERROR = `${prefix}LOAD_MORE_DRAFT_REQUESTS_ERROR`

export const ENABLE_REFRESH_DRAFT_REQUESTS = `${prefix}ENABLE_REFRESH`
export const DISABLE_DRAFT_REFRESH_DRAFT_REQUESTS = `${prefix}DISABLE_REFRESH`

export const OPEN_DRAFT_REQUEST = `${prefix}OPEN_DRAFT_REQUEST`
export const CLOSE_DRAFT_REQUEST = `${prefix}CLOSE_DRAFT_REQUEST`

export const UPDATE_DRAFT_REQUEST = `${prefix}UPDATE_DRAFT_REQUEST`
export const UPDATE_DRAFT_REQUEST_STATUS = `${prefix}UPDATE_DRAFT_REQUEST_STATUS`

const createGetDraftRequestsByStatusAction = (actionType) => (status, filters) => (dispatch) => {
    return getDraftRequestsApi({
        ...filters,
        statuts: status === DRAFT_REQUEST_STATUS.ALL_STATUS ? (filters.statuts ? [...filters.statuts] : DRAFT_REQUEST_STATUS_VALUES) : [status]
    })
        .then((response) => {
            dispatch({
                type: actionType,
                meta: {
                    status: status
                },
                payload: {
                    value: response.results,
                    next: response.sortValues,
                    nbTotal : response.nbResult
                }
            })
            return response
        })
}

export const getDraftRequestByStatus = createGetDraftRequestsByStatusAction(GET_DRAFT_REQUESTS)

export const enableRefreshDraftRequests = () => ({
    type: ENABLE_REFRESH_DRAFT_REQUESTS
})

export const disableRefreshDraftRequests = () => ({
    type: DISABLE_DRAFT_REFRESH_DRAFT_REQUESTS
})

export const openDraftRequest = (request) => (dispatch) => {
    // Suspendre le rafraîchissement quand on ouvre une demande
    dispatch(disableRefreshDraftRequests())
    dispatch({
        type: OPEN_DRAFT_REQUEST,
        payload: request.idDemande
    })
}

export const closeDraftRequest = () => (dispatch) => {
    dispatch({
        type: CLOSE_DRAFT_REQUEST
    })
}

const getDraftRequests = createGetDraftRequestsByStatusAction(GET_DRAFT_REQUESTS)
export const getDraftRequestsByListStatus = (listStatus, filters, withLoader = false) => (dispatch) => {
    if (withLoader) {
        dispatch({
            type: GET_DRAFT_REQUESTS_LOADING
        })
    }
    // Fermer la demande ouverte s'il y en a
    dispatch(closeDraftRequest())
    // Suspendre la tâche de rafraîchissement
    dispatch(disableRefreshDraftRequests())
    return Promise.all(listStatus.map(status => getDraftRequests(status, filters)(dispatch)))
        .then(res => {
            // Reprendre la tâche de rafraîchissement
            dispatch(enableRefreshDraftRequests())
            return res
        })
        .catch((e) => {
            dispatch({
                type: GET_DRAFT_REQUESTS_ERROR
            })
            throw e
        })
}

const loadMoreDraftRequests = createGetDraftRequestsByStatusAction(LOAD_MORE_DRAFT_REQUESTS)
export const loadMoreDraftRequestsByStatus = (status, filters) => (dispatch) => {
    dispatch({
        type: LOAD_MORE_DRAFT_REQUESTS_LOADING,
        meta: {
            status
        }
    })
    // Dès qu'on clique sur "voir plus", le rafraîchissement est désormais désactivé
    dispatch(disableRefreshDraftRequests())
    return loadMoreDraftRequests(status, filters)(dispatch)
        .catch((e) => {
            dispatch({
                type: LOAD_MORE_DRAFT_REQUESTS_ERROR,
                meta: {
                    status
                }
            })
            throw e
        })
}

export const updateHostingSpaces = (requestId, values) => (dispatch) => {
    return updateHostingSpacesApi(requestId, values)
        .then((request) => {
            dispatch({
                type: UPDATE_DRAFT_REQUEST,
                payload: request
            })
            return request
        })
}

export const updateBadgeType = (requestId, values) => (dispatch) => {
    return updateBadgeTypeApi(requestId, values)
        .then((request) => {
            dispatch({
                type: UPDATE_DRAFT_REQUEST,
                payload: request
            })
            return request
        })
}

export const updateTypeIntervention = (requestId, values) => (dispatch) => {
    return updateTypeInterventionApi(requestId, values)
        .then((request) => {
            dispatch({
                type: UPDATE_DRAFT_REQUEST,
                payload: request
            })
            return request
        })
}

export const updateDraftRequestStatus = (request, values) => (dispatch) => {
    return updateDraftRequestStatusApi(request.idDemande, values)
        .then((newRequest => {
            dispatch(enableRefreshDraftRequests())
            dispatch({
                type: UPDATE_DRAFT_REQUEST_STATUS,
                meta: {
                    status: request.statut
                },
                payload: newRequest
            })
            dispatch(closeDraftRequest())
            return newRequest
        }))
}

export const addNoteDraftRequest = (requestId, values) => (dispatch) => {
    return addNoteApi(requestId, values)
        .then((request) => {
            dispatch({
                type: UPDATE_DRAFT_REQUEST,
                payload: request
            })
            return request
        })
}

export const getDraftRequest = (requestId) => (dispatch) => {
    dispatch({
        type: GET_DRAFT_REQUEST_LOADING
    })
    return getDraftRequestApi(requestId)
        .then((request) => {
            dispatch({
                type: GET_DRAFT_REQUEST,
                payload : request
            })
            return request
        })
        .catch((e) => {
            dispatch({
                type: GET_DRAFT_REQUEST_ERROR
            })
            throw e
        })
}

export const changeVisitorStatus = (requestId, visitorId, values) => (dispatch) => {
    return changeVisitorStatusApi(requestId, visitorId, values)
        .then((request) => {
            dispatch({
                type: UPDATE_DRAFT_REQUEST,
                payload: request
            })
            return request
        })
}
