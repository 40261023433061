import { LOGIN, LOGOUT, UPDATE_NOTIFICATION_TYPE } from './userActions'
import { UPDATE_GROUP, REPLACE_GROUP } from '../group/actions'

const user = (state = {}, action) => {
	switch (action.type) {
		case LOGIN:
			return action.payload
        case UPDATE_GROUP: {
            // dans la cas où l'utilisateur modifie son propre groupe
            if (state.utilisateur.group.id === action.payload.id) {
                return {
                    ...state,
                    utilisateur: {
                        ...state.utilisateur,
                        group: action.payload
                    }
                }
            }
            return state
        }
        case REPLACE_GROUP: {
            // dans le cas où l'utilisateur supprime son propre groupe
            if (state.utilisateur.group.id === action.meta.deletedGroupId) {
                return {
                    ...state,
                    utilisateur: {
                        ...state.utilisateur,
                        group: action.payload
                    }
                }
            }
            return state
        }
        case UPDATE_NOTIFICATION_TYPE:
            return {
                ...state,
                utilisateur: action.payload
            }
		case LOGOUT:
		default:
			return state
	}
}
export default user

