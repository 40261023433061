import { combineReducers } from 'redux'
import {
    GET_VISITEURS_PERMANENTS,
    GET_VISITEURS_PONCTUELS,
    OPEN_DEMANDE,
    CLOSE_DEMANDE,
    EXPORT_DEMANDES_FINISH,
    EXPORT_DEMANDES_START,
    GET_DEMANDES,
    GET_DEMANDES_ERROR,
    GET_DEMANDES_LOADING,
    GET_DEMANDE,
    DISABLE_REFRESH_DEMANDES,
    ENABLE_REFRESH_DEMANDES
} from './demandeActionsTypes'
import requestFiltersReducer from './list/filters/requestFiltersReducer'
import { REQUEST_STATUS } from './const'
import createRequestsReducerByStatus from './createRequestsReducerByStatus'

const initialState = {
	visiteursPermanents: 0,
	visiteursPonctuels: 0
}

const visiteursPermanents = (state = initialState.visiteursPermanents, action) => {
	switch (action.type) {
		case GET_VISITEURS_PERMANENTS:
			return action.visiteursPermanents
		default:
			return state
	}
}

const allStatus = createRequestsReducerByStatus(REQUEST_STATUS.ALL_STATUS)
const open = createRequestsReducerByStatus(REQUEST_STATUS.OPEN)
const inProgress = createRequestsReducerByStatus(REQUEST_STATUS.IN_PROGRESS)
const close = createRequestsReducerByStatus(REQUEST_STATUS.CLOSE)

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_DEMANDES_LOADING:
            return true
        case GET_DEMANDES:
        case GET_DEMANDES_ERROR:
            return false
        default:
            return state
    }
}

const error = (state = false, action) => {
    switch (action.type) {
        case GET_DEMANDES_ERROR:
            return true
        case GET_DEMANDES_LOADING:
        case GET_DEMANDES:
            return false
        default:
            return state
    }
}

const selectedRequestId = (state = null, action) => {
    switch (action.type) {
        case OPEN_DEMANDE:
            return action.payload
        case CLOSE_DEMANDE:
            return null
        case GET_DEMANDE:
            return action.payload.idDemande
        default:
            return state
    }
}

const visiteursPonctuels = (state = initialState.visiteursPonctuels, action) => {
	switch (action.type) {
		case GET_VISITEURS_PONCTUELS:
			return action.visiteursPonctuels
		default:
			return state
	}
}

const exporting = (state = false, action) => {
    switch (action.type) {
        case EXPORT_DEMANDES_FINISH:
            return false
        case EXPORT_DEMANDES_START:
            return true
        default:
            return state
    }
}

const refreshEnabled = (state = true, action) => {
    switch (action.type) {
        case ENABLE_REFRESH_DEMANDES:
            return true
        case DISABLE_REFRESH_DEMANDES:
            return false
        default:
            return state
    }
}

export const visiteurs = combineReducers({
	visiteursPonctuels,
	visiteursPermanents
})

const demandes = combineReducers({
    [REQUEST_STATUS.ALL_STATUS]: allStatus,
    [REQUEST_STATUS.OPEN]: open,
    [REQUEST_STATUS.IN_PROGRESS]: inProgress,
    [REQUEST_STATUS.CLOSE]: close,
    loading,
    error,
    refreshEnabled
})

const selectedRequest = combineReducers({
    id: selectedRequestId
})

export const demandesReducer = combineReducers({
	demandes,
	filters: requestFiltersReducer,
    selectedRequest,
    exporting
})
