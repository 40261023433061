import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'

/**
 * Enhancer qui permet de créer un tooltip en composant wrappé.
 * WrappedComponent peut être un Component, stateless function ou string correspondant à un élément JSX
 * TODO trouver un moyen d'enregistrer le ref du composant wrappé
 */
const withTooltip = (WrappedComponent) => {
    class ComponentWithTooltip extends PureComponent {
        getTarget = () => {
            return this.tooltip
        }
        setRef = (tooltip) => {
            this.tooltip = tooltip
        }
    
        render() {
            const {
                tooltip,
                placement,
                trigger,
                ...props
            } = this.props
            return (
                <Fragment>
                    <span ref={this.setRef}>
                        {
                            React.createElement(WrappedComponent, {...props})
                        }
                    </span>
                    <UncontrolledTooltip
                        placement={placement}
                        trigger={trigger}
                        target={this.getTarget}
                    >
                        {tooltip}
                    </UncontrolledTooltip>
                </Fragment>
            )
        }
    }
    ComponentWithTooltip.propTypes = {
        tooltip: PropTypes.node,
        placement: PropTypes.string,
        trigger: PropTypes.string
    }
    ComponentWithTooltip.defaultProps = {
        placement: 'right',
        trigger: 'hover'
    }
    return ComponentWithTooltip
}

export default withTooltip