import {
    getGroupsPreview as getGroupsPreviewApi,
    exportGroups as exportGroupsApi,
    createGroup as createGroupApi,
    updateGroup as updateGroupApi,
    deleteAndReassignGroup as deleteAndReassignGroupApi,
    deleteGroup as deleteGroupApi
} from './api'
import middle from 'data4-commons/middlewares/api/middle'
import { getLabelPropertyName } from 'data4-commons/label/utils'

const prefix = 'GROUPS/'

export const GET_GROUPS_PREVIEW = `${prefix}GET_GROUPS_PREVIEW`
export const GET_GROUPS_PREVIEW_LOADING = `${prefix}GET_GROUPS_PREVIEW_LOADING`
export const GET_GROUPS_PREVIEW_ERROR = `${prefix}GET_GROUPS_PREVIEW_ERROR`

export const EXPORT_GROUPS_START = `${prefix}EXPORT_GROUPS_START`
export const EXPORT_GROUPS_FINISH = `${prefix}EXPORT_GROUPS_FINISH`

export const CREATE_GROUP = `${prefix}CREATE_GROUP`
export const UPDATE_GROUP = `${prefix}UPDATE_GROUP`
export const REPLACE_GROUP = `${prefix}REPLACE_GROUP`
export const DELETE_GROUP = `${prefix}DELETE_GROUP`
export const GET_GROUP = `${prefix}GET_GROUP`

export const getGroupsPreview = (lang) => (dispatch) => {
    dispatch({
        type: GET_GROUPS_PREVIEW_LOADING
    })
    return getGroupsPreviewApi(`${getLabelPropertyName(lang)},ASC`)
        .then((groups) => {
            dispatch({
                type: GET_GROUPS_PREVIEW,
                payload: groups
            })
            return groups
        })
        .catch((e) => {
            dispatch({
                type: GET_GROUPS_PREVIEW_ERROR
            })
            throw e
        })
}

export const exportGroups = () => (dispatch) => {
    dispatch({
        type: EXPORT_GROUPS_START
    })
    return exportGroupsApi()
        .then((res) => {
            dispatch({
                type: EXPORT_GROUPS_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_GROUPS_FINISH
            })
            throw e
        })
}

export const createGroup = (values) => (dispatch) => {
    return createGroupApi(values)
        .then((group) => {
            dispatch({
                type: CREATE_GROUP,
                payload: group
            })
            return group
        })
}

export const updateGroup = (groupId, values) => (dispatch) => {
    return updateGroupApi(groupId, values)
        .then((group) => {
            dispatch({
                type: UPDATE_GROUP,
                payload: group
            })
            return group
        })
}

export const deleteAndReassignGroup = (groupIdToDelete, values) => (dispatch) => {
    return deleteAndReassignGroupApi(groupIdToDelete, values)
        .then((newGroup) => {
            dispatch({
                type: REPLACE_GROUP,
                meta: {
                    deletedGroupId: groupIdToDelete
                },
                payload: newGroup
            })
        })
}

export const deleteGroup = (groupIdToDelete) => (dispatch) => {
    return deleteGroupApi(groupIdToDelete)
        .then(() => {
            dispatch({
                type: DELETE_GROUP,
                payload: groupIdToDelete
            })
        })
}

export const getGroup = (groupId) => middle(GET_GROUP, {
    api: '/api',
    url: `/groups/${groupId}`,
    method: 'GET'
})
