import React, { PureComponent } from 'react'
// lib
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose, mapProps, setPropTypes } from 'recompose'
// components
import { FormGroup } from 'reactstrap'
// internal components
import DateTimePicker from './DateTimePicker'
import { alignShape, dateValueShape, inputShape } from './shapes'
import mapErrors from './mapErrors'
// utils
import { startOf } from 'data4-commons/utils/dateUtils'

// TODO qletel trouver un moyen plus propre d'organiser les composants !
const EnhancedDateTimePicker = mapErrors(DateTimePicker)

const isStartDayValid = (start, min, max) => {
    let startValid = true
    if (start) {
        const startDate = new Date(start)
        if (startValid && min) {
            startValid = startDate >= new Date(min)
        }
        if (startValid && max) {
            startValid = new Date(max) >= startDate
        }
    }
    return startValid
}

const isEndDayValid = (start, end, min, max) => {
    let endValid = true
    if (end) {
        const endDate = new Date(end)
        if (start) {
            endValid = endDate >= new Date(start)
        }
        if (endValid && min) {
            endValid = endDate >= new Date(min)
        }
        if (endValid && max) {
            endValid = new Date(max) >= endDate
        }
    }
    return endValid
}

class DateRangePicker extends PureComponent {
    isStartDateValid = (current) => {
        const {
            min,
            max
        } = this.props
        const minDate = min ? startOf(new Date(min), 'day') : min
        const maxDate = max ? startOf(new Date(max), 'day') : max
        return isStartDayValid(current, minDate, maxDate)
    }
    isEndDateValid = (current) => {
        const {
            min,
            max,
            startDate: {
                input: {
                    value: start
                }
            }
        } = this.props
        const minDate = min ? startOf(new Date(min), 'day') : min
        const maxDate = max ? startOf(new Date(max), 'day') : max
        const startDate = start ? startOf(new Date(start), 'day') : start
        return isEndDayValid(startDate, current, minDate, maxDate)
    }
    render() {
        const {
            startDate,
            endDate,
            startFieldProps,
            endFieldProps,
            align,
            displayTimeZone,
            timeZone,
            timeZoneLabel,
            ...others
        } = this.props
        const inline = align === 'row'
        let startDateTimezoneProps
        if (!inline) {
            startDateTimezoneProps = {
                timeZone,
                timeZoneLabel
            }
        }
        return (
            <FormGroup className={classnames({ 'inline': inline }, 'date-pickers')}>
                <EnhancedDateTimePicker
                    {...others}
                    isValidDate={this.isStartDateValid}
                    {...startFieldProps}
                    {...startDate}
                    {...startDateTimezoneProps}
                />
                <EnhancedDateTimePicker
                    {...others}
                    isValidDate={this.isEndDateValid}
                    {...endFieldProps}
                    {...endDate}
                    timeZone={timeZone}
                    timeZoneLabel={timeZoneLabel}
                />
            </FormGroup>
        )
    }
}

DateRangePicker.propTypes = {
    /**
     * Alignement des radios, soit vertical ("col"), soit horizontal ("row")
     */
    align: alignShape,
    min: dateValueShape,
    max: dateValueShape,
    startDate: PropTypes.shape({
        input: inputShape.isRequired
    }).isRequired,
    endDate: PropTypes.shape({
        input: inputShape.isRequired
    }).isRequired,
    startValid: PropTypes.bool,
    endValid: PropTypes.bool,
    /**
     * Props suuplémentaires sur le champ startDate, ex. label
     */
    startFieldProps: PropTypes.object,
    /**
     * Props suuplémentaires sur le champ endDate, ex. label
     */
    endFieldProps: PropTypes.object
}

DateRangePicker.defaultProps = {
    align: 'row'
}

export default compose(
    setPropTypes({
        names: PropTypes.arrayOf(
            PropTypes.string
        ).isRequired,
    }),
    mapProps(
        ({
            names: [startFieldName, endFieldName],
            [startFieldName]: startDate,
            [endFieldName]: endDate,
            ...props
        }) => {
            return {
                startDate,
                startFieldName,
                endDate,
                endFieldName,
                ...props
            }
        })
)(DateRangePicker)
