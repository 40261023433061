import { GET_DASHBOARD_DRAFT_REQUESTS, GET_REQUESTS, HIDE_LAST_CONNEXION, SEE_LAST_CONNEXION } from './actions'
import { combineReducers } from 'redux'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const requests = createReducer(GET_REQUESTS)
const draftRequests = createReducer(GET_DASHBOARD_DRAFT_REQUESTS)

/**
 * Reducer pour gérer l'affichage de la bannière d'information sur la date de dernière connexion
 * @param state redux store
 * @param action information sur l'action effectuée
 */
const hideLastConnexion = (state = true, action) => {
    switch (action.type) {
        case SEE_LAST_CONNEXION:
            return false
        case HIDE_LAST_CONNEXION:
            return true
        default:
            return state
    }
}

const dashboard = combineReducers({
    requests,
    draftRequests,
    hideLastConnexion
})

export default dashboard
