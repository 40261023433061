import {
    exportUsers as exportUsersApi,
    createUser as createUserApi,
    updateUser as updateUserApi,
    deleteUser as deleteUserApi
} from './api'
import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'USERS/'

export const EXPORT_USERS_START = `${prefix}EXPORT_USERS_START`
export const EXPORT_USERS_FINISH = `${prefix}EXPORT_USERS_FINISH`

export const CREATE_USER = `${prefix}CREATE_USER`
export const UPDATE_USER = `${prefix}UPDATE_USER`
export const DELETE_USER = `${prefix}DELETE_USER`

export const GET_USER = `${prefix}GET_USER`

export const exportUsers = () => (dispatch) => {
    dispatch({
        type: EXPORT_USERS_START
    })
    return exportUsersApi()
        .then((res) => {
            dispatch({
                type: EXPORT_USERS_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_USERS_FINISH
            })
            throw e
        })
}

export const createUser = (values) => (dispatch) => {
    return createUserApi(values)
        .then((user) => {
            dispatch({
                type: CREATE_USER,
                payload: user
            })
            return user
        })
}

export const updateUser = (userId, values) => (dispatch) => {
    return updateUserApi(userId, values)
        .then((user) => {
            dispatch({
                type: UPDATE_USER,
                payload: user
            })
            return user
        })
}

export const deleteUser = (userId) => (dispatch) => {
    return deleteUserApi(userId)
        .then(() => {
            dispatch({
                type: DELETE_USER,
                payload: userId
            })
        })
}

export const getUser = (userId) => middle(GET_USER, {
    api: '/api',
    url: `/utilisateurs/${userId}`,
    method: 'GET'
})
