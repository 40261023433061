import React, { Component } from 'react'
import classnames from 'classnames'
import { Modal as ModalBootstrap, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Icon from '../Icon'

class Modal extends Component {
    render() {
        const {
            open,
            modal,
            centered,
            title,
            onClose,
            onClosed,
            footer,
            size,
            scrollable,
            className,
            children,
            cssModule,
            keyboard,
            backdrop,
            contentClassName
        } = this.props
        return (
            <ModalBootstrap
                isOpen={open}
                toggle={modal ? undefined : onClose}
                className={classnames(className, { 'modal-dialog-scrollable': scrollable })} // prop 'scrollable' est disponible dès reactstrap 8.0.0
                cssModule={cssModule}
                onClosed={onClosed}
                size={size}
                centered={centered}
                keyboard={keyboard}
                backdrop={backdrop}
                contentClassName={contentClassName}
            >
                {
                    title && (
                        <ModalHeader
                            close={modal ? undefined : (
	                            <Icon
                                    name="navigation-fermer"
                                    onClick={onClose}
                                />
                            )}
                            cssModule={cssModule}
                        >
                            {title}
                        </ModalHeader>
                    )
                }
                {
                    children && (
                        <ModalBody cssModule={cssModule}>
                            {children}
                        </ModalBody>
                    )
                }
                {
                    footer && (
                        <ModalFooter cssModule={cssModule}>
                            {footer()}
                        </ModalFooter>
                    )
                }
            </ModalBootstrap>
        )
    }
}

Modal.defaultProps = {
    centered: true
}

export default Modal
