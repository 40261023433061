import { ALL_ESCALATION_VALUE, ALL_TEAMS_VALUE, DEFAULT_REQUEST_STATUS_VALUES, STATIC_FILTERS_INITIAL_VALUES } from '../const'

export const transformFiltersPreference = (preference) => {
    if (!preference) {
        return STATIC_FILTERS_INITIAL_VALUES
    }
    const {
        request: {
            siteIds,
            team,
            escalation,
            statuts,
            types,
            clientIds,
            batiments
        }
    } = preference
    return {
        ...STATIC_FILTERS_INITIAL_VALUES,
        idsSociete: clientIds,
        siteIds: siteIds,
        equipe: team || ALL_TEAMS_VALUE,
        escalade: escalation || ALL_ESCALATION_VALUE,
        statuts: statuts || DEFAULT_REQUEST_STATUS_VALUES,
        typeDemandes: types,
        batiments: batiments
    }
}
