import React, { useEffect } from 'react'
import Loader from '../../../components/Loader'


const LoginOkta = () => {

    useEffect(() => {
        window.location.href = '/oauth2/authorization/okta'
    }, [])

    return (
        <Loader/>
    )
}

export default LoginOkta
