import { buildUrlWithParams } from 'data4-commons/entities/fetchEntities'
import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'SPREAD_CFA/'
export const GET_RACKS_BY_ROOM = `${prefix}GET_RACKS_BY_ROOM`
export const GET_PANELS_BY_RACK = `${prefix}GET_PANELS_BY_RACK`
export const GET_PORTS_BY_PANEL = `${prefix}GET_PORTS_BY_PANEL`
export const GET_CONNECTIONS_BY_PORT = `${prefix}GET_CONNECTIONS_BY_PORT`
export const GET_DUPLEX_CONNECTIONS_BY_PORT = `${prefix}GET_DUPLEX_CONNECTIONS_BY_PORT`

export const getRacksByRoom = (roomNlyteId) => middle(GET_RACKS_BY_ROOM, {
    api: '/api2',
    url: `/dcim/assets/racks/${roomNlyteId}`
})

export const getPanelsByRack = (rackId) => middle(GET_PANELS_BY_RACK, {
    api: '/api2',
    url: `/dcim/assets/panels/${rackId}`
})

export const getPortsByPanel = (panelId, patch) => middle(GET_PORTS_BY_PANEL, {
    api: '/api2',
    url: buildUrlWithParams(`/dcim/network/ports/${panelId}`, { patch })
})

export const getConnectionsByPort = (portId) => middle(GET_CONNECTIONS_BY_PORT, {
    api: '/api2',
    url: `/dcim/network/connections/${portId}`
})

export const getDuplexConnectionsByPort = (portId) => middle(GET_DUPLEX_CONNECTIONS_BY_PORT, {
    api: '/api2',
    url: `/dcim/network/connections/${portId}`
})
