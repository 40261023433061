import React, { PureComponent } from 'react'
import ReactSelect, { components } from 'react-select'
import { escapeRegExp } from 'data4-commons/utils/stringUtils'
import { injectIntl } from 'react-intl'
import warning from 'warning'

const CustomControl = (props) => (
    <components.Control
        {...props}
        className="form-control"
    />
)

const CustomMenu = (props) => (
    <components.Menu
        {...props}
        className="form-select-menu"
    />
)

const CustomMenuOption = ({
    children,
    ...props
}) => {
    return (
        <components.Option
            {...props}
            className="form-select-option"
        >
            {children}
        </components.Option>
    )
}

class Select extends PureComponent {
    filterOption = ({ data, label }, text) => {
        let searchMatch = true
        if (this.props.isSearchable) {
            const query = new RegExp(escapeRegExp(text), 'i')
            if (typeof label === 'string') {
                searchMatch = query.test(label)
            } else if (React.isValidElement(label) && label.props) {
                if (label.props.id) {
                    // FormattedMessage
                    const {
                        id,
                        values
                    } = label.props
                    searchMatch = query.test(this.props.intl.formatMessage({ id }, values))
                } else if (typeof label.props.children === 'string') {
                    // Html node
                    searchMatch = query.test(label)
                } else {
                    if (process.env.NODE_ENV !== 'production') {
                        warning(`Cannot get text from ${label}`)
                    }
                }
            }
        }
        if (data.access === undefined) {
            return searchMatch
        }
        if (typeof data.access === 'function') {
            return data.access() && searchMatch
        }
        return data.access && searchMatch
    }

    render() {
        const {
            disabled,
            components,
            value,
            changeValueOnBlur,
            ...props
        } = this.props
        return (
            <ReactSelect
                {...props}
                value={value === undefined ? null : value}
                classNamePrefix="form-select"
                isDisabled={disabled}
                filterOption={this.filterOption}
                components={{
                    Control: CustomControl,
                    Option: CustomMenuOption,
                    Menu: CustomMenu,
                    ...components
                }}
            />
        )
    }
}

Select.defaultProps = {
    placeholder: '',
    noOptionsMessage: () => null,
    menuPlacement: 'auto'
}

export default injectIntl(Select)

