import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import classnames from 'classnames'
import Icon from './Icon'

const Link = ({
	label,
	action,
	icon,
    type
}) => {
	return (
		<button
			className={classnames('link', `link-${type}`, {'link-icon': icon})}
			onClick={action}
            type="button"
		>
			<span className="label">{label}</span>
			{
				icon && <Icon name={icon} />
			}
		</button>
	)
}

Link.propTypes = {
	label: PropTypes.node.isRequired,
	action: PropTypes.func,
    /**
	 * Nom de l'icon
     */
	icon: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary'])
}

Link.defaultProps = {
    type: 'secondary'
}

export default pure(Link)
