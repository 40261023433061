import {
    addEquipements as addEquipementsApi,
    getEquipementsPage as getEquipementsPageApi,
    getEquipmentsSociete as getEquipmentsSocieteApi,
    exportEquipements as exportEquipementsApi,
    deleteEquipement as deleteEquipementApi,
    getEquipement as getEquipementApi,
    updateEquipement as updateEquipementApi
} from './api'

const prefix = 'EQUIPEMENTS/'
export const UPDATE_EQUIPEMENT = `${prefix}UPDATE_EQUIPEMENT`
export const GET_EQUIPEMENT = `${prefix}GET_EQUIPEMENT`
export const ADD_EQUIPEMENTS = `${prefix}ADD_EQUIPEMENTS`
export const DELETE_EQUIPEMENT = `${prefix}DELETE_EQUIPEMENT`
export const GET_EQUIPEMENTS_PAGE = 'GET_EQUIPEMENTS_PAGE'
export const GET_EQUIPEMENTS_PAGE_LOADING = 'GET_EQUIPEMENTS_PAGE_LOADING'
export const GET_EQUIPEMENTS_PAGE_ERROR = 'GET_EQUIPEMENTS_PAGE_ERROR'
export const GET_EQUIPEMENTS_SOCIETE = `${prefix}/GET_EQUIPEMENTS_SOCIETE`
export const GET_EQUIPEMENTS_SOCIETE_LOADING = `${prefix}/GET_EQUIPEMENTS_SOCIETE_LOADING`
export const GET_EQUIPEMENTS_SOCIETE_ERROR = `${prefix}/GET_EQUIPEMENTS_SOCIETE_ERROR`
export const EXPORT_EQUIPEMENTS_START = `${prefix}/GET_EQUIPEMENTS_SOCIETE_ERROR`
export const EXPORT_EQUIPEMENTS_FINISH = `${prefix}/EXPORT_EQUIPEMENTS_FINISH`
export const getEquipementsPage = (idSociete, params) => (dispatch) => {
    dispatch({
        type: GET_EQUIPEMENTS_PAGE_LOADING
    })
    return getEquipementsPageApi(idSociete, params)
        .then((equipements) => {
            dispatch({
                type: GET_EQUIPEMENTS_PAGE,
                payload: equipements
            })
            return equipements
        })
        .catch(e => {
            dispatch({
                type: GET_EQUIPEMENTS_PAGE_ERROR
            })
            throw e
        })
}

export const getEquipmentsSociete = (societeId) => (dispatch) => {
    dispatch({
        type: GET_EQUIPEMENTS_SOCIETE_LOADING
    })
    return getEquipmentsSocieteApi(societeId)
        .then(equipements => {
            dispatch({
                type: GET_EQUIPEMENTS_SOCIETE,
                payload: equipements
            })
        })
        .catch(e => {
            dispatch({
                type: GET_EQUIPEMENTS_SOCIETE_ERROR
            })
            throw e
        })
}

export const addEquipements = (values) => (dispatch) => {
    return addEquipementsApi(values)
        .then((equipements) => {
            dispatch({
                type: ADD_EQUIPEMENTS,
                payload: equipements
            })
            return equipements
        })
}

export const exportEquipments = () => (dispatch) => {
    dispatch({
        type: EXPORT_EQUIPEMENTS_START
    })
    return exportEquipementsApi()
        .then((res) => {
            dispatch({
                type: EXPORT_EQUIPEMENTS_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_EQUIPEMENTS_FINISH
            })
            throw e
        })
}

export const deleteEquipement = (idEquipement) => (dispatch) => {
    return deleteEquipementApi(idEquipement)
        .then((equipement) => {
            dispatch({
                type: DELETE_EQUIPEMENT,
                payload: equipement
            })
            return equipement
        })
}

export const getEquipement = (idEquipement) => (dispatch) => {
    return getEquipementApi(idEquipement)
        .then((equipement) => {
            dispatch({
                type: GET_EQUIPEMENT,
                payload: equipement
            })
            return equipement
        })
}

export const updateEquipement = (idEquipement, values) => (dispatch) => {
    return updateEquipementApi(idEquipement, values)
        .then((equipement) => {
            dispatch({
                type: UPDATE_EQUIPEMENT,
                payload: equipement
            })
            return equipement
        })
}
