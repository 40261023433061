import { createImmutableSelector } from 'data4-commons/utils'
import { Map } from 'immutable'
import { createSelector } from 'reselect'

export const _clientsSelector = state => state.app.clients.list

const clientsValueSelector = state => _clientsSelector(state).value

export const clientsMapSelector = createImmutableSelector([
    clientsValueSelector
], (clients) => (
	new Map().withMutations(map => {
		(clients || []).forEach(client => {
			map.set(client.idSociete, client)
		})
	})
))

export const clientsSelector = createImmutableSelector([
	clientsMapSelector
], (clients) => (
	clients
		.valueSeq()
		.sortBy(client => client.nom)
		.toArray()
))

export const createClientSelector = (idSelector) => createImmutableSelector([
	clientsMapSelector,
	idSelector
], (clients, idClient) => (
	clients.get(idClient)
))

const _selectedClientSelector = state => state.app.clients.selected
export const selectedClientLoadingSelector = state => _selectedClientSelector(state).loading
export const selectedClientErrorSelector = state => _selectedClientSelector(state).error
export const selectedReduxClientSelector = state => _selectedClientSelector(state).value

export const routeClientIdSelector = (state, props) => props.match.params.clientId

/**
 * Il faut {@link withRouter} en amont pour utiliser ce selector
 */
export const selectedClientSelector = createSelector([
    selectedReduxClientSelector,
    routeClientIdSelector
], (reduxClient, clientId) => {
    if (reduxClient && reduxClient.idSociete === clientId) {
        return reduxClient
    }
    return null
})
