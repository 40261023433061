import { UPDATE_DELIVERY_INSTRUCTIONS } from './actions'

const deliveryInformation = (state = [], action) => {
    switch (action.type) {
        case UPDATE_DELIVERY_INSTRUCTIONS:
            return action.payload
        default:
            return state
    }
}

export default deliveryInformation
