import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const logOut = () => fetchFactory('/utilisateurs/deconnexion', {
	method: 'POST'
}, '/api')

export const changerMDP = (body) => fetchFactory('/motdepasseconnecte/changerMDP', {
	method: 'POST',
	body: JSON.stringify(body)
}, '/api2')

export const changerLangue = (langue) => fetchFactory('/utilisateurs/majLangue', {
	method: 'POST',
	body: JSON.stringify({
		langue
	})
}, '/api')

export const changeNotification = (values) => fetchFactory('/utilisateurs/notification_type', {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')
