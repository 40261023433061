import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const exportUsers = () => fetch('/api/utilisateurs/export', {
    headers: {
        Accept: 'application/octet-stream'
    }
})

export const createUser = (values) => fetchFactory('/utilisateurs', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const updateUser = (userId, values) => fetchFactory(`/utilisateurs/${userId}`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const resetUserPassword = (userId) => fetchFactory(`/users/${userId}/resetPassword`, {
    method: 'PUT'
})

export const setUserPassword = (userId) => fetchFactory(`/users/${userId}/initPassword`, {
    method: 'PUT'
})

export const deleteUser = (userId) => fetchFactory(`/utilisateurs/${userId}`, {
    method: 'DELETE'
}, '/api')
