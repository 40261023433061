import React from 'react'
// lib
import { FormattedMessage } from 'react-intl'
import { reduxForm } from 'redux-form'
// components
import { Button } from 'reactstrap'
// internal components
import Headline from '../../../components/Headline'
import Icon from '../../../components/Icon'
import { Errors } from '../../../components/reduxForm'

const Activate2FASuccessPage = ({
    handleSubmit,
    submitting,
    error
}) => {
    return (
        <div className="modal">
            <div className="modal-dialog modal-xl">
                <Headline
                    label={<FormattedMessage id="login.2fa.title" />}
                    type="secondary"
                />
                <div className="empty-body">
                    <Icon name="page-2factors" />
                    <p className="contenu">
                        <FormattedMessage id="login.2fa.success.body" />
                    </p>
                    <Button
                        color="primary"
                        className="btn-uppercase"
                        onClick={handleSubmit}
                        disabled={submitting}
                    >
                        <FormattedMessage id="login.btn.access_portal" />
                    </Button>
                    <Errors errors={error} />
                </div>
            </div>
        </div>
    )
}

export default reduxForm({})(Activate2FASuccessPage)