import React, { Component } from 'react'
// lib
import { FormattedMessage } from 'react-intl'
// components
import { Container } from 'reactstrap'
// internal components
import Paper from '../../../components/Paper'
import EmptySpace from '../../../components/EmptySpace'
// api
import { getSupervision } from './api'

class WelcomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            supervision: {},
            error: false
        }
    }

    componentDidMount() {
        getSupervision()
            .then((supervision) => {
                this.setState({
                    supervision
                })
            }, () => {
                this.setState({
                    error: true
                })
            })
    }

    render() {
        const {
            supervision,
            error
        } = this.state
        return (
            <Container>
                <Paper className="paper-form">
                    {
                        error ? (
                            <EmptySpace
                                iconName="navigation-perte-connexion"
                                label={<FormattedMessage id="global.unknown_error" />}
                                legend={<FormattedMessage id="supervision.error.legend" />}
                            />
                        ) : (
                            Object.keys(supervision).map(key => (
                                <div>
                                    <FormattedMessage id={`supervision.${key}`} /> :&nbsp;
                                    <FormattedMessage id={`supervision.status.${supervision[key]}`} />
                                </div>
                            ))
                        )
                    }
                </Paper>
            </Container>
        )
    }
}

export default WelcomePage
