import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Input, Label } from 'reactstrap'
import Icon from '../Icon'

const Checkbox = ({
    label,
    input,
    meta,
    ...others
}) => {
    return (
        <div className="pretty p-icon p-curve p-smooth">
            <Input
                {...input}
                {...others}
                type="checkbox"
            />
            <div className="state p-success">
                <Icon
                    className="mdi mdi-check"
                    name="navigation-ok"
                />
                <Label className="label">
                    {label}
                </Label>
            </div>
        </div>
    )
}

Checkbox.propTypes = {
    label: PropTypes.node
}

export default pure(Checkbox)
