import React from 'react'
// lib
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { IntlProvider, intlReducer } from 'react-intl-redux'
import { addLocaleData } from 'react-intl'
import { reducer as formReducer } from 'redux-form'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
// components
import Root from './nonconnected/Root'
// utils
import appReducer from './app/appReducer'
import { getAppProperties } from 'data4-commons/api/properties/propertiesApi'
import { fetchFactory } from './nonconnected/entities/fetchEntities'
import { hasText } from 'data4-commons/utils/stringUtils'
import apiMiddleware from 'data4-commons/middlewares/api/apiMiddleware'
// const
import supportedLocales, { LOCALE_EN } from './i18n/supportedLocales'
import { default as momentSupportedLocales } from './moment/supportedLocales'
import formats from './i18n/formats'

import './scss/bootstrap.scss'
import './scss/App.scss'
import './polyfills'

const loadIntlLocales = (locales) => (
    Promise.all(locales.map(lang => import(`react-intl/locale-data/${lang}`)))
        .then((localeModules) => {
            localeModules.forEach(module => addLocaleData(module.default))
        })
)

const loadMomentLocales = (locales) => (
    Promise.all(locales.map(lang => import(`moment/locale/${lang}`)))
)

const getLocale = () => {
    if (hasText(navigator.language)) {
        return supportedLocales.find(locale => navigator.language.startsWith(locale)) || LOCALE_EN
    }
    return LOCALE_EN
}

const CLEAN = '@@CLEAN'

Promise.all([
    loadIntlLocales(supportedLocales),
    loadMomentLocales(momentSupportedLocales)
])
    .then(() => {

        getAppProperties()
            .then(properties => {

                const history = createBrowserHistory()

                const sagaMiddleware = createSagaMiddleware()

                let enhancers
                if (process.env.NODE_ENV === 'production') {
                    enhancers = [applyMiddleware(thunk, apiMiddleware, sagaMiddleware, routerMiddleware(history))]
                } else {
                    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
                        // utilisation de redux dev tools uniquement
                        enhancers = [applyMiddleware(thunk, apiMiddleware, sagaMiddleware, routerMiddleware(history)), window.__REDUX_DEVTOOLS_EXTENSION__()]
                    } else {
                        // à défaut, utilisation du redux-logger middleware
                        const loggerMiddleware = createLogger()
                        enhancers = [applyMiddleware(thunk, apiMiddleware, sagaMiddleware, loggerMiddleware, routerMiddleware(history))]
                    }
                }

                const lang = getLocale()

                const messages = require(`./i18n/messages_${lang}`).default

                const initialState = {
                    app: {
                        properties
                    },
                    intl: {
                        locale: lang,
                        defaultLocale: LOCALE_EN,
                        messages,
                        formats
                    }
                }

                const _rootReducer = combineReducers({
                    app: appReducer,
                    router: connectRouter(history),
                    intl: intlReducer,
                    form: formReducer
                })

                const rootReducer = (state, action) => {
                    switch (action.type) {
                        case CLEAN: {
                            return _rootReducer(initialState, action)
                        }
                        default:
                            return _rootReducer(state, action)
                    }
                }
                const store = createStore(rootReducer, initialState, compose(...enhancers))


                const redirectToLogin = () => {

                    const appContainer = document.getElementById('app')

                    // Démonter l'ancien app avant de nettoyer le store
                    ReactDOM.unmountComponentAtNode(appContainer)

                    // Clean store
                    store.dispatch({ type: CLEAN })

                    // Render App en non connectée
                    ReactDOM.render(
                        <Provider store={store}>
                            <IntlProvider>
                                <ConnectedRouter history={history}>
                                    <Root />
                                </ConnectedRouter>
                            </IntlProvider>
                        </Provider>, appContainer)
                }

                fetchFactory('/users/me')
                    .then(user => {
                        const {
                            bloque
                        } = user
                        if (bloque) {
                            redirectToLogin()
                        } else {
                            // connecté
                            import(/* webpackChunkName: "connecte" */ './connectedIndex').then((connected) => {
                                connected.reactInit(user, properties, { store, history, sagaMiddleware }, redirectToLogin)
                            })
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        redirectToLogin()
                    })
            })
    })
