import { GET_SYNTHSES_DEMANDES, GET_SYNTHSES_DEMANDES_ERROR, GET_SYNTHSES_DEMANDES_LOADING } from './demandeActionsTypes'
import { combineReducers } from 'redux'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_SYNTHSES_DEMANDES_LOADING:
            return true
        case GET_SYNTHSES_DEMANDES:
        case GET_SYNTHSES_DEMANDES_ERROR:
            return false
        default:
            return state
    }
}

const error = (state = false, action) => {
    switch (action.type) {
        case GET_SYNTHSES_DEMANDES:
            return false
        case GET_SYNTHSES_DEMANDES_ERROR:
            return true
        default:
            return state
    }
}

const syntheseDemande = (state = null, action) => {
    switch (action.type) {
        case GET_SYNTHSES_DEMANDES:
            return action.payload
        default:
            return state
    }
}

const syntheseDemandePage = combineReducers({
    error,
    loading,
    syntheseDemande
})

export default syntheseDemandePage
