import { createSelector } from 'reselect'
import { hostingSpacesSelector } from '../hostingspaces/selectors'
import { renderLabelWithAlias } from 'data4-commons/hostingspace/utils'
import { PARAM_REPORT_NAME } from './const'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { rapportsDatacenterConfigSelector } from './client/selectors'

const kpiEnvSelector = state => state.app.kpiEnv

export const kpiCalcMethodDocSelector = state => kpiEnvSelector(state).calcMethodDoc

export const kpiReportsDatacenterSelector = state => kpiEnvSelector(state).reportsDC.reportsDatacenter

const kpiReportsClientSelector = state => kpiEnvSelector(state).reportsClient

export const reportsDatacenterValueSelector = state => kpiReportsDatacenterSelector(state).value

export const reportsClientValueSelector = state => kpiReportsClientSelector(state).value

export const isReportsDatacenterEmptySelector = createSelector(
    reportsDatacenterValueSelector,
    reports => !reports || reports.length === 0
)

export const isReportsClientEmptySelector = createSelector(
    reportsClientValueSelector,
    reports => !reports || reports.length === 0
)

export const reportsDatacenterLoadingSelector = state => kpiReportsDatacenterSelector(state).loading

export const reportsClientLoadingSelector = state => kpiReportsClientSelector(state).loading

export const reportsDatacenterErrorSelector = state => kpiReportsDatacenterSelector(state).error

export const reportsClientErrorSelector = state => kpiReportsClientSelector(state).error

const kpiReportDataSelector = state => kpiEnvSelector(state).reportData

export const reportDataValueSelector = state => kpiReportDataSelector(state).value

export const reportDataLoadingSelector = state => kpiReportDataSelector(state).loading

export const reportDataErrorSelector = state => kpiReportDataSelector(state).error

const getBuildingsData = (localisations, reportsDatacenter) => {
    if (reportsDatacenter && reportsDatacenter.length > 0) {
        const configuredDatacenters = reportsDatacenter.map(datacenter => datacenter.batiment.idBatiment)
        return localisations.flatMap(localisation => (
            localisation.batiments
                .filter(building => configuredDatacenters.includes(building.idBatiment))
                .map(building =>
                    ({
                        value: building.idBatiment,
                        label: `${localisation.nom} - ${renderLabelWithAlias(building)}`
                    })
                )
        ))
    }
    return []
}
export const reportClientSiteBuildingOptionsSelector = createSelector([
        hostingSpacesSelector,
        reportsDatacenterValueSelector
    ], (localisations, reportsDatacenter) => {
        return getBuildingsData(localisations, reportsDatacenter)
    }
)

export const reportClientSiteBuildingOptionsConfigSelector = createSelector([
        hostingSpacesSelector,
        rapportsDatacenterConfigSelector
    ], (localisations, reportsDatacenter) => {
        return getBuildingsData(localisations, reportsDatacenter)
    }
)

export const reportTitleSelector = createSelector([
    reportDataValueSelector,
    reportDataErrorSelector,
    (state, props) => props.location.state
], (report, error, state) => {
    let label = state && state[PARAM_REPORT_NAME]
    if (!label) {
        if (error) {
            label = <FormattedMessage id="kpi_env.report.not_found" />
        }
        if (report && !error) {
            label = report.name
        }
    }
    return label
})

export const reportDatacenterPueWueSelector = state => kpiEnvSelector(state).reportDatacentersPueWue.value
export const loadingReportDatacenterPueWueSelector = state => kpiEnvSelector(state).reportDatacentersPueWue.loading
export const errorReportDatacenterPueWueSelector = state => kpiEnvSelector(state).reportDatacentersPueWue.error
