import React from 'react'
import ExtendedFormattedMessage from './ExtendedFormattedMessage'

/**
 * Retourne un composant React (si possible internationalisé) adapté au message passé en paramètre.
 *
 * @param message
 * @returns {*}
 */
const toI18N = message => {
    if (React.isValidElement(message)) {
        return message
    }
    if (typeof message === 'string') {
        return <span>{message}</span>
    }
    return <ExtendedFormattedMessage message={message} />
}

export default toI18N
