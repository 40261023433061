import { fetchFactory, postMultipart } from '../../entities/fetchEntities'
import { ACTIVATION_2FA_ACTION } from './const'

const _loginByAction = (values, action, headers) => postMultipart('/utilisateurs/connexion', {
    headers,
    body: {
        ...values,
        action
    }
}, '/api')

export const login = _loginByAction

export const fetchQRCode = (values) => _loginByAction(values, ACTIVATION_2FA_ACTION.FETCH_QR_CODE)

export const activate2FA = (values) => _loginByAction(values, ACTIVATION_2FA_ACTION.ACTIVATE)

export const checkPasswordExpiration = () => fetchFactory('/utilisateurs/passwordCheck', {}, '/api')
