import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { branch, pure, renderComponent } from 'recompose'
import { compose } from "redux";
import { getSectionClassName } from '../utils/sectionUtils'
import classnames from 'classnames'
import { SectionContext } from '../app/SectionContextProvider'
import { SECTION, USE_IN_TITLE } from '../modules/navigation/const'
import Icon from './Icon'

export class Headline1 extends Component {
    render() {
        const { label } = this.props
        const { section } = this.context
        let nameIcon = 'chevron'
        if (section === SECTION.SUPPORT) {
            nameIcon = 'chevron-support'
        }
        return (
            <div className="title-headline">
                <Icon name={nameIcon} className={classnames("logo", getSectionClassName(section, USE_IN_TITLE))}/>
                <h1 className="headline">
                    {label}
                </h1>
            </div>
        )
    }
}

const Headline2 = ({ label }) => {
    return (
        <h2 className="headline-2">
            {label}
        </h2>
    )
}

const HeadlineUnderline = ({ label }) => {
    return (
        <h1 className="headline underline">
            {label}
        </h1>
    )
}
const Headline = compose(
    branch(
        props => props.type === 'secondary',
        renderComponent(Headline2)
    ), branch(
        props => props.type === 'underline',
        renderComponent(HeadlineUnderline)
    ))(Headline1)

Headline.propTypes = {
    label: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['primary', 'secondary', 'underline'])
}

Headline1.contextType = SectionContext

Headline.defaultProps = {
    type: 'primary'
}


export default pure(Headline)
