import React, { PureComponent, Fragment } from 'react'
// lib
import { FormattedMessage } from 'react-intl'
import { SubmissionError, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'
// internal components
import Headline from '../../../../components/Headline'
import ChangePasswordForm, { validate } from '../ChangePasswordForm'
// api
import { changePassword } from '../api'
// utils
import handleError from 'data4-commons/form/handleError'
import { validatePassword } from '../utils'
// selectors
import { passwordMinLengthSelector } from '../../../../properties/selectors'

export const handleLoninOnChangePassword = (loginFunction) => ({
    login,
    password,
    newPassword,
    confirmation
}, dispatch, props) => {
    const errors = validatePassword(newPassword, confirmation, props.passwordMinLength)
    if (errors) {
        if (errors.password) {
            throw new SubmissionError({
                newPassword: errors.password
            })
        }
        if (errors.confirmation) {
            throw new SubmissionError(errors)
        }
    }
    return changePassword({
        email: login,
        oldPassword: password,
        newPassword
    })
        .then(
            () => {
                props.change('password', newPassword)
                return loginFunction({ login, password: newPassword }, dispatch, props) // relogin avec nouveau mot de passe
            },
            handleError // erreurs de changement de mot de passe
        )
}

class ChangePasswordPage extends PureComponent {
    render() {
        const {
            error,
            submitting,
            handleSubmit,
            passwordMinLength
        } = this.props
        return (
            <Fragment>
                <Headline
                    label={<FormattedMessage id="login.change_password.title" />}
                    type="secondary"
                />
                <ChangePasswordForm
                    error={error}
                    submitting={submitting}
                    handleSubmit={handleSubmit}
                    passwordMinLength={passwordMinLength}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => ({
    passwordMinLength: passwordMinLengthSelector(state, props)
})

export default compose(
    connect(mapStateToProps),
    reduxForm({
        validate
    })
)(ChangePasswordPage)
