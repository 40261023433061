import middle from 'data4-commons/middlewares/api/middle'
import { _filesSelector } from './selector'
import cache from './cache'

const prefix = 'FILES/'

export const GET_LIST_FILES = `${prefix}GET_LIST_FILES`

export const getFiles = () => middle(GET_LIST_FILES, {
    api: '/api2',
    url: '/files',
    method: 'GET',
    cache,
    selector: _filesSelector
})
