const ALL_STATUS = 0
const TO_VALIDATE = 1
const DENIED = 2
const VALIDATED = 3

export const DRAFT_REQUEST_STATUS = {
    ALL_STATUS,
    TO_VALIDATE,
    DENIED,
    VALIDATED
}

export const EXPIRED = 'expired'
export const SOON_EXPIRED = 'expired_soon'
export const NOT_EXPIRED = 'not_expired'
export const DRAFT_REQUEST_STATUS_VALUES = [TO_VALIDATE, DENIED, VALIDATED]
