import { createSelector } from 'reselect'

export const _configurationSelector = state => state.app.configuration
export const configurationValueSelector = state => _configurationSelector(state).value
export const configurationLoadingSelector = state => _configurationSelector(state).loading
const configurationErrorSelector = state => _configurationSelector(state).error

const configurationSelector = createSelector([
    configurationValueSelector,
    configurationLoadingSelector,
    configurationErrorSelector
], (value, loading, error) => !error && !loading && value)

export const requestsRefreshFrequencySelector = createSelector([
    configurationSelector
], (configuration) => configuration && configuration.rafraichissementDemandes)

export const requestSlaSelector = createSelector([
    configurationSelector
], (configuration) => configuration && configuration.sla)
