export const AUTHENTICATE = 'DCIM/AUTHENTICATE'


/**
 * action permattant d'authentifier un utilisateur dans le dcim
 * et d'obtenir le temps d'expiration des credentials
 * @param auth
 * @returns {{payload: *, type: string}}
 */
export const authenticateAction = (auth) => {
	return ({
		type: AUTHENTICATE,
		payload: auth
	})
}
