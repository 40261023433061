import { fetchFactory } from '../../entities/fetchEntities'

export const changePassword = (values) => fetchFactory('/motdepasse', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api2')

export const resetPassword = (values) => fetchFactory('/motdepasse/reset', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api2')

export const checkRightsToResetPassword = (email, token) => fetchFactory(`/motdepasse/check_reset/${email}/${token}`, {}, '/api2')

export const askToResetPassword = (values) => fetchFactory('/motdepasse/ask_for_reset', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api2')

export const initPassword = (values) => fetchFactory('/motdepasse/init', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api2')

export const checkRightsToInitPassword = (email, token) => fetchFactory(`/motdepasse/check_init/${email}/${token}`, {}, '/api2')
