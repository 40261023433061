import {
    activate2FA as activate2FAApi,
    disable2FA as disable2FAApi
} from './api'

const prefix = 'TWO_FA/'

export const ACTIVATE_2FA = `${prefix}ACTIVATE`
export const DISABLE_2FA = `${prefix}DISABLE`


export const activate2FA = (values) => (dispatch) => {
    return activate2FAApi(values)
        .then(() => {
            dispatch({
                type: ACTIVATE_2FA
            })
        })
}

export const disable2FA = (values) => (dispatch) => {
    return disable2FAApi(values)
        .then(() => {
            dispatch({
                type: DISABLE_2FA
            })
        })
}

