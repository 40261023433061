import { SECTION } from "../modules/navigation/const";

/**
 * Permet d'obtenir la classe css correspondante à la section dans laquelle on se trouve
 * @param sectionName le nom de la section
 * @param useIn string qui définit où la classename sera utilisée
 *      (valeur possible USE_IN_MENU, USE_IN_TITLE ou USE_IN_OTHER)
 * @returns {string} la classe css correspondante
 */
export const getSectionClassName = (sectionName, useIn) => {
    let sectionColor
        switch (sectionName) {
            case SECTION.SUSTAINABILITY :
                sectionColor = `section-sustainability-${useIn}`
                break;
            case SECTION.SERVICES :
                sectionColor = `section-services-${useIn}`
                break;
            case SECTION.REQUESTS :
                sectionColor = `section-requests-${useIn}`
                break;
            case SECTION.MANAGEMENT :
                sectionColor = `section-management-${useIn}`
                break;
            default:
                sectionColor = `section-support-${useIn}`
                break;
    }
    return sectionColor
}
