import { isDirty, reset } from 'redux-form'
import { REQUEST_FILTER_NAME } from '../const'
import { filtersInitialValuesSelector } from './selectors'

export const OPEN_REQUEST_FILTERS = 'OPEN_REQUEST_FILTERS'
export const CLOSE_REQUEST_FILTERS = 'CLOSE_REQUEST_FILTERS'
export const APPLY_REQUEST_FILTERS = 'APPLY_REQUEST_FILTERS'
export const RESET_REQUEST_FILTERS = 'RESET_REQUEST_FILTERS'

export const openFilters = () => (dispatch) => {
    dispatch({
        type: OPEN_REQUEST_FILTERS
    })
}

export const closeFilters = () => (dispatch) => {
    dispatch({
        type: CLOSE_REQUEST_FILTERS
    })
}


export const applyFilters = ({
    target,
    ...values
}) => (dispatch, getState) => {
    const dirty = isDirty(REQUEST_FILTER_NAME)(getState())
    dispatch({
        type: APPLY_REQUEST_FILTERS,
        payload: values,
        meta: {
            dirty
        }
    })
}

export const resetFilters = () => (dispatch, getState) => {
    const values = filtersInitialValuesSelector(getState())
    dispatch(reset(REQUEST_FILTER_NAME))
    dispatch({
        type: RESET_REQUEST_FILTERS,
        payload: values
    })
}
