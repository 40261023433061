import React from 'react'
// lib
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
// component
import { Button, Form } from 'reactstrap'
// internal components
import { Errors, TwoFactorCodeTextField } from '../../components/reduxForm'
// utils
import { validateMandatoryFields } from 'data4-commons/form/utils'

export const validate = validateMandatoryFields(['code'])

const Check2FACodeForm = ({
    submitting,
    handleSubmit,
    error,
    buttonProps
}) => {
    return (
        <Form
            className="form-standard"
            onSubmit={handleSubmit}
        >
            <Field
                name="code"
                component={TwoFactorCodeTextField}
                label={<FormattedMessage id="2fa.pin_code.label" />}
            />
            <div className="form-validation">
                <div className="left">
                    <Button
                        {...buttonProps}
                        color="primary"
                        disabled={submitting}
                        type="submit"
                    >
                        <FormattedMessage id="2fa.activate.label" />
                    </Button>
                </div>
                <Errors errors={error} />
            </div>
        </Form>
    )
}

Check2FACodeForm.propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.any,
    /**
     * Props de submit boutton
     */
    buttonProps: PropTypes.object
}

export default Check2FACodeForm
