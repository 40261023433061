import { combineReducers } from 'redux'
import rapportsParZone from './areas/reducer'
import buildings from './areas/buildings/reducer'
import { GET_ZONE_CONFIGURATION, GET_ZONE_CONFIGURATION_ERROR, GET_ZONE_CONFIGURATION_LOADING, UPDATE_ZONE_CONFIGURATION } from './actions'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_ZONE_CONFIGURATION_LOADING:
            return true
        case GET_ZONE_CONFIGURATION:
        case GET_ZONE_CONFIGURATION_ERROR:
            return false
        default:
            return state
    }
}
const error = (state = false, action) => {
    switch (action.type) {
        case GET_ZONE_CONFIGURATION:
            return false
        case GET_ZONE_CONFIGURATION_ERROR:
            return true
        default:
            return state
    }
}
const value = (state = null, action) => {
    switch (action.type) {
        case GET_ZONE_CONFIGURATION:
            return action.payload
        case UPDATE_ZONE_CONFIGURATION:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const zoneConfiguration = combineReducers({
    loading,
    error,
    value
})

export default combineReducers({
    rapportsParZone,
    buildings,
    zoneConfiguration
})

