import { combineReducers } from 'redux'
import {
    EXPORT_ACCESS_FINISH,
    EXPORT_ACCESS_START,
    EXPORT_ACCESS_REVOKE_START,
    EXPORT_ACCESS_REVOKE_FINISH,
    GET_REVOKED_ACCESS_PAGE_LOADING,
    GET_REVOKED_ACCESS_PAGE_ERROR,
    GET_REVOKED_ACCESS_PAGE,
    GET_ACCESS_PAGE,
    GET_ACCESS_PAGE_LOADING,
    GET_ACCESS_PAGE_ERROR
} from './actions'

const loadingPage = (state = false, action) => {
    switch (action.type) {
        case GET_ACCESS_PAGE_LOADING:
            return true
        case GET_ACCESS_PAGE_ERROR:
        case GET_ACCESS_PAGE:
            return false
        default:
            return state
    }
}

const errorPage = (state = false, action) => {
    switch (action.type) {
        case GET_ACCESS_PAGE_ERROR:
            return true
        case GET_ACCESS_PAGE_LOADING:
        case GET_ACCESS_PAGE:
            return false
        default:
            return state
    }
}

const valuePage = (state = {}, action) => {
    switch (action.type) {
        case GET_ACCESS_PAGE:
            return action.payload
        default:
            return state
    }
}

const exporting = (state = false, action) => {
    switch (action.type) {
        case EXPORT_ACCESS_FINISH:
            return false
        case EXPORT_ACCESS_START:
            return true
        default:
            return state
    }
}

const exportingRevoked = (state = false, action) => {
    switch (action.type) {
        case EXPORT_ACCESS_REVOKE_START:
            return true
        case EXPORT_ACCESS_REVOKE_FINISH:
            return false
        default:
            return state
    }
}

const loadingRevokedPage = (state = false, action) => {
    switch (action.type) {
        case GET_REVOKED_ACCESS_PAGE_LOADING:
            return true
        case GET_REVOKED_ACCESS_PAGE_ERROR:
        case GET_REVOKED_ACCESS_PAGE:
            return false
        default:
            return state
    }
}

const errorRevokedPage = (state = false, action) => {
    switch (action.type) {
        case GET_REVOKED_ACCESS_PAGE_ERROR:
            return true
        case GET_REVOKED_ACCESS_PAGE_LOADING:
        case GET_REVOKED_ACCESS_PAGE:
            return false
        default:
            return state
    }
}

const valueRevokedPage = (state = {}, action) => {
    switch (action.type) {
        case GET_REVOKED_ACCESS_PAGE:
            return action.payload
        default:
            return state
    }
}

export default combineReducers({
    loadingPage,
    errorPage,
    valuePage,
    exporting,
    exportingRevoked,
    loadingRevokedPage,
    errorRevokedPage,
    valueRevokedPage
})
