import React, { useEffect, useRef, useState } from 'react'
import { defaultId } from './defaultId'
import { FormGroup, Input } from 'reactstrap'
import FormLabel from '../FormLabel'
import classnames from 'classnames'

const TwoFactorCodeTextField = ({
    size,
    label,
    mandatory,
    meta: {
        touched,
        error
    },
    input
}) => {
    const [code, setCode] = useState(['', '', '', '', '', ''])
    const inputRefs = useRef([...Array(6)].map(() => React.createRef()))

    useEffect(() => {
        input.onChange(code.join(''))
    }, [code])

    const handleChange = (e, index) => {
        const { value } = e.target
        if (value.length === 1 && /^\d+$/.test(value)) {
            const newCode = [...code]
            newCode[index] = value
            setCode(newCode)
            if (index < 5) {
                inputRefs.current[index + 1].current.focus()
            }
        } else if (value.length === 0) {
            const newCode = [...code]
            newCode[index] = ''
            setCode(newCode)
            if (index > 0) {
                inputRefs.current[index - 1].current.focus()
            }
        }
    }

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && !code[index]) {
            inputRefs.current[index - 1].current.focus()
        }
    }

    return (
        <FormGroup
            className={classnames('form-input', {
                [`form-${size}`]: size,
                'form-error': error && touched
            })}
        >
            <div>
                {
                    label && (
                        <FormLabel
                            label={label}
                            mandatory={mandatory}
                        />
                    )
                }
                <div className="group-form-control two-fa-container">
                    {code.map((digit, index) => (
                        <Input
                            key={index}
                            type="text"
                            value={digit}
                            maxLength={1}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            innerRef={inputRefs.current[index]}
                            className="two-fa-textfield"
                        />
                    ))}
                </div>
                {
                    touched && error && (
                        <div className="error">{error}</div>
                    )
                }
            </div>
        </FormGroup>
    )
}

export default defaultId(TwoFactorCodeTextField)
