import { buildUrlWithParams, fetchFactory } from 'data4-commons/entities/fetchEntities'

const BASE_URL = "/equipements"

export const getEquipmentsSociete = (societeId) => fetchFactory(`${BASE_URL}/list/${societeId}`, {}, '/api')

export const addEquipements = (values) => fetchFactory(`${BASE_URL}/add`, {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const updateEquipement = (idEquipement, values) => fetchFactory(`${BASE_URL}/update/${idEquipement}`, {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const getEquipementsPage = (idSociete, params) => fetchFactory(buildUrlWithParams(`${BASE_URL}/${idSociete}`, { ...params }), {
    method: 'GET'
}, '/api')

export const getEquipement = (idEquipement) => fetchFactory(`${BASE_URL}/modify/${idEquipement}`, {
     method: 'GET'
}, '/api')

export const deleteEquipement = (idEquipement) => fetchFactory(`${BASE_URL}/delete/${idEquipement}`, {
    method: 'DELETE'
}, '/api')

export const exportEquipements = () => fetch(`/api${BASE_URL}/export`, {
    headers: {
        Accept: 'application/octet-stream'
    }
})
