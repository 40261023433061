import { DEMANDE_TYPE } from 'data4-commons/global'
import { EMERGENCY_VALUES as CFO_EMERGENCY_VALUES } from './cfo/const'
import { EMERGENCY_VALUES as ACCESS_EMERGENCY_VALUES, EMERGENCY as ACCESS_EMERGENCY } from './access/const'
import { EMERGENCY_VALUES as CFA_EMERGENCY_VALUES } from './cfa/const'
import { CRITICITES as GESTE_EMERGENCY_VALUES } from './gesteProximite/const'
import { CRITICITES as INCIDENT_EMERGENCY_VALUES } from './incidents/const'
import { EMERGENCY_VALUES as LIVRAISON_EMERGENCY_VALUES  } from './livraison/const'
import { EMERGENCY_VALUES as OTHER_EMERGENCY_VALUES  } from './others/const'

const ALL_STATUS = 0
const OPEN = 1
const IN_PROGRESS = 2
const CLOSE = 3

export const REQUEST_STATUS = {
    ALL_STATUS,
    OPEN,
    IN_PROGRESS,
    CLOSE
}

export const REQUEST_STATUS_VALUES = [OPEN, IN_PROGRESS, CLOSE]

export const START_DATE_HOUR = 8
export const END_DATE_HOUR = 19

const REVOCATION_EMERGENCY_VALUES = [ACCESS_EMERGENCY.EMERGENCY_STANDARD]

export const REQUEST_IMPORTANCE = {
    [DEMANDE_TYPE.ACCES]: ACCESS_EMERGENCY_VALUES,
    [DEMANDE_TYPE.GESTE_PROXIMITE]: GESTE_EMERGENCY_VALUES,
    [DEMANDE_TYPE.CFA]: CFA_EMERGENCY_VALUES,
    [DEMANDE_TYPE.CFO]: CFO_EMERGENCY_VALUES,
    [DEMANDE_TYPE.INCIDENT]: INCIDENT_EMERGENCY_VALUES,
    [DEMANDE_TYPE.LIVRAISON]: LIVRAISON_EMERGENCY_VALUES,
    [DEMANDE_TYPE.REVOCATION]: REVOCATION_EMERGENCY_VALUES,
    [DEMANDE_TYPE.OTHER]: OTHER_EMERGENCY_VALUES
}

export const HISTORY = {
    OUVERTURE_DEMANDE: 1,
    DEBUT_TRAITEMENT_DEMANDE: 2,
    FIN_TRAITEMENT_DEMANDE: 3,
    AJOUT_NOTE: 4,
    AJOUT_NOTE_ATTENTE_REPONSE: 5,
    AJOUT_OPERATION: 6,
    CLOTURE_OPERATION: 7,
    CHANGEMENT_CRITICITE: 8,
    MISE_EN_ESCALADE: 9,
    CHANGEMENT_AFFECTATION: 10,
    AJOUT_NOTE_INTERNE: 11
}

const LIVRAISON_SERVICE_ID = '-1000'
const ENLEVEMENT_SERVICE_ID = '-1001'

export const FAKE_SERVICE_IDS = [LIVRAISON_SERVICE_ID, ENLEVEMENT_SERVICE_ID]

const VISITOR_ACCESS_VALID = true
const VISITOR_ACCESS_REJECT = false

export const VISITOR_ACCESS_STATUS_VALUES = [VISITOR_ACCESS_VALID, VISITOR_ACCESS_REJECT]

const GESTE_ACTION_STATUS_IN_PROGRESS = 1

export const GESTE_ACTION_STATUS = {
    IN_PROGRESS: GESTE_ACTION_STATUS_IN_PROGRESS,
    CLOSE
}

export const GESTE_ACTION_STATUS_VALUES = [GESTE_ACTION_STATUS_IN_PROGRESS, CLOSE]
