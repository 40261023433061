import { CLOSE_REQUEST_FILTERS, OPEN_REQUEST_FILTERS, APPLY_REQUEST_FILTERS, RESET_REQUEST_FILTERS } from './requestFiltersActions'
import { UPDATE_REQUEST_FILTERS_PREFERENCE } from '../../../preferencedisplay/actions'
import { REQUEST_FILTER_NAME, STATIC_FILTERS_INITIAL_VALUES } from '../const'
import { transformFiltersPreference } from './utils'
import { combineReducers } from 'redux'
import { actionTypes } from 'redux-form'

const open = (state = false, action) => {
	switch (action.type) {
		case CLOSE_REQUEST_FILTERS:
            return false
        case OPEN_REQUEST_FILTERS:
            return true
		default:
			return state
	}
}

const values = (state = STATIC_FILTERS_INITIAL_VALUES, action) => {
    switch (action.type) {
        case RESET_REQUEST_FILTERS:
        case APPLY_REQUEST_FILTERS:
            return action.payload
        case actionTypes.INITIALIZE: {
            if (action.meta.form === REQUEST_FILTER_NAME) {
                return action.payload
            }
            return state
        }
        case UPDATE_REQUEST_FILTERS_PREFERENCE:
            return {
                ...state,
                ...transformFiltersPreference(action.payload)
            }
        default:
            return state
    }
}

const dirty = (state = false, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE: {
            if (action.meta.form === REQUEST_FILTER_NAME) {
                return false
            }
            return state
        }
        case RESET_REQUEST_FILTERS:
            return false
        case APPLY_REQUEST_FILTERS:
            return action.meta.dirty
        default:
            return state
    }
}

export default combineReducers({
    values,
    open,
    dirty
})
