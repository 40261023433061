import { combineReducers } from 'redux'
import {
    GET_USER,
    EXPORT_USERS_START,
    EXPORT_USERS_FINISH
} from './actions'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const exporting = (state = false, action) => {
    switch (action.type) {
        case EXPORT_USERS_FINISH:
            return false
        case EXPORT_USERS_START:
            return true
        default:
            return state
    }
}

const selected = createReducer(GET_USER)

export default combineReducers({
    exporting,
    selected
})
