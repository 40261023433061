import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'HOMEPAGE_MESSAGE/'

export const GET_HOMEPAGE_MESSAGE = `${prefix}GET_MESSAGE`

export const getHomepageMessage = () => middle(GET_HOMEPAGE_MESSAGE, {
    api: '/api',
    url: '/homepage_message',
    method: 'GET'
})
