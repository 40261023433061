import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'GROUPING_SPACES/'

export const GET_GROUPING_SPACES = `${prefix}GET_GROUPING_SPACES`

export const getGroupingSpaces = () => middle(GET_GROUPING_SPACES, {
    api: '/api',
    url: '/groupements',
    method: 'GET'
})
