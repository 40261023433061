import PropTypes from 'prop-types'
import moment from 'moment'
import { FORM_ALIGN, FORM_SIZE_VALUES } from './const'

export const inputShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any
})

export const metaShape = PropTypes.shape({
    form: PropTypes.string.isRequired,
    error: PropTypes.any,
    dirty: PropTypes.bool,
    touched: PropTypes.bool
})

export const sizeShape = PropTypes.oneOf(FORM_SIZE_VALUES)

export const alignShape = PropTypes.oneOf(FORM_ALIGN)

export const dateValueShape = (props, propName, componentName) => {
    if (props[propName]) {
        if (!moment(props[propName]).isValid()) {
            return new Error(
                'Invalid prop `' + propName + '` supplied to' +
                ' `' + componentName + '`. Invalid date value.'
            )
        }
    }
}