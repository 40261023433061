import { createSelector } from 'reselect'
import { requestFiltersPreferenceSelector } from '../../preferencedisplay/selectors'
import { transformDraftRequestsFiltersPreference } from './utils'

export const filtersInitialDraftRequestValueSelector = createSelector([
    requestFiltersPreferenceSelector
], transformDraftRequestsFiltersPreference)

export const draftRequestsFiltersSelector = state => state.app.draftRequests.filters
export const draftRequestsFiltersOpenSelector = state => draftRequestsFiltersSelector(state).open
export const draftRequestsFiltersValuesSelector = state => draftRequestsFiltersSelector(state).values
export const draftRequestsFiltersDirtySelector = state => draftRequestsFiltersSelector(state).dirty
