import {
    acceptCGU as acceptCGUApi
} from './api'
import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'CGU/'

export const GET_CGU_PREVIEW = `${prefix}GET_CGU_PREVIEW`
export const GET_CGU_TO_VALIDATE = `${prefix}GET_CGU_TO_VALIDATE`
export const ACCEPT_CGU = `${prefix}ACCEPT_CGU`
export const GET_LAST_ACCEPTED_CGU = `${prefix}GET_LAST_ACCEPTED_CGU`

export const getCGUToValidate = () => middle(GET_CGU_TO_VALIDATE, {
    api: '/api',
    url: '/cgu/me',
    method: 'GET',
})

export const getLastAcceptedCGU = () => middle(GET_LAST_ACCEPTED_CGU, {
    api: '/api',
    url: '/cgu/validation/me',
    method: 'GET',
})

export const acceptCGU = () => (dispatch) => {
    return acceptCGUApi()
        .then(() => {
            dispatch({
                type: ACCEPT_CGU
            })
        })
}
