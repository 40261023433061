export const GET_VISITEURS_PERMANENTS = 'GET_VISITEURS_PERMANENTS'
export const GET_VISITEURS_PONCTUELS = 'GET_VISITEURS_PONCTUELS'

const prefix = 'DEMANDES/'

export const GET_DEMANDES = `${prefix}GET_DEMANDES`
export const GET_DEMANDES_LOADING = `${prefix}GET_DEMANDES_LOADING`
export const GET_DEMANDES_ERROR = `${prefix}GET_DEMANDES_ERROR`

export const GET_DEMANDE = `${prefix}GET_DEMANDE`
export const GET_DEMANDE_LOADING = `${prefix}GET_DEMANDE_LOADING`
export const GET_DEMANDE_ERROR = `${prefix}GET_DEMANDE_ERROR`

export const LOAD_MORE_DEMANDES = `${prefix}LOAD_MORE_DEMANDES`
export const LOAD_MORE_DEMANDES_LOADING = `${prefix}LOAD_MORE_DEMANDES_LOADING`
export const LOAD_MORE_DEMANDES_ERROR = `${prefix}LOAD_MORE_DEMANDES_ERROR`

export const ENABLE_REFRESH_DEMANDES = `${prefix}ENABLE_REFRESH`
export const DISABLE_REFRESH_DEMANDES = `${prefix}DISABLE_REFRESH`

export const CREATE_DEMANDE =`${prefix}CREATE`

export const OPEN_DEMANDE = `${prefix}OPEN_DEMANDE`
export const CLOSE_DEMANDE = `${prefix}CLOSE_DEMANDE`
export const UPDATE_DEMANDE = `${prefix}UPDATE_DEMANDE`
export const UPDATE_DEMANDE_STATUT = `${prefix}UPDATE_DEMANDE_STATUT`

const EXPORT_DEMANDES = `${prefix}EXPORT_DEMANDES`

export const EXPORT_DEMANDES_START = `${EXPORT_DEMANDES}_START`
export const EXPORT_DEMANDES_FINISH = `${EXPORT_DEMANDES}_FINISH`

export const GET_SYNTHSES_DEMANDES = `${prefix}GET_SYNTHESE_DEMANDES`
export const GET_SYNTHSES_DEMANDES_LOADING = `${prefix}GET_SYNTHESE_DEMANDES_LOADING`
export const GET_SYNTHSES_DEMANDES_ERROR = `${prefix}GET_SYNTHESE_DEMANDES_ERROR`
