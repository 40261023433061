const EMERGENCY_URGENT = 2
const EMERGENCY_STANDARD = 1

const MAINTENANCE = 1
const DELIVERY = 2
const ASTREINTE = 3
const FIT_OUT = 4
const CONSTRUCTION = 5

export const EMERGENCY = {
    EMERGENCY_STANDARD,
    EMERGENCY_URGENT
}

export const EMERGENCY_VALUES = [
    EMERGENCY_STANDARD,
    EMERGENCY_URGENT
]

export const INTERVENTION_TYPE = {
    MAINTENANCE,
    DELIVERY,
    ASTREINTE,
    FIT_OUT,
    CONSTRUCTION
}

export const INTERVENTION_TYPE_VALUES = [
    MAINTENANCE,
    DELIVERY,
    ASTREINTE,
    FIT_OUT,
    CONSTRUCTION
]

export const START_DATE_RANGE_MINUTE = 30
