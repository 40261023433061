import { isDirty, reset } from 'redux-form'
import { DRAFT_REQUEST_FILTER_NAME } from './const'
import { filtersInitialDraftRequestValueSelector } from './filtersSelector'

export const OPEN_DRAFT_REQUEST_FILTERS = 'OPEN_DRAFT_REQUEST_FILTERS'
export const CLOSE_DRAFT_REQUEST_FILTERS = 'CLOSE_DRAFT_REQUEST_FILTERS'
export const APPLY_DRAFT_REQUEST_FILTERS = 'APPLY_DRAFT_REQUEST_FILTERS'
export const RESET_DRAFT_REQUEST_FILTERS = 'RESET_DRAFT_REQUEST_FILTERS'

export const openDraftFilters = () => (dispatch) => {
    dispatch({
        type: OPEN_DRAFT_REQUEST_FILTERS
    })
}

export const closeDraftFilters = () => (dispatch) => {
    dispatch({
        type: CLOSE_DRAFT_REQUEST_FILTERS
    })
}


export const applyFilters = ({
    target,
    ...values
}) => (dispatch, getState) => {
    const dirty = isDirty(DRAFT_REQUEST_FILTER_NAME)(getState())
    dispatch({
        type: APPLY_DRAFT_REQUEST_FILTERS,
        payload: values,
        meta: {
            dirty
        }
    })
}

export const resetFilters = () => (dispatch, getState) => {
    const values = filtersInitialDraftRequestValueSelector(getState())
    dispatch(reset(DRAFT_REQUEST_FILTER_NAME))
    dispatch({
        type: RESET_DRAFT_REQUEST_FILTERS,
        payload: values
    })
}

