import { combineReducers } from 'redux'
import createRequestsReducerByStatus from './createRequestsReducerByStatus'
import { DRAFT_REQUEST_STATUS } from './const'
import filterReducer from './filters/filterReducer'
import {
    CLOSE_DRAFT_REQUEST,
    DISABLE_DRAFT_REFRESH_DRAFT_REQUESTS,
    ENABLE_REFRESH_DRAFT_REQUESTS,
    GET_DRAFT_REQUESTS,
    GET_DRAFT_REQUESTS_ERROR,
    GET_DRAFT_REQUESTS_LOADING,
    OPEN_DRAFT_REQUEST,
    GET_DRAFT_REQUEST
} from './actions'

const allStatus = createRequestsReducerByStatus(DRAFT_REQUEST_STATUS.ALL_STATUS)
const toValidate = createRequestsReducerByStatus(DRAFT_REQUEST_STATUS.TO_VALIDATE)
const denied = createRequestsReducerByStatus(DRAFT_REQUEST_STATUS.DENIED)
const validated = createRequestsReducerByStatus(DRAFT_REQUEST_STATUS.VALIDATED)

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_DRAFT_REQUESTS_LOADING:
            return true
        case GET_DRAFT_REQUESTS:
        case GET_DRAFT_REQUESTS_ERROR:
            return false
        default:
            return state
    }
}

const error = (state = false, action) => {
    switch (action.type) {
        case GET_DRAFT_REQUESTS_ERROR:
            return true
        case GET_DRAFT_REQUESTS_LOADING:
        case GET_DRAFT_REQUESTS:
            return false
        default:
            return state
    }
}

const refreshEnabled = (state = true, action) => {
    switch (action.type) {
        case ENABLE_REFRESH_DRAFT_REQUESTS:
            return true
        case DISABLE_DRAFT_REFRESH_DRAFT_REQUESTS:
            return false
        default:
            return state
    }
}

const selectedDraftRequestId = (state = null, action) => {
    switch (action.type) {
        case OPEN_DRAFT_REQUEST:
            return action.payload
        case CLOSE_DRAFT_REQUEST:
            return null
        case GET_DRAFT_REQUEST:
            return action.payload.idDemande
        default:
            return state
    }
}

const list = combineReducers({
    [DRAFT_REQUEST_STATUS.ALL_STATUS]: allStatus,
    [DRAFT_REQUEST_STATUS.TO_VALIDATE]: toValidate,
    [DRAFT_REQUEST_STATUS.DENIED] : denied,
    [DRAFT_REQUEST_STATUS.VALIDATED] : validated,
    loading,
    error,
    refreshEnabled
})

const selectedDraftRequest = combineReducers({
    id: selectedDraftRequestId
})

const draftRequests = combineReducers({
    list,
    filters: filterReducer,
    selectedDraftRequest
})

export default draftRequests
