const URGENT = 1
const PLANNED = 2

export const EMERGENCY = {
    URGENT,
    PLANNED
}

export const EMERGENCY_VALUES = [
    URGENT,
    PLANNED
]

const AM = '1'
const PM = '2'
const CUSTOM = '3'

export const SCHEDULE = {
    AM,
    PM,
    CUSTOM
}

export const CFO_FORM_SECTION = 'cfo'
