import { checkMedia as checkMediaApi } from 'data4-commons/api/media/api'
import { DOMAIN_SHORTCUT } from 'data4-commons/global'

const prefix = 'USER_GUIDE/'

export const CHECK_USER_GUIDE = `${prefix}CHECK_USER_GUIDE`

export const checkUserGuide = (lang) => (dispatch) => {
    return checkMediaApi(DOMAIN_SHORTCUT.USER_GUIDE, lang)
        .then(() => {
            dispatch({
                type: CHECK_USER_GUIDE,
                payload: true
            })
        }, (e) => {
            dispatch({
                type: CHECK_USER_GUIDE,
                payload: false
            })
            throw e
        })
}
