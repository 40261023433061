import { exportAccess as exportAccessApi, exportRevokedAccess as exportRevokedAccessApi, getAccessPage, getRevokedAccessPage } from './api'

const prefix = 'ACCESS/'

export const EXPORT_ACCESS_START = `${prefix}EXPORT_ACCESS_START`
export const EXPORT_ACCESS_FINISH = `${prefix}EXPORT_ACCESS_FINISH`

export const GET_ACCESS_PAGE = 'GET_ACCESS_PAGE'
export const GET_ACCESS_PAGE_LOADING = 'GET_ACCESS_PAGE_LOADING'
export const GET_ACCESS_PAGE_ERROR = 'GET_ACCESS_PAGE_ERROR'
export const getPageAccess = (clientId, params) => (dispatch) => {
    dispatch({
        type: GET_ACCESS_PAGE_LOADING
    })
    return getAccessPage(clientId, params)
        .then(access => {
            dispatch({
                type: GET_ACCESS_PAGE,
                payload: access
            })
            return access
        })
        .catch(e => {
            dispatch({
                type: GET_ACCESS_PAGE_ERROR
            })
            throw e
        })
}

export const exportAccess = (clientId, params) => (dispatch) => {
    dispatch({
        type: EXPORT_ACCESS_START
    })
    return exportAccessApi(clientId, params)
        .then((res) => {
            dispatch({
                type: EXPORT_ACCESS_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_ACCESS_FINISH
            })
            throw e
        })
}

export const EXPORT_ACCESS_REVOKE_START = `${prefix}EXPORT_ACCESS_REVOKE_START`
export const EXPORT_ACCESS_REVOKE_FINISH = `${prefix}EXPORT_ACCESS_REVOKE_FINISH`

export const GET_REVOKED_ACCESS_PAGE = 'GET_REVOKED_ACCESS_PAGE'
export const GET_REVOKED_ACCESS_PAGE_LOADING = 'GET_REVOKED_ACCESS_PAGE_LOADING'
export const GET_REVOKED_ACCESS_PAGE_ERROR = 'GET_REVOKED_ACCESS_PAGE_ERROR'
export const getPageRevokedAccess = (clientId, params) => (dispatch) => {
    dispatch({
        type: GET_REVOKED_ACCESS_PAGE_LOADING
    })
    return getRevokedAccessPage(clientId, params)
        .then(revokedAccess => {
            dispatch({
                type: GET_REVOKED_ACCESS_PAGE,
                payload: revokedAccess
            })
            return revokedAccess
        })
        .catch(e => {
            dispatch({
                type: GET_REVOKED_ACCESS_PAGE_ERROR
            })
            throw e
        })
}

export const exportRevokedAccess = (clientId, params) => (dispatch) => {
    dispatch({
        type: EXPORT_ACCESS_REVOKE_START
    })
    return exportRevokedAccessApi(clientId, params)
        .then((res) => {
            dispatch({
                type: EXPORT_ACCESS_REVOKE_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_ACCESS_REVOKE_FINISH
            })
            throw e
        })
}
