export const SHORT_DATE = 'short_date'
export const SHORT_DATE2 = 'short_date2'
export const SHORT_DATE3 = 'short_date3'
export const LONG_DATE = 'long_date'
export const SHORT_TIME = 'short_time'

export default {
    date: {
        [LONG_DATE]: {
            year: 'numeric',
            month: 'long',
            day: '2-digit'
        },
        [SHORT_DATE]: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        [SHORT_DATE2]: {
            month: 'short',
            day: 'numeric'
        },
        [SHORT_DATE3]: {
            year: 'numeric',
            month: 'short'
        },
        [SHORT_TIME]: {
            hour: 'numeric',
            minute: 'numeric'
        }
    }
}
