import { combineReducers } from 'redux'
import { demandesReducer, visiteurs } from '../modules/demandes/demandeReducer'
import demandePageReducer from '../modules/demandes/demandePageReducer'
import user from '../modules/user/userReducer'
import notifications from '../modules/notifications/notificationsReducer'
import documentReducer from '../modules/documents/documentReducer'
import dcim from '../modules/dcim/dcimReducer'
import navigation from '../modules/navigation/navigationReducer'
import clients from '../modules/clients/reducer'
import users from '../modules/users/reducer'
import groups from '../modules/group/reducer'
import { localisationReducer } from '../modules/localisations/localisationReducer'
import { gesteReducer } from '../modules/demandes/gesteProximite/gesteReducer'
import configuration from '../modules/configuration/reducer'
import dashboard from '../modules/dashboard/reducer'
import forfait from '../modules/forfait/reducer'
import hostingSpaces from '../modules/hostingspaces/reducer'
import deliveryInformation from '../modules/deliveryinstructions/reducer'
import access from '../modules/access/reducer'
import twoFA from '../modules/2fa/reducer'
import cgu from '../modules/cgu/reducer'
import clientPreference from '../modules/clientpreference/reducer'
import recurrentRemoteHands from '../modules/recurrentremotehands/reducer'
import preferenceDisplay from '../modules/preferencedisplay/reducer'
import homepageMessage from '../modules/homepagemessage/reducer'
import media from '../modules/media/reducer'
import kpiEnv from '../modules/kpienvironnementaux/reducer'
import files from '../modules/files/reducer'
import spreadCfa from '../modules/spreadcfa/reducer'
import certificates from '../modules/certificates/reducer'
import draftRequests from '../modules/draftrequests/reducer'
import draftRequest from '../modules/draftrequests/draftRequest/reducer'
import { groupingSpaces } from '../modules/groupingSpaces/groupingSpacesReducer'
import { equipements } from '../modules/equipements/reducers'
import syntheseDemandeReducer from '../modules/demandes/syntheseDemandeReducer'

// TODO pas moyen de faire autrement ?
const identityReducer = (state = null) => state

export default combineReducers({
    properties: identityReducer,
    user: user,
    notifications,
    demandes: demandesReducer,
    demande: demandePageReducer,
    syntheseDemande: syntheseDemandeReducer,
    dcim,
    clients,
    configuration,
    navigation,
    localisations: localisationReducer,
    services: gesteReducer,
    twoFA,
    preferenceDisplay,
    media,
    files,
    spreadCfa,
    draftRequests,
    draftRequest,
    groupingSpaces,
    // profil client
    dashboard,
    visiteurs: visiteurs,
    documents: documentReducer,
    forfait,
    hostingSpaces,
    deliveryInformation,
    users,
    groups,
    access,
    cgu,
    clientPreference,
    recurrentRemoteHands,
    homepageMessage,
    equipements,
    kpiEnv,
    certificates
})
