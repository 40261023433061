import React, { PureComponent, Fragment } from 'react'
// lib
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { SubmissionError, reduxForm } from 'redux-form'
// components
// internal components
import Headline from '../../../components/Headline'
import EmptySpace from '../../../components/EmptySpace'
import TwoFactorsInformation from '../../../modules/2fa/TwoFactorsInformation'
import Check2FACodeForm, { validate } from '../../../modules/2fa/Check2FACodeForm'
import Loader from '../../../components/Loader'
// api
import { fetchQRCode, activate2FA } from '../login/api'
// utils
import { fieldsSelector } from 'data4-commons/utils'
// const
import { AUTH_STEP } from '../login/const'

export const handleActivate = (values, dispatch, props) => {
    return activate2FA(values)
        .then((res) => {
            props.change('redirection', res.redirection)
            return Promise.resolve(AUTH_STEP.SUCCESS_ACTIVATE_2FA)
        }, (e) => {
            return props.handleError(e)
                .catch((error) => {
                    throw new SubmissionError({
                        code: {
                            id: `login.error.${error.message}`
                        }
                    })
                })
        })

}

class Activate2FAPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            secret: null,
            qrCode: null,
            loading: false,
            error: false
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        fetchQRCode(this.props.credentials)
            .catch((e) => {
                if (e.status === 412) {
                    return e.json()
                        .then((res) => {
                            this.setState({
                                secret: res.secret,
                                qrCode: res.qrCode,
                                loading: false
                            })
                        }, (jsonError) => {
                            console.error('Erreur Json', jsonError)
                            // erreur de json
                            this.setState({
                                loading: false,
                                error: true
                            })
                        })
                }
                // d'autres erreurs de QR code
                this.setState({
                    loading: false,
                    error: true
                })
            })
    }


    render() {
        const {
            credentials: {
                login
            },
            submitting,
            error: reduxFormError,
            handleSubmit
        } = this.props
        const {
            qrCode,
            secret,
            loading,
            error
        } = this.state
        return (
            <Fragment>
                <Headline
                    label={<FormattedMessage id="login.2fa.title" />}
                    type="secondary"
                />
                {
                    loading ? <Loader /> : (
                        error ? (
                            <EmptySpace
                                iconName="page-2factors"
                                label={<FormattedMessage id="login.error.TWO_FA_ERROR" />}
                            />
                        ) : (
                            (qrCode || secret) && (
                                <Fragment>
                                    <p className="contenu">
                                        <FormattedMessage id="login.2fa.forced_information" />
                                    </p>
                                    <TwoFactorsInformation
                                        email={login}
                                        qrCode={qrCode}
                                        secret={secret}
                                    />
                                    <Check2FACodeForm
                                        submitting={submitting}
                                        error={reduxFormError}
                                        handleSubmit={handleSubmit}
                                    />
                                </Fragment>
                            )
                        )
                    )
                }
            </Fragment>
        )
    }
}

Activate2FAPage.propTypes = {
    form: PropTypes.string.isRequired,
    handleError: PropTypes.func.isRequired
}

const formValuesSelector = fieldsSelector(['login', 'password'])

const mapStateToProps = (state, props) => ({
    credentials: formValuesSelector(state, props)
})

export default compose(
    reduxForm({
        validate
    }),
    connect(mapStateToProps)
)(Activate2FAPage)
