import React, { PureComponent } from 'react'
// lib
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
// components
import { Button, Form } from 'reactstrap'
// internal components
import { TextField } from '../../../components/reduxForm'
// api
// utils
import { validateMandatoryFields } from 'data4-commons/form/utils'

const validate = validateMandatoryFields(['login', 'password'])

class LoginForm extends PureComponent {

    render() {
        const {
            intl,
            // redux-form
            submitting,
            handleSubmit
        } = this.props
        return (
            <Form
                className="form-standard login-form"
                onSubmit={handleSubmit}
            >
                <Field
                    name="login"
                    component={TextField}
                    placeholder={intl.formatMessage({ id: 'login.identify.placeholder' })}
                    size="full"
                />
                <div className="form-validation">
                    <Button
                        className="btn-uppercase"
                        color="primary"
                        disabled={submitting}
                        type="submit"
                    >
                        <FormattedMessage id="btn.continue" />
                    </Button>
                </div>
            </Form>
        )
    }
}

LoginForm.propTypes = {
    intl: intlShape.isRequired,
    // redux-form
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func
}

export default compose(
    reduxForm({
        validate
    }),
    injectIntl
)(LoginForm)
