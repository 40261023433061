import { combineReducers } from 'redux'
import { UPDATE_ACCESS_PREFERENCE, UPDATE_EMAILS_PREFERENCE } from './actions'

const access = (state = null, action) => {
    switch (action.type) {
        case UPDATE_ACCESS_PREFERENCE:
            return action.payload
        default:
            return state
    }
}

const emails = (state = null, action) => {
    switch (action.type) {
        case UPDATE_EMAILS_PREFERENCE:
            return action.payload
        default:
            return state
    }
}

export default combineReducers({
    access,
    emails
})
