import React from 'react'
import { FormattedMessage as IntlFormattedMessage, intlShape } from 'react-intl'
import { pure } from 'recompose'
import messageShape from '../i18n/messageShape'

/**
 * Retourne le premier id qui correspond à un message intl.
 *
 * @param id
 * @param ids
 * @param intl
 * @returns {*}
 */
const findSuitableId = (id, ids = [id], intl) => {
    for (const key of ids) {
        if (intl.messages[key]) {
            return key
        }
    }
    return ids[ids.length - 1]
}

/**
 * Wrapper pour le composant `FormattedMessage` de react-intl afin de mieux fonctionner
 * avec le format de messages utilisé par Spring :
 *
 * - utiliser la prop `id` pour un message simple
 * - utiliser la prop `ids` pour un tableau de codes dont le premier avec une correspondance sera
 * utilisé (fallback `id`)
 */
const ExtendedFormattedMessage = ({
    message: {
        id,
        ids,
        defaultMessage,
        description,
        values
    }
}, { intl }) => {
    const realId = findSuitableId(id, ids, intl)
    const descriptor = {
        id: realId,
        defaultMessage: defaultMessage || realId,
        description,
        values
    }
    return <IntlFormattedMessage {...descriptor} />
}

ExtendedFormattedMessage.propTypes = {
    message: messageShape.isRequired
}

ExtendedFormattedMessage.contextTypes = {
    intl: intlShape
}

export default pure(ExtendedFormattedMessage)
