import React from 'react'
// lib
import PropTypes from 'prop-types'
import { FormattedHTMLMessage } from 'react-intl'
// utils
import { insertSpaceEveryChars } from 'data4-commons/utils/stringUtils'

const TwoFactorsHelpText = ({
    email,
    secret
}) => {
    return (
        <div className="c-background-card">
            <FormattedHTMLMessage
                id="2fa.help_text"
                values={{
                    email,
                    secret: insertSpaceEveryChars(secret, 4)
                }}
            />
        </div>
    )
}

TwoFactorsHelpText.propTypes = {
    email: PropTypes.string.isRequired,
    secret: PropTypes.string.isRequired
}

export default TwoFactorsHelpText
