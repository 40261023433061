import React, { PureComponent } from 'react'
// lib
import PropTypes from 'prop-types'
import classnames from 'classnames'
// components
import { Creatable as CreatableSelect, components as SelectComponents } from 'react-select'
import { FormGroup } from 'reactstrap'
// internal components
import FormLabel from '../FormLabel'
// utils
import { metaShape, inputShape, sizeShape } from './shapes'
import { hasText } from 'data4-commons/utils/stringUtils'

const createOption = (label) => ({
    label,
    value: label,
})

const getReduxFormValue = (options, inputValue) => {
    if (hasText(inputValue)) {
        // Duplication interdite
        if (!options.some(option => option.value === inputValue)) {
            return options.concat(createOption(inputValue)).map(option => option.value)
        }
    }
    return options.map(option => option.value)
}

const CustomControl = (props) => (
    <SelectComponents.Control
        {...props}
        className="form-control"
    />
)

const components = {
    DropdownIndicator: null,
    Control: CustomControl
}

class ChipsInput extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            inputValue: '',
            options: []
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.input.value !== prevProps.input.value) {
            this.setState({
                options: (this.props.input.value || []).map(createOption)
            })
        }
    }
    
    handleChange = (options, { action }) => {
        // dispatch value dans redux-form
        this.props.input.onChange(options.map(option => option.value))
    }
    
    handleBlur = (event) => {
        const {
            inputValue,
            options
        } = this.state
        this.props.input.onBlur(getReduxFormValue(options, inputValue))
        this.setState({
            inputValue: ''
        })
    }
    handleKeyDown = (event) => {
        const {
            inputValue,
            options
        } = this.state
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault()
            this.props.input.onChange(getReduxFormValue(options, inputValue))
            this.setState({
                inputValue: ''
            })
        }
    }
    
    handleInputChange = (inputValue, { action }) => {
        // pour les actions input-blur et les autres, on garde la valeur input et passe en méthode handleBlur
        if (action === 'input-change') {
            this.setState({
                inputValue: inputValue
            })
        }
    }
    
    render() {
        const {
            disabled,
            mandatory,
            label,
            size,
            meta: {
                touched,
                error
            },
            input: {
                value,
                onBlur,
                ...input
            },
            ...props
        } = this.props
        const {
            inputValue,
            options
        } = this.state
        return (
            <FormGroup
                className={classnames('form-select form-input multi-select', {
                    [`form-${size}`]: size,
                    'form-error': error && touched
                })}
            >
                {
                    label && (
                        <FormLabel
                            label={label}
                            mandatory={mandatory}
                        />
                    )
                }
                <div className="group-form-control">
                    <CreatableSelect
                        {...props}
                        {...input}
                        value={options}
                        inputValue={inputValue}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        onInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        classNamePrefix="form-select"
                        isDisabled={disabled}
                        components={components}
                    />
                    {
                        touched && error && (
                            <div className="error">{error}</div>
                        )
                    }
                </div>
            </FormGroup>
        )
    }
}

ChipsInput.defaultProps = {
    size: 'md',
    placeholder: ''
}

ChipsInput.propTypes = {
    size: sizeShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
    disabled: PropTypes.bool
}

export default ChipsInput

