import { combineReducers } from 'redux'
import createReducer from 'data4-commons/middlewares/api/createReducer'
import {
    GET_RACKS_BY_ROOM,
    GET_CONNECTIONS_BY_PORT,
    GET_PORTS_BY_PANEL,
    GET_PANELS_BY_RACK,
    GET_DUPLEX_CONNECTIONS_BY_PORT
} from './actions'

const racks = createReducer(GET_RACKS_BY_ROOM)
const panels = createReducer(GET_PANELS_BY_RACK)
const ports = createReducer(GET_PORTS_BY_PANEL)
const duplexConnections = createReducer(GET_DUPLEX_CONNECTIONS_BY_PORT)
const connections = createReducer(GET_CONNECTIONS_BY_PORT)

export default combineReducers({
    racks,
    panels,
    ports,
    duplexConnections,
    connections
})
