import { combineReducers } from 'redux'
import {
    GET_KPI_REPORT_DATA,
    GET_KPI_REPORTS_CLIENT,
    GET_KPI_REPORTS_DATACENTER,
    CHECK_CALC_METHOD_DOC, GET_KPI_DATACENTERS_PUE_WUE
} from './actions'
import createReducer from 'data4-commons/middlewares/api/createReducer'
import reportsZone from './mysmartdc/reducer'
import { datacenterConfiguration } from './client/reducer'

const reportsDatacenter = createReducer(GET_KPI_REPORTS_DATACENTER)

const reportsClient = createReducer(GET_KPI_REPORTS_CLIENT)

const reportData = createReducer(GET_KPI_REPORT_DATA)

const reportDatacentersPueWue = createReducer(GET_KPI_DATACENTERS_PUE_WUE)

const calcMethodDoc = (state = false, action) => {
    switch (action.type) {
        case CHECK_CALC_METHOD_DOC:
            return action.payload
        default:
            return state
    }
}

const reportsDC = combineReducers({ reportsDatacenter, datacenterConfiguration })
export default combineReducers({
    reportsDC,
    reportsClient,
    reportData,
    reportDatacentersPueWue,
    calcMethodDoc,
    reportsZone
})
