import React from 'react'
// lib
import { FormattedMessage } from 'react-intl'

const TwoFactorsInstructions = () => {
    return (
        <div className="two-fa-instructions">
            <FormattedMessage id="2fa.instructions" />
        </div>
    )
}

export default TwoFactorsInstructions