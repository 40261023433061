import React from 'react'
import { Spinner } from 'reactstrap'
import { pure } from 'recompose'

const Loader = ({ section }) => (
    <Spinner
        color="primary"
        className={`spinner-placeholder ${section && 'spinner-border-'+section}`}
    />
)

export default pure(Loader)
