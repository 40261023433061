import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const askToActivate2FA = () => fetchFactory('/utilisateurs/ask_activate_2fa', {
   method: 'PUT'
}, '/api')

export const activate2FA = (values) => fetchFactory('/utilisateurs/activate_2fa', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const disable2FA = () => fetchFactory('/utilisateurs/disable_2fa', {
    method: 'POST'
}, '/api')

export const fetch2FADetails = () => fetchFactory('/utilisateurs/2fa_details', {}, '/api')
