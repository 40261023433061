import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose, pure } from 'recompose'
import { FormGroup } from 'reactstrap'
import FormLabel from '../../FormLabel'
import Select from '../../Select'
import { metaShape, inputShape, sizeShape } from '../shapes'
import optionsMap from './optionsMap'
import { FormattedMessage } from 'react-intl'

class SelectField extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            // En mobile on perd la valeur focus en blur (blurInputOnSelect = true)
            // Pour synchroniser la valeur focus lors du blur, on persiste la valeur
            // en state
            selectedOption: null,
            noOptionsMessage: this.props.isSearchable ? () => <FormattedMessage id="global.empty_search_results" /> : undefined
        }
    }

    getSelectedOption = (values, map = {}) => {
        const {
            isMulti
        } = this.props
        return isMulti ? (values || []).map(value => map[value]) : map[values]
    }

    componentDidMount() {
        const {
            meta: {
                initial,
                pristine
            },
            optionsMap
        } = this.props
        this.setState({
            selectedOption: this.getSelectedOption(pristine ? initial : this.props.input.value, optionsMap)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.optionsMap !== this.props.optionsMap || prevProps.input.value !== this.props.input.value) {
            this.setState({
                selectedOption: this.getSelectedOption(this.props.input.value, this.props.optionsMap)
            })
        }
    }

    handleChange = (selectedOption, action) => {
        this.setState({
            selectedOption
        })
        if (!this.props.changeValueOnBlur && selectedOption) {
            this.props.input.onChange(this.props.isMulti ? selectedOption.map(option => option.value) : selectedOption.value)
        }
    }
    handleBlur = () => {
        // FIXME trouver un meilleur moyen pour synchroniser la valeur changée
        // Ici Timeout permet d'attendre la mise à jour du state
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
            const {
                selectedOption
            } = this.state
            this.props.input.onBlur(this.props.isMulti ? selectedOption.map(option => option.value) : (selectedOption && selectedOption.value))
        }, 0)
    }

    render() {
        const {
            size,
            label,
            mandatory,
            meta: {
                touched,
                error
            },
            input: {
                onChange,
                onBlur,
                value,
                ...input
            },
            options,
            optionsMap,
            isMulti,
            isSearchable,
            className,
            information,
            ...props
        } = this.props
        const {
            selectedOption,
            noOptionsMessage
        } = this.state
        return (
            <FormGroup
                className={classnames('form-select form-input', className, {
                    'multi-select': !!isMulti,
                    [`form-${size}`]: size,
                    'form-error': error && touched
                })}
            >
                {
                    label && (
                        <FormLabel
                            label={label}
                            mandatory={mandatory}
                        />
                    )
                }
                <div className="group-form-control">
                    <div className={classnames({ 'form-input': !!information })}>
                        <Select
                            {...props}
                            {...input}
                            isMulti={isMulti}
                            isSearchable={isSearchable}
                            value={selectedOption}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            options={options}
                            noOptionsMessage={noOptionsMessage}
                        />
                        {information}
                    </div>
                    {
                        touched && error && (
                            <div className="error">{error}</div>
                        )
                    }
                </div>
            </FormGroup>
        )
    }
}

SelectField.propTypes = {
    size: sizeShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.oneOfType([PropTypes.node, PropTypes.number, PropTypes.string]).isRequired
        })
    ),
    /**
     * Indiquer si c'est un multi-select
     */
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    disabled: PropTypes.bool,
    /**
     * Classes sur la racine
     */
    className: PropTypes.string,
    /**
     * Faire sorte que la valeur est dispatch au store du redux-form seulement quand "onBlur" est provoqué
     */
    changeValueOnBlur: PropTypes.bool,
    /**
     * Informations à côté du champ Select
     */
    information: PropTypes.node
}

SelectField.defaultProps = {
    size: 'md',
    isSearchable: false
}

export default compose(
    optionsMap,
    pure
)(SelectField)
