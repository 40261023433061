import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Icon = ({ name, className, ...props }) => (
	<span
		{...props}
		className={classnames(['icon', `icon-${name}`, className])}
	/>
)

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string
}

export default Icon
