import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { getTimeZoneLabel } from '../../utils/dateUtils'
import IconTooltip from '../IconTooltip'

const TimeZoneInformation = ({
    date,
    timeZone,
    tooltip
}) => (
    <div className="form-timezone">
        {getTimeZoneLabel(timeZone, date)}
        <IconTooltip
            iconName="navigation-information"
            tooltip={tooltip}
        />
    </div>
)

TimeZoneInformation.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    timeZone: PropTypes.string.isRequired,
    tooltip: PropTypes.node.isRequired
}

export default pure(TimeZoneInformation)
