import { AUTHENTICATE } from './dcimActions'
import { combineReducers } from 'redux'
import zonesReducer from '../dashboard/dcim/zones/reducer'
import trendsReducer from '../dashboard/dcim/trends/reducer'

const initialState = {}

const authentication = (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return action.payload

		default:
			return state
	}
}
export default combineReducers({
	authentication,
	zones: zonesReducer,
	trends: trendsReducer
})
