const INDISPONIBLE = 1
const DEGRADE = 2
const SANS_IMPACT = 3

export const CRITICITE = {
    INDISPONIBLE,
    DEGRADE,
    SANS_IMPACT
}

export const CRITICITES = [
    INDISPONIBLE,
    DEGRADE,
    SANS_IMPACT
]