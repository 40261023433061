import { getAreasKpiConfigurationByBuilding as getAreasKpiConfigurationByBuildingApi, getZoneConfiguration as getZoneConfigurationApi, updateRapportParZone as updateRapportParZoneApi, } from './api'
import middle from 'data4-commons/middlewares/api/middle'
import configureCache from 'data4-commons/middlewares/api/cache'
import { _kpiDcimBuildings } from './areas/buildings/selectors'
import { zoneConfigurationSelector } from './areas/selectors'

const prefix = 'RAPPORT_PAR_ZONE/'

export const GET_AREAS_KPI_CONFIG = `${prefix}GET_AREAS_KPI_CONFIG`
export const GET_AREAS_KPI_CONFIG_LOADING = `${prefix}GET_AREAS_KPI_CONFIG_LOADING`
export const GET_AREAS_KPI_CONFIG_ERROR = `${prefix}GET_AREAS_KPI_CONFIG_ERROR`
export const CREATE_RAPPORT_ZONE = `${prefix}CREATE_RAPPORT_ZONE`

export const GET_ZONE_CONFIGURATION = `${prefix}GET_ZONE_CONFIGURATION`
export const GET_ZONE_CONFIGURATION_LOADING = `${prefix}GET_ZONE_CONFIGURATION_LOADING`
export const GET_ZONE_CONFIGURATION_ERROR = `${prefix}GET_ZONE_CONFIGURATION_ERROR`
export const UPDATE_ZONE_CONFIGURATION = `${prefix}UPDATE_ZONE_CONFIGURATION`

export const GET_DCIM_BUILDINGS = `${prefix}GET_DCIM_BUILDINGS`
const cacheDcimBuildings= configureCache(60 * 60 * 12)

export const getKpiDcimBuildings = () => middle(GET_DCIM_BUILDINGS, {
    api: '/api',
    url: '/kpi-configuration/buildings',
    method: 'GET',
    cache: cacheDcimBuildings,
    selector: _kpiDcimBuildings
})

export const getAreasKpiConfigurationByBuilding = (buildingId) => (dispatch) => {
    dispatch({
        type: GET_AREAS_KPI_CONFIG_LOADING
    })
    return getAreasKpiConfigurationByBuildingApi(buildingId)
        .then((configs) => {
            dispatch({
                type: GET_AREAS_KPI_CONFIG,
                payload: configs
            })
            return configs
        }, (e) => {
            dispatch({
                type: GET_AREAS_KPI_CONFIG_ERROR
            })
            throw e
        })
}

export const createRapportZone = (id, values) => (dispatch) => {
    return updateRapportParZoneApi(id, values)
        .then((rapport) => {
            dispatch({
                type: CREATE_RAPPORT_ZONE,
                payload: rapport
            })
            return rapport
        })
}

export const getZoneConfiguration = (id) => (dispatch, getState) => {
    const oldZoneConfig = zoneConfigurationSelector(getState())
    if (oldZoneConfig && oldZoneConfig.id === id) {
        return Promise.resolve(oldZoneConfig)
    }
    dispatch({
        type: GET_ZONE_CONFIGURATION_LOADING
    })
    return getZoneConfigurationApi(id)
        .then((config) => {
            dispatch({
                type: GET_ZONE_CONFIGURATION,
                payload: config
            })
            return config
        }, (e) => {
            dispatch({
                type: GET_ZONE_CONFIGURATION_ERROR
            })
            throw e
        })
}

export const updateZoneConfiguration = (id, values) => (dispatch) => {
    return updateRapportParZoneApi(id, values)
        .then((config) => {
            dispatch({
                type: UPDATE_ZONE_CONFIGURATION,
                payload: config
            })
        })
}

