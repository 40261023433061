import React from 'react'
import PropTypes from 'prop-types'
import errorShape from './errorShape'
import { compose, pure, withPropsOnChange, branch, renderNothing } from 'recompose'
import toI18N from '../../i18n/toI18N'
import _ from 'lodash'

const Errors = ({ errors, alwaysAsList }) => {
    if (errors.length === 1 && !alwaysAsList) {
        return (
            <div className="error">
                {toI18N(errors[0])}
            </div>
        )
    }
    return (
        <ul className="error">
            {
                errors.map(error => (
                    <li key={_.uniqueId()}>
                        {toI18N(error)}
                    </li>
                ))
            }
        </ul>
    )
}

Errors.propTypes = {
    /**
     * Le tableau des erreurs à afficher
     */
    errors: PropTypes.arrayOf(errorShape).isRequired,
    /**
     * Booléen indiquant si l'on doit toujours afficher les erreurs sous forme de liste, même quand il n'y a qu'une
     * seule erreur
     */
    alwaysAsList: PropTypes.bool.isRequired
}
Errors.defaultProps = {
    errors: [],
    alwaysAsList: false
}

export default compose(
    branch(
        (props) => !props.errors,
        renderNothing
    ),
    withPropsOnChange(
        ['errors'],
        ({ errors }) => ({
        errors: (errors && !Array.isArray(errors)) ? [errors] : errors
    })),
    pure
)(Errors)
