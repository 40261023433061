import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Button } from 'reactstrap'
import Icon from './Icon'

// TODO à remplacer les icônes cliquables par ce composant
const IconButton = ({
    iconName,
    iconClassName,
    ...buttonProps
}) => {
    return (
        <Button
            {...buttonProps}
        >
            <Icon
                name={iconName}
                className={iconClassName}
            />
        </Button>
    )
}

IconButton.propTypes = {
    /**
     * Le nom de l'icon
     */
    iconName: PropTypes.string.isRequired,
    /**
     * Le nom de classe css sur l'icon
     */
    iconClassName: PropTypes.string
}
IconButton.defaultProps = {
    /**
     * Surcharger celui de defaultProp du Button reactstrap
     */
    color: 'auto'
}
/**
 * Wrapper button d'{@link Icon}.
 * En dehors des props documentés, tous les autres
 * sont appliqués au button
 */
export default pure(IconButton)