import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormGroup, Input, FormText } from 'reactstrap'
import { defaultId } from './defaultId'
import FormLabel from '../FormLabel'
import { metaShape, inputShape, sizeShape } from './shapes'

class UploadFile extends PureComponent{
    componentDidUpdate(prevProps) {
        // Supprimer le fichier
        if (prevProps.input.value !== this.props.input.value && !this.props.input.value) {
            this.input.value = ''
        }
    }

    /**
     * Méthode pour convertir FileList en Array.
     * Elle permet de fix le bug qu'en firefox prevProps.input.value
     * et this.props.input.value sont toujours même lors que la valeur
     * est du type FileList
     * @param delegate
     * @return {function(*): *}
     */
    adaptFileEventToValue = delegate => {
        return e => {
            const files = Array.from(e.target.files)
            return delegate(files)
        }
    }
    render() {
        const {
            size,
            label,
            mandatory,
            description,
            meta: {
                touched,
                error
            },
            input: {
                onChange,
                onFocus,
                onBlur,
                ...input
            },
            handleClickDelete,
            ...props
        } = this.props
        return (
            <FormGroup
                className={classnames('form-data', {
                    [`form-${size}`]: size,
                    'form-error': error && touched
                })}>
                {
                    label && (
                        <FormLabel
                            label={label}
                            mandatory={mandatory}
                        />
                    )
                }
                <Input
                    {...props}
                    {...input}
                    onChange={this.adaptFileEventToValue(onChange)}
                    onBlur={this.adaptFileEventToValue(onBlur)}
                    onFocus={() => {
                        handleClickDelete()
                        this.adaptFileEventToValue(onFocus)
                    }}
                    innerRef={input => this.input = input}
                    value={undefined}
                    type="file"
                />
                {
                    touched && error && (
                        <div className="error">{error}</div>
                    )
                }
                {
                    description && <FormText color="muted">{description}</FormText>
                }
            </FormGroup>
        )
    }
}

UploadFile.propTypes = {
    size: sizeShape,
    id: PropTypes.any.isRequired,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    description: PropTypes.node,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
}

UploadFile.defaultProps = {
    size: 'md'
}

export default defaultId(UploadFile)

