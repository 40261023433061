import { combineReducers } from 'redux'
import {
    GET_CLIENTS,
    GET_CLIENT
} from './actions'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const clients = createReducer(GET_CLIENTS)
const client = createReducer(GET_CLIENT)

export default combineReducers({
    list: clients,
    selected: client
})
