import React from 'react'
// lib
import PropTypes from 'prop-types'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
import { pure } from 'recompose'
// component
import { Button, Form } from 'reactstrap'
// internal components
import { Errors, PasswordTextField } from '../../../components/reduxForm/index'
// utils
import { validateMandatoryFields } from 'data4-commons/form/utils'

export const validate = validateMandatoryFields(['newPassword', 'confirmation'])

const ChangePasswordForm = ({
    passwordMinLength,
    submitting,
    handleSubmit,
    error
}) => {
    return (
        <Form
            className="form-standard"
            onSubmit={handleSubmit}
        >
            <p className="contenu">
                <FormattedHTMLMessage
                    id="change_password.text"
                    values={{ minLength: passwordMinLength }}
                />
            </p>
            <Field
                name="newPassword"
                component={PasswordTextField}
                label={<FormattedMessage id="login.change_password.password.label" />}
            />
            <Field
                name="confirmation"
                component={PasswordTextField}
                label={<FormattedMessage id="login.change_password.confirmation.label" />}
            />
            <Errors errors={error} />
            <div className="d-flex justify-content-center">
                <Button
                    color="primary"
                    className="btn-uppercase"
                    disabled={submitting}
                    type="submit"
                >
                    <FormattedMessage id="login.change_password.btn.change_password" />
                </Button>
            </div>
        </Form>
    )
}

ChangePasswordForm.propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    passwordMinLength: PropTypes.number
}

export default pure(ChangePasswordForm)
