// lib
import { reduxForm } from 'redux-form'

let submitTimeout

/**
 * Enhancer du redux-form qui permet d'effectuer la soumission dès que la valeur d'un champ est changée.
 * Le flag "submitWhenDirty" permet d'indiquer que le redux-form est submit seulement s'il est dirty, par défaut c'est à true
 */
export default ({
    submitWhenDirty = true,
    ...config
}) => reduxForm({
    ...config,
    onChange: (values, dispatch, props) => {
        if (submitTimeout) {
            clearTimeout(submitTimeout)
        }
        if (!submitWhenDirty || props.dirty) {
            submitTimeout = setTimeout(props.submit, 0)
        }
    }
})