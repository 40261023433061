import React from 'react'
// lib
import PropTypes from 'prop-types'
import { pure } from 'recompose'
// internal component
import TwoFactorsHelpText from './TwoFactorsHelpText'
import TwoFactorsInstructions from './TwoFactorsInstructions'
import TwoFactorsQRCode from './TwoFactorsQRCode'

const TwoFactorsInformation = ({
    email,
    secret,
    qrCode
}) => {
    return (
        <div className="two-fa-information">
            <div>
                <TwoFactorsInstructions />
                <TwoFactorsHelpText
                    email={email}
                    secret={secret}
                />
            </div>
            <TwoFactorsQRCode {...qrCode} />
        </div>
    )
}

TwoFactorsInformation.propTypes = {
    email: PropTypes.string.isRequired,
    secret: PropTypes.string,
    qrCode: PropTypes.shape({
        filetype: PropTypes.string.isRequired,
        base64: PropTypes.string.isRequired
    })
}

export default pure(TwoFactorsInformation)