import { checkFormValue, lowercase, uppercase, digits, specialchar, accent, length } from 'data4-commons/utils/validatorUtils'

/**
 * Valider le mot de passe avec centaines règles
 * @param password
 * @param confirmation
 * @param passwordMinLength
 * @return object soit null, soit un objet d'erreur peut contenir les attributs "password" ou "confirmation"
 */
export const validatePassword = (password, confirmation, passwordMinLength) => {
    if (password !== confirmation) {
        return {
            confirmation: {
                id: 'change_password.error.confirm'
            }
        }
    }
    const errorMotPasse = checkFormValue(password, [
        lowercase,
        uppercase,
        digits,
        specialchar,
        accent,
        length(passwordMinLength)
    ])
    if (errorMotPasse) {
        return {
            password: errorMotPasse
        }
    }
}