import { logOut as logOutApi, changeNotification as changeNotificationApi } from './userApi'

const prefix = 'USER/'
const _ = actionName => `${prefix}${actionName}`

export const LOGIN = _('LOGIN')
export const LOGOUT = _('LOGOUT')
export const UPDATE_NOTIFICATION_TYPE = _('UPDATE_NOTIFICATION_TYPE')


export const loginAction = (user) => ({
	type: LOGIN,
	payload: user
})

export const logoutAction = () => ({
	type: LOGOUT
})

export const logOut = () => (dispatch) => {
	return logOutApi()
		.then(() => {
			dispatch(logoutAction())
		})
}

export const changeNotificationType = (values) => (dispatch) => {
    return changeNotificationApi(values)
        .then((user) => {
            dispatch({
                type: UPDATE_NOTIFICATION_TYPE,
                payload: user
            })
        })
}

