import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../Icon'
import SelectField from './SelectField'
import { components } from 'react-select'
import { metaShape, inputShape, sizeShape } from '../shapes'
import { getStatusClassName } from '../../../utils/statusUtils'

const CustomDropdownIndicator = (props) => {
    return !props.isDisabled && (
        <components.DropdownIndicator
            {...props}
            className="dropdown-indicator"
        >
            <Icon name="navigation-fleche-bas" />
        </components.DropdownIndicator>
    )
}

const CustomIndicatorSeparator = null

const CustomValueContainer = ({
    children,
    ...props
}) => (
    <components.ValueContainer
        {...props}
        className="value-container"
    >
        {children}
    </components.ValueContainer>
)

const CustomSingleValue = ({
    children,
    ...props
}) => (
    <components.SingleValue
        {...props}
        className="single-value"
    >
        {children}
    </components.SingleValue>
)

class DropDownSelect extends PureComponent {
    customControl = ({
        children,
        ...props
    }) => {
        // return open, close ou in-progress
        const statutClassname = this.props.input.name === 'statut' ? getStatusClassName(this.props.options[this.props.meta.initial - 1].value) : ''
        return (
            <div className="form-control dropdown-select">
                <components.Control
                    {...props}
                    className={`btn btn-select btn-secondary btn-${this.props.size} ${statutClassname}`}
                >
                    {children}
                </components.Control>
            </div>
        )
    }

    render() {
        const {
            size,
            label,
            mandatory,
            meta,
            input,
            options,
            disabled,
            components,
            ...props
        } = this.props
        return (
            <SelectField
                {...props}
                size={size}
                label={label}
                mandatory={mandatory}
                meta={meta}
                input={input}
                options={options}
                disabled={disabled}
                className="form-dropdown"
                components={{
                    Control: this.customControl,
                    DropdownIndicator: CustomDropdownIndicator,
                    IndicatorSeparator: CustomIndicatorSeparator,
                    ValueContainer: CustomValueContainer,
                    SingleValue: CustomSingleValue,
                    ...components
                }}
            />
        )
    }
}

DropDownSelect.propTypes = {
    size: sizeShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired
        })
    ),
    disabled: PropTypes.bool
}

DropDownSelect.defaultProps = {
    size: 'sm'
}

export default DropDownSelect
