import { combineReducers } from 'redux'
import { UPDATE_REQUEST_FILTERS_PREFERENCE } from './actions'

const requestFilters = (state = null, action) => {
    switch (action.type) {
        case UPDATE_REQUEST_FILTERS_PREFERENCE:
            return action.payload
        default:
            return state
    }
}

export default combineReducers({
    requestFilters
})
