import React, { PureComponent } from 'react'
// lib
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
// components
import { Button } from 'reactstrap'
// internal components
import Headline from '../../../../components/Headline'
import Icon from '../../../../components/Icon'
import { Errors } from '../../../../components/reduxForm'
import Loader from '../../../../components/Loader'
// utils
import { fieldsSelector } from 'data4-commons/utils'
// const
// api
import { checkPasswordExpiration } from '../../login/api'

class CheckPasswordStatusPage extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            checking: true,
            daysBeforeExpiration: 0
        }
    }

    componentDidMount() {
        return checkPasswordExpiration()
            .then((status) => {
                if (!status.aboutToExpire) {
                    this.goToHome()
                    return
                }
                this.setState({
                    checking: false,
                    daysBeforeExpiration: status.daysBeforeExpiration
                })
            }, (e) => {
                /* */
            })
    }

    goToHome = () => {
        window.location.replace(this.props.redirection)
    }

    render() {
        const {
            handleSubmit,
            submitting,
            error
        } = this.props
        const {
            checking,
            daysBeforeExpiration
        } = this.state
        if (checking) {
            return <Loader />
        }
        return (
            <div className="modal">
                <div className="modal-dialog modal-lg">
                    <Headline
                        label={<FormattedMessage id="login.alert_password_expired.title" />}
                        type="secondary"
                    />
                    <div className="empty-body">
                        <Icon name="page-2factors" />
                        <p className="contenu">
                            <FormattedMessage
                                id="login.alert_password_expired.body"
                                values={{
                                    days_before_expired: daysBeforeExpiration
                                }}
                            />
                        </p>
                    </div>
                    <div className="login-footer">
                        <Button
                            color="primary"
                            className="btn-uppercase"
                            outline
                            onClick={this.goToHome}
                            disabled={submitting}
                        >
                            <FormattedMessage id="login.btn.access_portal" />
                        </Button>
                        <Button
                            color="primary"
                            className="btn-uppercase"
                            onClick={handleSubmit}
                            disabled={submitting}
                        >
                            <FormattedMessage id="login.alert_password_expired.btn.change_password" />
                        </Button>
                    </div>
                    <Errors errors={error} />
                </div>
            </div>
        )
    }
}

const formValuesSelectors = fieldsSelector(['redirection'])

const mapStateToProps = (state, props) => ({
    ...formValuesSelectors(state, props)
})

export default compose(
    reduxForm({}),
    connect(mapStateToProps)
)(CheckPasswordStatusPage)
