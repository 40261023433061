import { combineReducers } from 'redux'
import {
    GET_GROUPS_PREVIEW,
    GET_GROUPS_PREVIEW_LOADING,
    GET_GROUPS_PREVIEW_ERROR,
    CREATE_GROUP,
    UPDATE_GROUP,
    REPLACE_GROUP,
    DELETE_GROUP,
    EXPORT_GROUPS_START,
    EXPORT_GROUPS_FINISH, GET_GROUP
} from './actions'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const value = (state = [], action) => {
    switch (action.type) {
        case GET_GROUPS_PREVIEW:
            return action.payload
        case CREATE_GROUP:
            return state.concat(action.payload)
        case UPDATE_GROUP:
            return state.map((group) => {
                if (group.id === action.payload.id) {
                    return action.payload
                }
                return group
            })
        case REPLACE_GROUP: {
            const deletedGroup = state.find(group => group.id === action.meta.deletedGroupId)
            if (deletedGroup) {
                return state.filter(group => group.id !== action.meta.deletedGroupId)
                    .map((group) => {
                        if (group.id === action.payload.id) {
                            group.nbUsers += deletedGroup.nbUsers
                        }
                        return group
                    })
            }
            return state
        }
        case DELETE_GROUP:
            return state.filter(group => group.id !== action.payload)
        default:
            return state
    }
}

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_GROUPS_PREVIEW_LOADING:
            return true
        case GET_GROUPS_PREVIEW_ERROR:
        case GET_GROUPS_PREVIEW:
            return false
        default:
            return state
    }
}

const error = (state = false, action) => {
    switch (action.type) {
        case GET_GROUPS_PREVIEW_ERROR:
            return true
        case GET_GROUPS_PREVIEW_LOADING:
        case GET_GROUPS_PREVIEW:
            return false
        default:
            return state
    }
}

const previewList = combineReducers({
    loading,
    error,
    value
})

const exporting = (state = false, action) => {
    switch (action.type) {
        case EXPORT_GROUPS_FINISH:
            return false
        case EXPORT_GROUPS_START:
            return true
        default:
            return state
    }
}

const selected = createReducer(GET_GROUP)

export default combineReducers({
    previewList,
    selected,
    exporting
})
