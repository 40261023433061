import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pure } from 'recompose'
import { FormGroup, Input } from 'reactstrap'
import FormLabel from '../../FormLabel'
import { metaShape, inputShape, alignShape } from '../shapes'

const ButtonRadioGroup = ({
    align,
    label,
    options,
    mandatory,
    meta: {
        form,
        touched,
        error
    },
    ...others
}) => {
    const {
        value,
        name
    } = others.input
    return (
        <FormGroup className="form-radio">
            {
                label && (
                    <FormLabel
                        label={label}
                        mandatory={mandatory}
                    />
                )
            }
            <div className={classnames('form-button-group', { 'inline': align === 'row' })}>
                {
                    options.map((option) => {
                        const key = `${form}-${name}-${option.value}`
                        const checked = value === option.value
                        return (
                            <div
                                key={key}
                                className={classnames('selectable-button btn-circle pretty', { checked })}
                            >
                                <Input
                                    {...others.input}
                                    value={option.value}
                                    checked={checked}
                                    type="radio"
                                />
                                <div className="label">{option.label}</div>
                            </div>
                        )
                    })
                     }
            </div>
            {
                touched && error && (
                    <div className="error">{error}</div>
                )
            }
        </FormGroup>
    )
}

ButtonRadioGroup.propTypes = {
    /**
     * Alignement des bouttons, soit vertical ("col"), soit horizontal ("row")
     */
    align: alignShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.any
        })
    ).isRequired,
    input: inputShape.isRequired,
    meta: metaShape.isRequired
}

export default pure(ButtonRadioGroup)
