import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withTooltip from './enhancer/withTooltip'
import Icon from './Icon'

/**
 * Composant du wrapper d'{@link Icon} qui permet d'afficher un tooltip
 */
class IconTooltip extends PureComponent {

	render() {
		const {
		    className,
            iconName,
            iconClassName
		} = this.props
		return (
             <span className={classnames('btn-tooltip', className)}>
                 <Icon
                     name={iconName}
                     className={iconClassName}
                 />
             </span>
		)
	}
}

IconTooltip.propTypes = {
    className: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    iconClassName: PropTypes.string
}

export default withTooltip(IconTooltip)
