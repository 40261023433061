import {
    EXPORT_EQUIPEMENTS_FINISH,
    EXPORT_EQUIPEMENTS_START,
    GET_EQUIPEMENTS_PAGE,
    GET_EQUIPEMENTS_PAGE_ERROR,
    GET_EQUIPEMENTS_PAGE_LOADING,
    GET_EQUIPEMENTS_SOCIETE,
    GET_EQUIPEMENTS_SOCIETE_ERROR,
    GET_EQUIPEMENTS_SOCIETE_LOADING
} from './actions'
import { combineReducers } from 'redux'

const loadingPage = (state = false, action) => {
    switch (action.type) {
        case GET_EQUIPEMENTS_PAGE_LOADING:
            return true
        case GET_EQUIPEMENTS_PAGE:
        case GET_EQUIPEMENTS_PAGE_ERROR:
            return false
        default:
            return state
    }
}

const errorPage = (state = false, action) => {
    switch (action.type) {
        case GET_EQUIPEMENTS_PAGE_ERROR:
            return true
        case GET_EQUIPEMENTS_PAGE:
        case GET_EQUIPEMENTS_PAGE_LOADING:
            return false
        default:
            return state
    }
}

const valuePage = (state = {}, action) => {
    switch (action.type) {
        case GET_EQUIPEMENTS_PAGE:
            return action.payload
        default:
            return state
    }
}

const page = combineReducers({
    loadingPage,
    errorPage,
    valuePage
})


const loading = (state = false, action) => {
    switch (action.type) {
        case GET_EQUIPEMENTS_SOCIETE_LOADING:
            return true
        case GET_EQUIPEMENTS_SOCIETE:
        case GET_EQUIPEMENTS_SOCIETE_ERROR:
            return false
        default:
            return state
    }
}

const error = (state = false, action) => {
    switch (action.type) {
        case GET_EQUIPEMENTS_SOCIETE_ERROR:
            return true
        case GET_EQUIPEMENTS_SOCIETE:
            return false
        default:
            return state
    }
}

const value = (state = [], action) => {
    switch (action.type) {
        case GET_EQUIPEMENTS_SOCIETE:
            return action.payload
        default:
            return state
    }
}

const exporting = (state = false, action) => {
    switch (action.type) {
        case EXPORT_EQUIPEMENTS_FINISH:
            return false
        case EXPORT_EQUIPEMENTS_START:
            return true
        default:
            return state
    }
}

const list = combineReducers({
    loading,
    error,
    value,
    exporting
})

export const equipements = combineReducers({
    page,
    list
})
