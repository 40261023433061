import { CLOSE_DRAFT_REQUEST_FILTERS, OPEN_DRAFT_REQUEST_FILTERS, APPLY_DRAFT_REQUEST_FILTERS, RESET_DRAFT_REQUEST_FILTERS } from './filtersActions'
import { DRAFT_REQUEST_FILTER_NAME, STATIC_FILTERS_INITIAL_VALUES } from './const'
import { combineReducers } from 'redux'
import { actionTypes } from 'redux-form'
import { UPDATE_REQUEST_FILTERS_PREFERENCE } from '../../preferencedisplay/actions'
import { transformDraftRequestsFiltersPreference } from './utils'

const open = (state = false, action) => {
    switch (action.type) {
        case CLOSE_DRAFT_REQUEST_FILTERS:
            return false
        case OPEN_DRAFT_REQUEST_FILTERS:
            return true
        default:
            return state
    }
}

const values = (state = STATIC_FILTERS_INITIAL_VALUES, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE: {
            if (action.meta.form === DRAFT_REQUEST_FILTER_NAME) {
                return action.payload
            }
            return state
        }
        case UPDATE_REQUEST_FILTERS_PREFERENCE:
            return {
                ...state,
                ...transformDraftRequestsFiltersPreference(action.payload)
            }
        case RESET_DRAFT_REQUEST_FILTERS:
        case APPLY_DRAFT_REQUEST_FILTERS:
            return action.payload
        default:
            return state
    }
}

const dirty = (state = false, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE: {
            if (action.meta.form === DRAFT_REQUEST_FILTER_NAME) {
                return false
            }
            return state
        }
        case RESET_DRAFT_REQUEST_FILTERS:
            return false
        case APPLY_DRAFT_REQUEST_FILTERS:
            return action.meta.dirty
        default:
            return state
    }
}

export default combineReducers({
    values,
    open,
    dirty
})
