import { combineReducers } from 'redux'
import {
    GET_FORFAITS,
    GET_FORFAIT_TICKETS,
    START_EXPORT_FORFAIT,
    FINISH_EXPORT_FORFAIT
} from './actions'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const list = createReducer(GET_FORFAITS)

const ticketsConsomptionList = (state = [], action) => {
    switch (action.type) {
        case GET_FORFAIT_TICKETS:
            return action.payload
        default:
            return state
    }
}

const exporting = (state = false, action) => {
    switch (action.type) {
        case START_EXPORT_FORFAIT:
            return true
        case FINISH_EXPORT_FORFAIT:
            return false
        default:
            return state
    }
}

const selectedForfait = combineReducers({
    ticketsConsomptionList,
    exporting
})

export default combineReducers({
    list,
    selectedForfait
})
