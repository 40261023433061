import { editRoomAlias as editRoomAliasApi } from './api'
import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'HOSTING_SPACES/'

export const GET_HOSTING_SPACES = `${prefix}GET_HOSTING_SPACES`

const EDIT_ROOM_ALIAS = `${prefix}EDIT_ROOM_ALIAS`

export const getClientHostingSpaces = () => middle(GET_HOSTING_SPACES, {
    api: '/api',
    url: '/hosting_spaces',
    method: 'GET'
})

export const editRoomAlias = (roomId, values) => (dispatch) => {
    return editRoomAliasApi(roomId, values)
        .then((room) => {
            dispatch({
                type: EDIT_ROOM_ALIAS,
                payload: room
            })
            return room
        })
}
