import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const updateAccessPreference = (values) => fetchFactory('/clients/access_preference', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const updateEmailsPreference = (values) => fetchFactory('/clients/emails_preference', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')