import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const editRoomAlias = (roomId, values) => fetchFactory(`/hosting_spaces/rooms/${roomId}/alias`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const editBuildingAlias = (buildingId, values) => fetchFactory(`/hosting_spaces/buildings/${buildingId}/alias`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')
