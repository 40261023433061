import { combineReducers } from 'redux'
import {
    GET_DRAFT_REQUEST,
    GET_DRAFT_REQUEST_ERROR,
    GET_DRAFT_REQUESTS_LOADING,
    UPDATE_DRAFT_REQUEST, UPDATE_DRAFT_REQUEST_STATUS
} from '../actions'

const loading = (state=false, action) => {
    switch (action.type) {
        case GET_DRAFT_REQUESTS_LOADING:
            return true
        case GET_DRAFT_REQUEST:
        case GET_DRAFT_REQUEST_ERROR:
            return false
        default:
            return state
    }
}

const error = (state=false, action) => {
    switch (action.type) {
        case GET_DRAFT_REQUEST:
            return false
        case GET_DRAFT_REQUEST_ERROR:
            return true
        default:
            return state
    }
}

const request = (state=null, action) => {
    switch (action.type) {
        case GET_DRAFT_REQUEST:
        case UPDATE_DRAFT_REQUEST:
        case UPDATE_DRAFT_REQUEST_STATUS:
            return action.payload
        default:
            return state
    }
}

const draftRequest = combineReducers({
    error,
    loading,
    request
})

export default draftRequest
