import { DEFAULT_REQUEST_STATUS_VALUES, STATIC_FILTERS_INITIAL_VALUES } from './const'

export const transformDraftRequestsFiltersPreference = (preference) => {
    if (!preference) {
        return STATIC_FILTERS_INITIAL_VALUES
    }
    const {
        draftRequest: {
            batiments,
            clientIds,
            siteIds,
            statuts,
            typeIntervention
        }
    } = preference
    return {
        ...STATIC_FILTERS_INITIAL_VALUES,
        batiments: batiments,
        siteIds: siteIds,
        statuts: statuts || DEFAULT_REQUEST_STATUS_VALUES,
        typeIntervention: typeIntervention,
        idsSociete: clientIds
    }
}
