import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormGroup } from 'reactstrap'
import Checkbox from './Checkbox'
import { defaultId } from './defaultId'
import { metaShape, inputShape, sizeShape } from './shapes'

const SingleCheckbox = ({
    meta: {
        touched,
        error
    },
    size,
    ...others
}) => {
    return (
        <FormGroup
            className={classnames('form-checkbox', {
                [`form-${size}`]: size
            })}
        >
            <Checkbox
                checked={others.input.value}
                {...others}
            />
            {
                touched && error && (
                    <div className="error">{error}</div>
                )
            }
        </FormGroup>
    )
}

SingleCheckbox.propTypes = {
    size: sizeShape,
    label: PropTypes.node,
    input: inputShape.isRequired,
    meta: metaShape.isRequired
}

export default defaultId(SingleCheckbox)