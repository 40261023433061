import React from 'react'
import { lifecycle, compose } from 'recompose'
import { withRouter } from 'react-router'
import { ToastContainer } from 'react-toastify'
import IconButton from '../components/IconButton'
import { removeCurrentToaster } from './utils'

const ToasterCloseButton = ({
    closeToast
}) => (
    <IconButton
        className="close"
        iconName="navigation-fermer"
        onClick={closeToast}
    />
)

const Toaster = () => {
    return (
        <ToastContainer
            className="toast-container"
            toastClassName="toast-inner"
            closeButton={<ToasterCloseButton />}
        />
    )
}

export default compose(
    withRouter,
    lifecycle({
        componentDidUpdate(prevProps) {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                removeCurrentToaster()
            }
        }
    })
)(Toaster)
