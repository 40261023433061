import React from 'react'
// lib
import PropTypes from 'prop-types'

const TwoFactorsInformation = ({
    filetype,
    base64
}) => {
    return (
        <div className="two-fa-qr-code">
            <img src={`data:${filetype};base64,${base64}`} alt="QR Code" />
        </div>
    )
}

TwoFactorsInformation.propTypes = {
    filetype: PropTypes.string.isRequired,
    base64: PropTypes.string.isRequired
}

export default TwoFactorsInformation