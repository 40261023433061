export const SUBSCRIBE = 'WS/SUBSCRIBE'
export const UNSUBSCRIBE = 'WS/UNSUBSCRIBE'
export const NOTIFY = 'WS/NOTIFY'

export const notifyAction = (notifications) => {
	return ({
		type: NOTIFY,
		payload: notifications
	})
}
