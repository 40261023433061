import { withProps } from 'recompose'
import toI18N from '../i18n/toI18N'

const mapErrors = withProps(({
    meta: {
        error,
        warning,
        ...others
    }
}) => ({
    meta: {
        error: error ? toI18N(error) : error,
        warning: warning ? toI18N(warning) : warning,
        ...others
    }
}))

export default mapErrors
