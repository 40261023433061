import { buildUrlWithParams, fetchFactory, postMultipart } from 'data4-commons/entities/fetchEntities'

export const downloadReportPdf = (reportType, reportId, values, params) => postMultipart(buildUrlWithParams(`/kpi-report/${reportType}/${reportId}/generate_pdf`, params), {
    body: values,
    acceptBlob: true
}, '/api')

export const addReport = (values) => fetchFactory('/kpi-report/client', {
    method: 'POST',
    body: JSON.stringify(values)
}, '/api')

export const deleteReport = (reportId) => fetchFactory(`/kpi-report/client/${reportId}`, {
    method: 'DELETE'
}, '/api')

export const getClientReport = (reportId) => fetchFactory(`/kpi-report/client/${reportId}`, {
    method: 'GET'
}, '/api')

export const updateReport = (reportId, values) => fetchFactory(`/kpi-report/client/${reportId}`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')
