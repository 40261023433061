import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormGroup, Input } from 'reactstrap'
import { defaultId } from './defaultId'
import FormLabel from '../FormLabel'
import { metaShape, inputShape, sizeShape } from './shapes'
import { FormattedMessage } from 'react-intl'

class AutocompleteTextField extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            userInput: "",
            showSuggestions: false,
            selectedSuggestion: null
        }
        this.suggestionsRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount = () => {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.userInput !== this.state.userInput) {
            this.props.customChange(this.state.userInput, this.state.selectedSuggestion);
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        if (this.suggestionsRef.current && !this.suggestionsRef.current.contains(event.target)) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                selectedSuggestion: null
            })
        }
    }


    onChange = (e) => {
        const userInput = e.currentTarget.value
        if (typeof userInput === 'string' && userInput.trim().length >= 2) {
            this.props.getSuggestions(userInput, this.props.input.name)
                .then((res) => {
                    this.setState({
                        activeSuggestion: 0,
                        showSuggestions: true,
                        filteredSuggestions: res,
                        selectedSuggestion: null,
                        userInput
                    })
                })
                .catch(() => this.setState({
                    activeSuggestion: 0,
                    showSuggestions: false,
                    filteredSuggestions: [],
                    selectedSuggestion: null,
                    userInput
                }))
        } else {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                selectedSuggestion: null,
                userInput
            })
        }
    }

    onClick = (suggestion) => (e) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
            selectedSuggestion: suggestion
        })
    }

    onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (e.keyCode === 13) { // enter key
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                selectedSuggestion: filteredSuggestions[activeSuggestion],
                userInput: filteredSuggestions[activeSuggestion]
            });
        } else if (e.keyCode === 38) { // up arrow
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) { // down arrow
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            activeSuggestion,
            filteredSuggestions,
            showSuggestions,
            userInput
        } = this.state
        const {
            size,
            children,
            id,
            label,
            mandatory,
            meta: {
                touched,
                error
            },
            input,
        } = this.props
        return (
            <FormGroup
                className={classnames('form-input', {
                    [`form-${size}`]: size,
                    'form-error': error && touched
                })}
            >
                {
                    label && (
                        <FormLabel
                            label={label}
                            mandatory={mandatory}
                        />
                    )
                }
                <div className="group-form-control autocomplete">
                    <Input
                        id={id}
                        {...input}
                        label={label}
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        value={this.props.inputValue ? this.props.inputValue : (userInput.trim().length !== 0 ? "" : userInput)}
                    >{children}</Input>
                    {
                        showSuggestions && userInput && (
                            filteredSuggestions.length ? (
                                <ul className={`suggestions`} ref={this.suggestionsRef}>
                                    {filteredSuggestions.map((suggestion, index) => {
                                        return (
                                            <li
                                                className={classnames({ active: index === activeSuggestion })}
                                                key={index}
                                                onClick={this.onClick(suggestion)}>
                                                {this.props.renderSuggestion(suggestion)}
                                            </li>
                                        )
                                    })}
                                </ul>
                            ) : (
                                <div className="no-suggestions">
                                    <em><FormattedMessage id="autocomplete.no_suggestions" /></em>
                                </div>
                            )
                        )
                    }
                    {
                        touched && error && (
                            <div className="error">{error}</div>
                        )
                    }
                </div>
            </FormGroup>
        )
    }
}

AutocompleteTextField.propTypes = {
    size: sizeShape,
    id: PropTypes.any.isRequired,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
    getSuggestions : PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    customChange: PropTypes.func.isRequired
}

AutocompleteTextField.defaultProps = {
    size: 'md'
}

export default defaultId(AutocompleteTextField)
