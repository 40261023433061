import {
    getTicketsConsumptionDuringAllPeriods as getTicketsConsumptionDuringAllPeriodsApi,
    exportForfait as exportForfaitApi
} from './api'
import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'FORFAITS/'

export const GET_FORFAITS = `${prefix}GET_FORFAITS`

export const START_EXPORT_FORFAIT = `${prefix}START_EXPORT_FORFAIT`
export const FINISH_EXPORT_FORFAIT = `${prefix}FINISH_EXPORT_FORFAIT`

export const GET_FORFAIT_TICKETS = `${prefix}GET_FORFAIT_TICKETS`

export const getListForfaits = () => middle(GET_FORFAITS, {
    api: '/api',
    url: '/forfaits',
    method: 'GET'
})

export const getTicketsConsumptionDuringAllPeriods = (forfaitId) => (dispatch) => {
    return getTicketsConsumptionDuringAllPeriodsApi(forfaitId)
        .then((res) => {
            dispatch({
                type: GET_FORFAIT_TICKETS,
                payload: res
            })
            return res
        })
}

export const exportForfait = (forfaitId) => (dispatch) => {
    dispatch({
        type: START_EXPORT_FORFAIT
    })
    return exportForfaitApi(forfaitId)
        .then((res) => {
            dispatch({
                type: FINISH_EXPORT_FORFAIT
            })
            return res
        })
        .catch((e) => {
            console.error(e)
            dispatch({
                type: FINISH_EXPORT_FORFAIT
            })
            throw e
        })
}

