import { combineReducers } from 'redux'
import { GET_GESTE_SERVICES } from './gesteActionsTypes'

const initialState = {
	listeServices: []
}

const gestes = (state = initialState.listeServices, action) => {
	switch (action.type) {
		case GET_GESTE_SERVICES:
			return action.listeServices
		default:
			return state
	}
}

export const gesteReducer = combineReducers({
	gestes
})
