import PropTypes from 'prop-types'

/**
 * La structure d'un message internationalisé.
 */
const messageShape = PropTypes.shape({
    /**
     * L'id du message à afficher (`ids` a la priorité)
     */
    id: PropTypes.string,
    /**
     * Un tableau d'ids de messages possibles à afficher, par ordre de préférence.
     *
     * Un seul message est affiché. A la priorité sur `id`.
     */
    ids: PropTypes.arrayOf(PropTypes.string),
    /**
     * Le message à afficher si aucune traduction n'a été trouvée (sinon c'est `id`)
     */
    defaultMessage: PropTypes.string,
    /**
     * Un objet dont les clés représentent les valeurs à injecter dans le message à traduire.
     *
     * Ex: `Hello {name}` + { name: 'world' } => `Hello world`
     */
    values: PropTypes.object,
    description: PropTypes.string
})

export default messageShape
