const appConst = {
    mediaQueries: {
        mobile: {
            maxWidth: 991
        }
    },
    requests: {
        inactive_interval: 5 * 60 * 1000,
        pageSize: 10,
        sort:  {
            open_date: 1,
            date_intervention: 2
        }
    },
    selector: {
        all_value: ''
    }
}

export default appConst
