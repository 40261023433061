import { withPropsOnChange } from 'recompose'

const generateDefaultId = (formName, fieldName) => {
    return `${formName}_${fieldName}`
}
/**
 * Enhancer permet de générer un id par défaut (si le prop id n'existe pas) pour les composant boostrap
 */
export const defaultId = withPropsOnChange(
    ['id', 'meta.form', 'input.name'],
    ({
        id,
        meta: {
            form
        },
        input: {
            name
        }
    }) => ({
        id: id || generateDefaultId(form, name)
    })
)