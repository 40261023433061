import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'CERTIFICATES/'

export const GET_CERTIFICATES = `${prefix}GET_CERTIFICATES`

export const getCertificates = () => middle(GET_CERTIFICATES, {
    api: '/api',
    url: '/certificates',
    method: 'GET'
})
