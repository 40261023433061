import { createSelector } from 'reselect'
import { getLabel } from 'data4-commons/label/utils'
import selectedLocaleSelector from '../../i18n/selectedLocaleSelector'

export const _filesSelector = state => state.app.files

export const filesSelector = state => _filesSelector(state).value

export const fileOptionsSelector = createSelector([
    filesSelector,
    selectedLocaleSelector
], (files, locale) => {
    return (files || []).map((file) => ({
        value: file.idFile,
        label: getLabel(file, locale)
    }))
})
