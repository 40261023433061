import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { pure } from 'recompose'

const EmptySpace = ({
	label,
	legend,
	iconName,
    children
}) => {
	return (
		<div className="empty-space">
			<div className="empty-space-box">
				{
					iconName && (
						<Icon name={iconName} />
					)
				}
				{
					label && <div className="label">{label}</div>
				}
				{
					legend && <div className="legend">{legend}</div>
				}
                {children}
			</div>
		</div>
	)
}

EmptySpace.propTypes = {
	label: PropTypes.node,
	legend: PropTypes.node,
	iconName: PropTypes.string,
    children: PropTypes.node
}

export default pure(EmptySpace)
