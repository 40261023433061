import {  _configurationSelector } from './selectors'
import cache from './cache'
import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'CONFIGURATION/'

export const GET_CONFIGURATION = `${prefix}GET`

export const getConfiguration = () => middle(GET_CONFIGURATION, {
    api: '/api',
    url: '/configuration',
    method: 'GET',
    cache,
    selector: _configurationSelector
})
