import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormGroup, Input } from 'reactstrap'
import { defaultId } from './defaultId'
import FormLabel from '../FormLabel'
import { metaShape, inputShape } from './shapes'

const TextArea = ({
    children,
    id,
    label,
    mandatory,
    meta: {
        touched,
        error
    },
    input,
    ...props
}) => {
    return (
        <FormGroup className={classnames('form-text-area', { 'form-error': error && touched })}>
            {
                label && (
                    <FormLabel
                        label={label}
                        mandatory={mandatory}
                    />
                )
            }
            <div className="group-form-control">
                <Input
                    id={id}
                    {...input}
                    {...props}
                    type="textarea"
                />
                {
                    touched && error && (
                        <div className="error">{error}</div>
                    )
                }
            </div>
        </FormGroup>
    )
}

TextArea.propTypes = {
    id: PropTypes.any.isRequired,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
}

export default defaultId(TextArea)

