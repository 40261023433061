import { SHORT_DATE, SHORT_DATE2, SHORT_TIME } from '../i18n/formats/dateFormat'
import { plus } from 'data4-commons/utils/dateUtils'
import moment from 'moment-timezone'

/**
 * Méthode permettant d'ajuster une date à la valeur valide la plus proche.
 * @param startDate la date choisie par l'utilisateur
 * @param now
 */
export const closestValidDate = (startDate, now) => {
    const validDate = new Date(Math.max(startDate, now))
    return new Date(validDate.getFullYear(), validDate.getMonth(), validDate.getDate(), validDate.getHours(), Math.floor(validDate.getMinutes() / 30) * 30)
}

/**
 * Méthode permettant d'ajuster une date à la valeur valide la plus proche (30 minutes après) compte tenu d'une
 * certaine date de début.
 * @param startDate la date de début choisie par l'utilisateur
 * @param endDate   la date de fin choisie par l'utilisateur
 * @param now
 * @returns {*}
 */
export const closestValidEndDate = (startDate, endDate, now) => {
    const closestValidStartDate = closestValidDate(startDate, now);
    const adjustedEndDate = closestValidDate(endDate, now);
    if (adjustedEndDate > closestValidStartDate) {
        return adjustedEndDate;
    }
    return plus(closestValidStartDate, 30, 'minutes')
}

/**
 * Méthode permettant de générer une date valide la plus proche.
 * @returns {*}
 */
export const getClosestValidDateFormNow = () => {
    const now = new Date()
    return closestValidDate(now, now)
}

/**
 * Récupérer le format de temps en fonction du décalage entre la date
 * d'ouverture de demande et celle d'actuelle
 * @param openDate date d'ouverture de demande
 * @param now      date actuelle
 */
export const getTimeFormatSinceLastOpened = (openDate, now) => {
    if (openDate.getFullYear() !== now.getFullYear()) {
        return SHORT_DATE
    }
    if (openDate.getMonth() === now.getMonth()
        && openDate.getDate() === now.getDate()) {
        return SHORT_TIME
    }
    return SHORT_DATE2
}

/**
 * Méthode permettant de convertir une date dans le fuseau horaire spécifié
 * @param date la date
 * @param timeZone le fuseau horaire
 * @returns {Date}
 */
export const convertToTimeZone = (date, timeZone) => {
    return new Date(moment.tz(date, timeZone).format('YYYY/MM/DD HH:mm:ss'))
}

/**
 * Méthode permettant de convertir une date en un texte dans le fuseau horaire UTC
 * @param date la date
 * @param timeZone le fuseau horaire de la date
 * @returns {string}
 */
export const convertToUTCString = (date, timeZone) => {
    return moment(date).tz(timeZone, true).toISOString()
}

/**
 * Méthode permettant de récupérer le label du fuseau horaire, le résultat dépend de la date si le fuseau horaire est éligible au décalage horaire
 * @param timeZone le fuseau horaire
 * @param date la date, facultatif
 * @returns {string}
 */
export const getTimeZoneLabel = (timeZone, date) => {
    if (moment(date).isValid()) {
        return `UTC${moment.tz(date, timeZone).format('Z')}`
    }
    return `UTC${moment.tz(new Date(), timeZone).format('Z')}`
}
