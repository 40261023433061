import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormGroup, Input } from 'reactstrap'
import { defaultId } from './defaultId'
import FormLabel from '../FormLabel'
import { inputShape, metaShape, sizeShape } from './shapes'
import Icon from '../Icon'

const PasswordTextField = ({
    size,
    children,
    id,
    label,
    mandatory,
    meta: {
        touched,
        error
    },
    input,
    ...props
}) => {
    const [hiddenPassword, setHiddenPassword] = useState(true)

    const toggleHiddenPassword = () => {
        setHiddenPassword(!hiddenPassword)
    }

    return (
        <FormGroup
            className={classnames('form-input', {
                [`form-${size}`]: size,
                'form-error': error && touched
            })}
        >
            {
                label && (
                    <FormLabel
                        label={label}
                        mandatory={mandatory}
                    />
                )
            }
            <div className="group-form-control">
                <div className="login-form-password">
                    <Input
                        id={id}
                        {...input}
                        {...props}
                        type={hiddenPassword ? 'password' : 'text'}
                    >
                        {children}
                    </Input>
                    <button
                        className="hide-password-btn"
                        onClick={toggleHiddenPassword}
                        type="button"
                    >
                        <Icon name={hiddenPassword ? 'visibility' : 'visibility-off'} />
                    </button>
                </div>
                {
                    touched && error && (
                        <div className="error">{error}</div>
                    )
                }
            </div>
        </FormGroup>
    )
}

PasswordTextField.propTypes = {
    size: sizeShape,
    id: PropTypes.any.isRequired,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired
}

PasswordTextField.defaultProps = {
    size: 'md'
}

export default defaultId(PasswordTextField)
