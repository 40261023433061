import { updateDeliveryInstructions as updateDeliveryInstructionsApi } from './api'

const prefix = 'DELIVERY_INSTRUCTIONS/'

export const UPDATE_DELIVERY_INSTRUCTIONS = `${prefix}UPDATE_DELIVERY_INSTRUCTIONS`

export const updateDeliveryInstructions = (values, siteId) => (dispatch) => {
    return updateDeliveryInstructionsApi(values, siteId)
        .then(({ deliveryInformation }) => {
            dispatch({
                type: UPDATE_DELIVERY_INSTRUCTIONS,
                payload: deliveryInformation
            })
            return deliveryInformation
        })
}
