const _rapportsParZone = (state) => state.app.kpiEnv.reportsZone.rapportsParZone
const _zoneConfiguration = (state) => state.app.kpiEnv.reportsZone.zoneConfiguration

export const rapportsParZoneLoadingSelector = state => _rapportsParZone(state).loading
export const rapportsParZoneErrorSelector = state => _rapportsParZone(state).error
export const rapportsParZoneSelector = state => _rapportsParZone(state).value

export const zoneConfigurationLoadingSelector = state => _zoneConfiguration(state).loading
export const zoneConfigurationErrorSelector = state => _zoneConfiguration(state).error
export const zoneConfigurationSelector = state => _zoneConfiguration(state).value
