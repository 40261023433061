import React, { PureComponent } from 'react'
// lib
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { SubmissionError, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose, lifecycle, branch, renderNothing } from 'recompose'
import { replace } from 'connected-react-router'
// components
import { Container } from 'reactstrap'
// internal components
import Headline from '../../../../components/Headline'
import Paper from '../../../../components/Paper'
import ChangePasswordForm, { validate } from '../ChangePasswordForm'
// api
import {
    resetPassword,
    checkRightsToResetPassword,
    initPassword,
    checkRightsToInitPassword
} from '../api'
// utils
import handleError from 'data4-commons/form/handleError'
import { validatePassword } from '../utils'
// selectors
import { passwordMinLengthSelector } from '../../../../properties/selectors'
// utils
import { toasterSuccess } from '../../../../toaster/utils'

const ChangePasswordReduxForm = reduxForm({
    validate
})(ChangePasswordForm)

class ResetPasswordPage extends PureComponent {
    handleResetPassword = ({
        newPassword,
        confirmation
    }) => {
        const errors = validatePassword(newPassword, confirmation, this.props.passwordMinLength)
        if (errors) {
            if (errors.password) {
                throw new SubmissionError({
                    newPassword: errors.password
                })
            }
            if (errors.confirmation) {
                throw new SubmissionError(errors)
            }
        }
        const input = {
            newPassword,
            email: this.props.match.params.email,
            token: this.props.match.params.token
        }
        const passwordPromise = this.props.newPassword ? initPassword(input) : resetPassword(input)

        return passwordPromise.then(() => {
            toasterSuccess(this.props.newPassword ? 'login.set_new_password.success' : 'login.reset_password.success')
            this.props.replace('/login')
        }, handleError)
    }

    render() {
        const {
            passwordMinLength
        } = this.props
        return (
            <Container>
                <Paper className="paper-form paper-reset-password">
                    <Headline
                        label={<FormattedMessage id={this.props.newPassword ? 'login.set_new_password.title' : 'login.reset_password.title'} />}
                        type="secondary"
                    />
                    <ChangePasswordReduxForm
                        form="ResetPasswordForm"
                        onSubmit={this.handleResetPassword}
                        passwordMinLength={passwordMinLength}
                    />
                </Paper>
            </Container>
        )
    }
}

ResetPasswordPage.propTypes = {
    newPassword: PropTypes.bool
}

const mapStateToProps = (state, props) => ({
    passwordMinLength: passwordMinLengthSelector(state, props)
})

const mappedActions = {
    replace
}

export default compose(
    connect(mapStateToProps, mappedActions),
    lifecycle({
        componentDidMount() {
            const eligiblePromise = this.props.newPassword ? checkRightsToInitPassword(this.props.match.params.email, this.props.match.params.token)
                : checkRightsToResetPassword(this.props.match.params.email, this.props.match.params.token)

            eligiblePromise.then((eligible) => {
                this.setState({
                    eligible
                })
                if (!eligible) {
                    this.props.replace('/login')
                }
            }, () => this.props.replace('/login'))
        }
    }),
    branch(
        (props) => !props.eligible,
        renderNothing
    )
)(ResetPasswordPage)
