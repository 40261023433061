import { combineReducers} from 'redux'
import { ACTIVATE_2FA, DISABLE_2FA } from './actions'
import { LOGIN } from '../user/userActions'

const userActive = (state = false, action) => {
    switch (action.type) {
        case ACTIVATE_2FA:
            return true
        case DISABLE_2FA:
            return false
        case LOGIN:
            return action.payload.utilisateur.actif2FA
        default:
            return state
    }
}

export default combineReducers({
    userActive
})

