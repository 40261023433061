const requests = 'demandes'
const clients = 'clients'
const draftRequests = 'draft-requests'
const requestsAndDraft = 'demandes-and-draft'

export const SEARCH_TARGETS = [
    requestsAndDraft,
    requests,
    clients,
    draftRequests
]

export const SEARCH_TARGET = {
    requests,
    clients,
    draftRequests,
    requestsAndDraft
}

export const DEFAULT_SEARCH_TARGET = requests
export const DEFAULT_INTERN_SEARCH_TARGET = requestsAndDraft

export const SEARCH_TARGET_FIELD_NAME = 'target'

const MANAGEMENT = 'MANAGEMENT'
const REQUESTS = 'REQUESTS'
const SERVICES = 'SERVICES'
const SUSTAINABILITY = 'SUSTAINABILITY'
const SUPPORT = 'SUPPORT'


export const USE_IN_OTHER = 'other'
export const USE_IN_BUTTON = 'btn'
export const USE_IN_MENU = 'menu'
export const USE_IN_TITLE = 'title'

export const SECTION = {
    MANAGEMENT,
    REQUESTS,
    SERVICES,
    SUSTAINABILITY,
    SUPPORT
}

export const SECTION_VALUES = [
    SECTION.MANAGEMENT,
    SECTION.REQUESTS,
    SECTION.SERVICES,
    SECTION.SUSTAINABILITY,
    SECTION.SUPPORT
]
