export const KPI_REPORT_TYPE = {
    client: 'client',
    datacenter: 'datacenter',
    rapport_par_zone: 'rapport-par-zone'
}
export const RAPPORT_PERSONNALISE = {
    ALIAS: 'alias',
    BUILDINGS: 'buildings'
}

export const CREATE_CLIENT_REPORT_FORM = 'CreateClientReportForm'
export const UPDATE_CLIENT_REPORT_FORM = 'UpdateClientReportForm'

export const PARAM_REPORT_NAME = 'reportName'

export const TYPE_CALCUL = {
    MARKET_BASED: 1,
    LOCATION_BASED: 2
}

export const AMORTISSEMENT = {
    EXCLUDING_FAB_AND_FDV: 1,
    DEPRECIATING_FAB_AND_FDV_OVER_20_YEARS: 2,
    INCLUDING_FAB_ONLY: 3,
    INCLUDING_FDV_ONLY: 4
}

export const AMORTISSEMENT_VALUES = [
    AMORTISSEMENT.EXCLUDING_FAB_AND_FDV,
    AMORTISSEMENT.DEPRECIATING_FAB_AND_FDV_OVER_20_YEARS,
    AMORTISSEMENT.INCLUDING_FAB_ONLY,
    AMORTISSEMENT.INCLUDING_FDV_ONLY
]
