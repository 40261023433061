import { combineReducers } from 'redux'
import {
    GET_DEMANDES,
    LOAD_MORE_DEMANDES,
    LOAD_MORE_DEMANDES_ERROR,
    LOAD_MORE_DEMANDES_LOADING,
    UPDATE_DEMANDE, UPDATE_DEMANDE_STATUT
} from './demandeActionsTypes'
import appConst from '../../app/appConst'
import { REQUEST_STATUS } from './const'

const createRequestsReducerByStatus = (status) => {
    const value = (state = [], action) => {
        switch (action.type) {
            case GET_DEMANDES:
                if (action.meta.status === status) {
                    return action.payload.value
                }
                return state
            case LOAD_MORE_DEMANDES:
                if (action.meta.status === status) {
                    return state.concat(action.payload.value)
                }
                return state
            case UPDATE_DEMANDE:
                if (action.payload.statut === status || status === REQUEST_STATUS.ALL_STATUS) {
                    return state.map((request) => {
                        if (request.idDemande === action.payload.idDemande) {
                            return action.payload
                        }
                        return request
                    })
                }
                return state
            case UPDATE_DEMANDE_STATUT: {
                // nouveau statut
                if (action.payload.statut === status || status === REQUEST_STATUS.ALL_STATUS) {
                    return [
                        action.payload,
                        ...state.filter(el => el.idDemande !== action.payload.idDemande)
                    ]
                }
                // ancien statut
                if (action.meta.status === status) {
                    return state.filter(el => el.idDemande !== action.payload.idDemande)
                }
                return state
            }
            default:
                return state
        }
    }

    const next = (state = [], action) => {
        switch (action.type) {
            case GET_DEMANDES:
            case LOAD_MORE_DEMANDES:
                if (action.meta.status === status) {
                    return action.payload.next || state
                }
                return state
            default:
                return state
        }
    }

    const hasMore = (state = true, action) => {
        switch (action.type) {
            case GET_DEMANDES:
            case LOAD_MORE_DEMANDES:
                if (action.meta.status === status) {
                    return action.payload.value.length === appConst.requests.pageSize
                }
                return state
            default:
                return state
        }
    }

    const loading = (state = false, action) => {
        switch (action.type) {
            case LOAD_MORE_DEMANDES_LOADING:
                if (action.meta.status === status) {
                    return true
                }
                return state
            case LOAD_MORE_DEMANDES_ERROR:
            case LOAD_MORE_DEMANDES:
            case GET_DEMANDES:
                if (action.meta.status === status) {
                    return false
                }
                return state
            default:
                return state
        }
    }

    const error = (state = false, action) => {
        switch (action.type) {
            case LOAD_MORE_DEMANDES_ERROR:
                if (action.meta.status === status) {
                    return true
                }
                return state
            case LOAD_MORE_DEMANDES_LOADING:
            case LOAD_MORE_DEMANDES:
            case GET_DEMANDES:
                if (action.meta.status === status) {
                    return false
                }
                return state
            default:
                return state
        }
    }

    const nbRequests = (state = 0, action) => {
        switch (action.type) {
            case GET_DEMANDES:
                if (action.meta.status === status) {
                    return action.payload.nb
                }
                return state
            default:
                return state
        }
    }

    return combineReducers({
        value,
        next,
        hasMore,
        loading,
        error,
        nbRequests
    })
}

export default createRequestsReducerByStatus
