import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'


const Paper = ({
	children,
	className,
    shadowType,
    style
}) => {
	return (
		<div
            className={classnames('paper', className, {
                'paper-shadow': shadowType === 'paper',
                'dashboard-shadow': shadowType === 'dashboard'
            })}
            style={style}
        >
			{children}
		</div>
	)
}

Paper.defaultProps = {
    shadowType: 'paper'
}

Paper.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
    /**
     * Indiquer si le type de l'effet de box-shadow.
     * Par défaut c'est "paper"
     */
	shadowType: PropTypes.oneOf(['dashboard', 'paper']),
    /**
     * inline style sur la racine
     */
    style: PropTypes.object
}

export default Paper
