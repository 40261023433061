import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import DropDownSelect from './DropDownSelect'
import { components } from 'react-select'
import { metaShape, inputShape, sizeShape } from '../shapes'

const CustomValueContainer = ({
    children,
    ...props
}) => {
    return (
        <components.ValueContainer
            {...props}
            className="value-container"
        >
            {
                !!props.selectProps.renderMultiValue ? (
                    <Fragment>
                        {props.selectProps.renderMultiValue(children[0], props.getValue())}
                        {children[1]}
                    </Fragment>
                ) : children
            }
        </components.ValueContainer>
    )
}

const styles = {
    valueContainer: base => ({
        ...base,
        flexWrap: 'nowrap'
    }),
    multiValue: base => ({
        ...base,
        backgroundColor: 'transparent'
    }),
    multiValueRemove: base => ({
        ...base,
        display: 'none'
    })
}

class DropDownMultiSelect extends PureComponent {

    render() {
        return (
            <DropDownSelect
                {...this.props}
                styles={styles}
                hideSelectedOptions={false}
                isClearable={false}
                closeMenuOnSelect={false}
                isMulti
                changeValueOnBlur
                components={{
                    ValueContainer: CustomValueContainer
                }}
            />
        )
    }
}

DropDownMultiSelect.propTypes = {
    size: sizeShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired
        })
    ),
    disabled: PropTypes.bool,
    /**
     * Fontion personnalisée pour le rendu de la vue des options sélétionnées.
     * (selectedChildren, selectedOptions) => {
     *     ...
     * }
     */
    renderMultiValue: PropTypes.func
}

DropDownMultiSelect.defaultProps = {
    size: 'sm'
}

export default DropDownMultiSelect
