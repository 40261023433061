import React from 'react'
// lib
import { FormattedMessage } from 'react-intl'
import { Field } from 'redux-form'
// internal components
import { TextField } from '../../../../components/reduxForm/index'
// utils
import { validateMandatoryFields, validateSingleEmailFields, combineValidators } from 'data4-commons/form/utils'

export const validate = combineValidators([validateMandatoryFields(['email']), validateSingleEmailFields(['email'])])

const ForgotPasswordField = () => {
    return (
        <div className="form-standard">
            <p className="contenu">
                <FormattedMessage id="login.forgot_password.dialog.body" />
            </p>
            <Field
                name="email"
                component={TextField}
                label={<FormattedMessage id="login.forgot_password.dialog.email.label" />}
            />
        </div>
    )
}

export default ForgotPasswordField