import { combineReducers } from 'redux'
import { GET_SITES, GET_BUILDINGS_BY_SITE, GET_DCIM_SITES } from './localisationActionsTypes'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const sites = createReducer(GET_SITES)

const dcim = createReducer(GET_DCIM_SITES)

const buildings = (state = {}, action) => {
    switch (action.type) {
        case GET_BUILDINGS_BY_SITE:
            return {
                ...state,
                [action.meta.siteId]: action.payload
            }
        default:
            return state
    }
}

export const localisationReducer = combineReducers({
	sites,
    buildings,
    dcim
})
