import { combineReducers } from 'redux'
import {
    GET_KPI_REPORTS_DATACENTER_CONFIG,
    GET_KPI_REPORTS_DATACENTER_CONFIG_ERROR,
    GET_KPI_REPORTS_DATACENTER_CONFIG_LOADING
} from '../actions'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_KPI_REPORTS_DATACENTER_CONFIG_LOADING:
            return true
        case GET_KPI_REPORTS_DATACENTER_CONFIG:
        case GET_KPI_REPORTS_DATACENTER_CONFIG_ERROR:
            return false
        default:
            return state
    }
}
const error = (state = false, action) => {
    switch (action.type) {
        case GET_KPI_REPORTS_DATACENTER_CONFIG:
            return false
        case GET_KPI_REPORTS_DATACENTER_CONFIG_ERROR:
            return true
        default:
            return state
    }
}
const value = (state = null, action) => {
    switch (action.type) {
        case GET_KPI_REPORTS_DATACENTER_CONFIG:
            return action.payload
        default:
            return state
    }
}

export const datacenterConfiguration = combineReducers({
    loading,
    error,
    value
})
