import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Field } from 'redux-form'
import { pure } from 'recompose'
import { FormGroup } from 'reactstrap'
import Checkbox from './Checkbox'
import FormLabel from '../FormLabel'
import { metaShape, alignShape } from './shapes'

const CheckboxGroup = ({
    align,
    label,
    mandatory,
    fields,
    meta: {
        form,
        error
    }
}) => {
    return (
        <FormGroup className="form-checkbox">
            {
                label && (
                    <FormLabel
                        label={label}
                        mandatory={mandatory}
                    />
                )
            }
            <div className={classnames('form-checkbox', { 'inline': align === 'row' })}>
                {
                    fields.map((field, index, fields2) => {
                        const option = fields2.get(index)
                        const key = `${form}-${field}-${option.value}`
                        return (
                            <Field
                                key={key}
                                id={key}
                                name={field}
                                component={Checkbox}
                                label={option.label}
                                checked={!!option.checked}
                                normalize={(value) => ({
                                    ...option,
                                    checked: value
                                })}
                            />
                        )
                    })
                }
            </div>
            {
                error && (
                    <div className="error">{error}</div>
                )
            }
        </FormGroup>
    )
}

CheckboxGroup.propTypes = {
    /**
     * Alignement des radios, soit vertical ("col"), soit horizontal ("row")
     */
    align: alignShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    fields: PropTypes.object.isRequired,
    meta: metaShape.isRequired
}

/**
 * Composant d'un ensemble de {@link Checkbox}s connecté à FieldArray redux-form.
 * La valeur passée à {@code fields} doit contenir les attributs suivants:
 * - label: PropTypes.node
 * - value: PropTypes.any
 */
export default pure(CheckboxGroup)