import {
    updatePreferenceRequestFilters as updatePreferenceRequestFiltersApi
} from './api'

const prefix = 'DISPALY_PREFERENCE/'

export const UPDATE_REQUEST_FILTERS_PREFERENCE = `${prefix}UPDATE_REQUEST_FILTERS_PREFERENCE`

export const updatePreferenceRequestFilters = (values) => (dispatch) => {
    return updatePreferenceRequestFiltersApi(values)
        .then((preference) => {
            dispatch({
                type: UPDATE_REQUEST_FILTERS_PREFERENCE,
                payload: preference
            })
            return preference
        })
}
