const URGENT = 1
const PLANNED = 2

export const EMERGENCY = {
    URGENT,
    PLANNED
}

export const EMERGENCY_VALUES = [
    URGENT,
    PLANNED
]

export const CFA_FORM_SECTION = 'cfa'
