import { combineReducers } from 'redux'
import {
    GET_LIST_DOCUMENTS,
    GET_LIST_DOCUMENTS_LOADING,
    GET_LIST_DOCUMENTS_ERROR,
    MARK_FOLDER_AS_READ
} from './documentActionsTypes'

const value = (state = {}, action) => {
    switch (action.type) {
        case GET_LIST_DOCUMENTS:
            return {
                ...state,
                [action.meta.folderId]: action.payload
            }
        case MARK_FOLDER_AS_READ:
            return {
                ...state,
                [action.payload]: state[action.payload].map((document) => ({
                    ...document,
                    nouveau: false
                }))
            }
        default:
            return state
    }
}

const loading = (state = {}, action) => {
    switch (action.type) {
        case GET_LIST_DOCUMENTS_LOADING:
            return {
                ...state,
                [action.meta.folderId]: true
            }
        case GET_LIST_DOCUMENTS:
        case GET_LIST_DOCUMENTS_ERROR:
            return {
                ...state,
                [action.meta.folderId]: false
            }
        default:
            return state
    }
}

const error = (state = {}, action) => {
    switch (action.type) {
        case GET_LIST_DOCUMENTS_ERROR:
            return {
                ...state,
                [action.meta.folderId]: true
            }
        case GET_LIST_DOCUMENTS:
            return {
                ...state,
                [action.meta.folderId]: false
            }
        default:
            return state
    }
}

export default combineReducers({
    value,
    error,
    loading
})
