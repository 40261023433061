import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const getCGUToValidate = () => fetchFactory('/cgu/me', {}, '/api')

export const acceptCGU = () => fetchFactory('/cgu/accepterCgu', {
    method: 'POST'
}, '/api')

export const refuseCGU = () => fetchFactory('/cgu/refuserCgu', {
    method: 'POST'
}, '/api')