import { MENU_OPEN } from './navigationActions'

const open = (state = false, action) => {
	switch (action.type) {
		case MENU_OPEN:
			return action.payload.menuOpen
		default:
			return state
	}
}

export default open
