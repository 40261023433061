import {
    updateAccessPreference as updateAccessPreferenceApi,
    updateEmailsPreference as updateEmailsPreferenceApi
} from './api'

const prefix = 'CLIENT_PREFERENCE/'

export const UPDATE_ACCESS_PREFERENCE = `${prefix}UPDATE_ACCESS_PREFERENCE`
export const UPDATE_EMAILS_PREFERENCE = `${prefix}UPDATE_EMAILS_PREFERENCE`

export const updateAccessPreference = (values) => (dispatch) => {
    return updateAccessPreferenceApi(values)
        .then((accessPreference) => {
            dispatch({
                type: UPDATE_ACCESS_PREFERENCE,
                payload: accessPreference
            })
            return accessPreference
        })
}

export const updateEmailsPreference = (values) => (dispatch) => {
    return updateEmailsPreferenceApi(values)
        .then((emailsPreference) => {
            dispatch({
                type: UPDATE_EMAILS_PREFERENCE,
                payload: emailsPreference
            })
            return emailsPreference
        })
}