import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormGroup, CustomInput } from 'reactstrap'
import FormLabel from '../FormLabel'
import { inputShape, metaShape } from './shapes'
import { defaultId } from './defaultId'

const Toggle = ({
    id,
    label,
    meta: {
        touched,
        error
    },
    input: {
        value,
        onBlur,
        ...input
    },
    ...props
}) => {
	return (
        <FormGroup className={classnames('form-switch', { 'form-error': error && touched })}>
            <div className="group-form-control">
                 <div className="form-control">
                     <CustomInput
                         {...props}
                         {...input}
                         id={id}
                         type="switch"
                         checked={!!value}
                     />
                     {
                         label && <FormLabel label={label} />
                     }
                 </div>
                 {
                     touched && error && (
                         <div className="error">{error}</div>
                     )
                 }
            </div>
        </FormGroup>
	)
}

Toggle.propTypes = {
	input: inputShape.isRequired,
	meta: metaShape.isRequired,
	label: PropTypes.node
}

export default defaultId(Toggle)
