import PropTypes from 'prop-types'
import messageShape from '../../i18n/messageShape'

const stringOrMessage = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    messageShape
])

export default stringOrMessage
