import PropTypes from 'prop-types'
import { withPropsOnChange, compose, setPropTypes } from 'recompose'

/**
 * Enhancer qui permet de générer un object <value, option>
 * depuis le prop 'options' pour simplifier la recherche
 * de l'option sélectionnée.
 */
export default compose(
    setPropTypes({
       options: PropTypes.arrayOf(
           PropTypes.shape({
               value: PropTypes.any.isRequired
           })
       ).isRequired
    }),
    withPropsOnChange(
        ['options'],
        (props) => {
            const optionsMap = props.options.reduce((map, option) => {
                return {
                    ...map,
                    [option.value]: option
                }
            }, {})
            return {
                optionsMap
            }
        }
    )
)