import React, { Component } from 'react'
// lib
import { FormattedMessage } from 'react-intl'
// components
import { Container, Row, Col } from 'reactstrap'
// internal components
import Paper from '../../../components/Paper'
import EmptySpace from '../../../components/EmptySpace'
import Link from '../../../components/Link'
import SiteMap from './SiteMap'
// api
import { getWelcomInformationBySite } from './api'

class WelcomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            configuration: null,
            error: false
        }
    }
    
    componentDidMount() {
        getWelcomInformationBySite(this.props.match.params.siteId)
            .then((configuration) => {
                this.setState({
                    configuration
                })
            }, () => {
                this.setState({
                    error: true
                })
            })
    }
    
    render() {
        const {
            configuration,
            error
        } = this.state
        return (
            <Container>
                <Paper className="paper-form">
                    {
                        error ? (
                            <EmptySpace
                                iconName="navigation-perte-connexion"
                                label={<FormattedMessage id="global.unknown_error" />}
                                legend={<FormattedMessage id="login.welcome.error.legend" />}
                            />
                        ) : (
                            configuration && (
                                <Row>
                                    <Col lg={8} md={12}>
                                        <div dangerouslySetInnerHTML={{ __html: configuration.messageInformation }} />
                                    </Col>
                                    <Col lg={4} md={12} className="d-flex flex-column align-items-lg-end align-items-md-center">
                                        <SiteMap
                                            latitude={configuration.planLatitude}
                                            longitude={configuration.planLongitude}
                                        />
                                        <Link
                                            label={<FormattedMessage id="login.welcome.see_weather_report" />}
                                            action={() => window.open(configuration.lienMeteo, '_blank')}
                                            icon="navigation-fleche-droite"
                                        />
                                    </Col>
                                </Row>
                            )
                        )
                    }
                </Paper>
            </Container>
        )
    }
}

export default WelcomePage