const STANDARD = 2
const URGENT = 1
const PROGRAMME = 3
const RECURRENT = 4

export const CRITICITE = {
    STANDARD,
    URGENT,
    PROGRAMME,
    RECURRENT
}

export const CRITICITES = [
    STANDARD,
    URGENT,
    PROGRAMME
]

const DAILY = 'DAILY'
const WEEKLY = 'WEEKLY'
const MONTHLY = 'MONTHLY'
const MONTHLY_RELATIVE = 'MONTHLY_RELATIVE'

export const RECURRENT_FREQUENCY_TYPES = [
    DAILY,
    WEEKLY,
    MONTHLY
]

export const RECURRENT_FREQUENCY_TYPE = {
    DAILY,
    WEEKLY,
    MONTHLY,
    MONTHLY_RELATIVE
}

const DAY_OF_MONTH = 'DAY_OF_MONTH'
const DAY_OF_WEEK = 'DAY_OF_WEEK'

export const MONTHLY_FREQUENCY_TYPES = [
    DAY_OF_MONTH,
    DAY_OF_WEEK
]

export const MONTHLY_FREQUENCY_TYPE = {
    DAY_OF_MONTH,
    DAY_OF_WEEK
}
