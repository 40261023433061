import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Input, Label } from 'reactstrap'
import { inputShape } from './reduxForm/shapes'

const Radio = ({
    label,
    input: {
        value,
        ...input
    },
    meta,
    ...others
}) => {
    return (
        <div className="pretty p-default p-round p-smooth">
            <Input
                {...input}
                checked={value === others.value}
                {...others}
                type="radio"
            />
            {
                label && (
                    <div className="state p-success">
                        <Label className="label">
                           {label}
                        </Label>
                    </div>
                )
            }
        </div>
    )
}

Radio.propTypes = {
    label: PropTypes.node,
    input: inputShape.isRequired,
    value: PropTypes.any.isRequired
}

export default pure(Radio)