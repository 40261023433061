import React from 'react'
import { toast, cssTransition } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

// Dans l'application, on n'a qu'un seul toaster remonté en même temps
const internalToastId = '__toaster__'

const PREFIX = 'Toastify__'

const transition = cssTransition({
    enter: `${PREFIX}slide-enter`,
    exit: `${PREFIX}slide-exit`,
    duration: [500, 0],
    appendPosition: true
})

const defaultOptions = {
    autoClose: 2000,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_CENTER,
    transition
}

export const removeCurrentToaster = () => {
    if (toast.isActive(internalToastId)) {
        toast.dismiss(internalToastId)
    }
}

export const toasterSuccess = (id = 'global.success', options = {}) => {
    const {
        values,
        ...otherOptions
    } = options
    const toaster = {
        ...defaultOptions,
        bodyClassName: 'toast-success',
        ...otherOptions,
        toastId: internalToastId
    }
    removeCurrentToaster()
    toast.success(<FormattedMessage id={id} values={values} />, toaster)
}

export const toasterError = (id = 'global.unknown_error', options = {}) => {
    const {
        values,
        ...otherOptions
    } = options
    const toaster = {
        ...defaultOptions,
        autoClose: false,
        bodyClassName: 'toast-error',
        ...otherOptions,
        toastId: internalToastId
    }
    removeCurrentToaster()
    toast.error(<FormattedMessage id={id} values={values} />, toaster)
}
