import { fetchFactory } from 'data4-commons/entities/fetchEntities'

export const getDraftRequests = (filtres) => fetchFactory('/draft_requests', {
    method: 'POST',
    body: JSON.stringify(filtres)
}, '/api')

export const downloadDraftRequestFile = (draftRequestId, fileIndex) => {
    window.open(`/api/draft_requests/${draftRequestId}/files/${fileIndex}`, '_blank')
}

export const downloadDraftRequestNoteFile = (draftRequestId, idNote, fileIndex) => {
    window.open(`/api/draft_requests/${draftRequestId}/notes/${idNote}/files/${fileIndex}`)
}

export const updateHostingSpaces = (draftRequestId, values) => fetchFactory(`/draft_requests/${draftRequestId}/hosting_spaces`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const updateBadgeType = (draftRequestId, values) => fetchFactory(`/draft_requests/${draftRequestId}/badgeType`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const updateTypeIntervention = (draftRequestId, values) => fetchFactory(`/draft_requests/${draftRequestId}/majTypeIntervention`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const updateDraftRequestStatus = (draftRequestId, values) => fetchFactory(`/draft_requests/${draftRequestId}/status`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const getDraftRequestHistory = (draftRequestId) => fetchFactory(`/draft_requests/${draftRequestId}/history`, {}, '/api')

export const addNote = (draftRequestId, values) => fetchFactory(`/draft_requests/${draftRequestId}/note`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')

export const getDraftRequest = (draftRequestId) => fetchFactory(`/draft_requests/${draftRequestId}`, {}, '/api')

export const changeVisitorStatus = (draftRequestId, visitorId, values) => fetchFactory(`/draft_requests/${draftRequestId}/visitors/${visitorId}/status`, {
    method: 'PUT',
    body: JSON.stringify(values)
}, '/api')
