const STANDARD = 2
const URGENT = 1
const PROGRAMME = 3

export const EMERGENCY = {
    STANDARD,
    URGENT,
    PROGRAMME
}

export const EMERGENCY_VALUES = [
    STANDARD,
    URGENT,
    PROGRAMME
]

export const OTHER_FORM_SECTION = 'autre'
