import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pure } from 'recompose'
import { FormGroup } from 'reactstrap'
import Radio from '../Radio'
import FormTitle from '../FormTitle'
import { metaShape, inputShape, alignShape } from './shapes'

const RadioGroup = ({
    align,
    label,
    options,
    mandatory,
    meta: {
        form,
        touched,
        error
    },
    ...others
}) => {
    const {
        value,
        name
    } = others.input
    return (
        <FormGroup className="form-radio">
            {
                label && (
                    <FormTitle
                        label={label}
                        mandatory={mandatory}
                    />
                )
            }
            <div className={classnames('form-radio', { 'inline': align === 'row' })}>
            {
                options.map((option) => {
                    const key = `${form}-${name}-${option.value}`
                    return (
                        <Radio
                            key={key}
                            id={key}
                            label={option.label}
                            value={option.value}
                            checked={value === option.value}
                            {...others}
                        />
                    )
                })
            }
            </div>
            {
                touched && error && (
                    <div className="error">{error}</div>
                )
            }
        </FormGroup>
    )
}

RadioGroup.propTypes = {
    /**
     * Alignement des radios, soit vertical ("col"), soit horizontal ("row")
     */
    align: alignShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            value: PropTypes.any
        })
    ).isRequired,
    input: inputShape.isRequired,
    meta: metaShape.isRequired
}

export default pure(RadioGroup)