import { createSelector } from 'reselect'
import { requestFiltersPreferenceSelector } from '../../../preferencedisplay/selectors'
import { transformFiltersPreference } from './utils'
import { REQUEST_STATUS_VALUES } from '../../const'

export const filtersInitialValuesSelector = createSelector([
    requestFiltersPreferenceSelector
], transformFiltersPreference)

export const requestsFiltersSelector = state => state.app.demandes.filters
export const requestsFiltersOpenSelector = state => requestsFiltersSelector(state).open
export const requestsFiltersValuesSelector = state => requestsFiltersSelector(state).values
export const requestsFiltersDirtySelector = state => requestsFiltersSelector(state).dirty

const requestsFiltersStatusSelector = createSelector([
    requestsFiltersValuesSelector
], (values) => (!values.statuts || values.statuts.length === 0 ? REQUEST_STATUS_VALUES : values.statuts))

/**
 * Les statuts sélectionnés en ordre : ouvert, en cours, clôturé
 */
export const orderedRequestsFiltersStatusSelector = createSelector(
    requestsFiltersStatusSelector,
    (listStatus) => listStatus.sort()
)
