import middle from 'data4-commons/middlewares/api/middle'
import { _clientsSelector } from './selectors'
import cache from './cache'
import { buildUrlWithParams } from 'data4-commons/entities/fetchEntities'

const RELATED = 'related'

const prefix = 'CLIENTS/'

export const GET_CLIENTS = `${prefix}GET_CLIENTS`
export const GET_CLIENT = `${prefix}GET_CLIENT`

const getClients = (qualifier) => middle(GET_CLIENTS, {
    api: '/api',
    url: buildUrlWithParams('/clients', { qualifier }),
    method: 'GET',
    cache,
    selector: _clientsSelector
})

export const getClientsRelatedToUser = () => getClients(RELATED)

export const getClient = (clientId) => middle(GET_CLIENT, {
    api: '/api',
    url: `/clients/${clientId}`,
    method: 'GET'
})
