import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { googleApi, Cache, getMapOptions } from 'data4-commons/gmap'

const mapHeight = 300
const mapWidth = 300

class SiteMap extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
        this.cache = Cache({
            google: googleApi
        })
    }
    
    componentDidMount() {
        this.cache.google.onLoad(() => {
            this.setState({
                loaded: true
            })
            const {
                latitude,
                longitude
            } = this.props
            this.map = new window.google.maps.Map(
                this.mapElement,
                getMapOptions(latitude, longitude, {
                    zoomControl: true,
                    scaleControl: true
                })
            )
            this.marker = new window.google.maps.Marker({
                position: {
                    lat: latitude,
                    lng: longitude
                },
                map: this.map
            })
        })
    }
    
    render() {
        const {
            loaded
        } = this.state
        return (
            <div
                ref={el => {
                    this.mapElement = el
                }}
                style={{
                    width: mapWidth,
                    height: mapHeight,
                    display: loaded ? 'block' : 'none',
                    marginTop: 20,
                    marginBottom: 20
                }}
            />
        )
    }
}

SiteMap.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number
}

export default SiteMap