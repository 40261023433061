import { buildUrlWithParams, fetchFactory } from 'data4-commons/entities/fetchEntities'

export const getAccess = (clientId, accessId) => fetchFactory(`/access/${clientId}/${accessId}`, {}, '/api')

export const getAccessPage = (clientId, params) => fetchFactory(buildUrlWithParams(`/access/${clientId}/page`, params), {
    method: 'GET'
}, '/api')

export const getRevokedAccess = (clientId, accessId) => fetchFactory(`/access/${clientId}/${accessId}/revoke`, {}, '/api')

export const makeAccessToBeRevoked = (clientId, accessId) => fetchFactory(`/access/${clientId}/${accessId}/revoke`, {
    method: 'PATCH'
}, '/api')

export const makeMultipleAccessToBeRevoked = (clientId, accessesToRevoke) => fetchFactory(`/access/${clientId}/accesses/revoke`, {
    method: 'POST',
    body: JSON.stringify(accessesToRevoke)
}, '/api')

export const exportAccess = (clientId, params) => fetch(buildUrlWithParams(`/api/access/${clientId}/export`, params), {
    headers: {
        Accept: 'application/octet-stream'
    }
})

export const exportRevokedAccess = (clientId, params) => fetch(buildUrlWithParams(`/api/access/${clientId}/export/revoke`, params), {
    headers: {
        Accept: 'application/octet-stream'
    }
})

export const getRevokedAccessPage = (clientId, params) => fetchFactory(buildUrlWithParams(`/access/${clientId}/revoke`, params), {
    method: 'GET'
}, '/api')
