import { combineReducers } from 'redux'
import createReducer from 'data4-commons/middlewares/api/createReducer'
import {
    GET_CGU_PREVIEW,
    GET_CGU_TO_VALIDATE,
    ACCEPT_CGU,
    GET_LAST_ACCEPTED_CGU
} from './actions'

const cgu = (state = null, action) => {
    switch (action.type) {
        case GET_CGU_PREVIEW:
            return action.payload
        case ACCEPT_CGU:
            return null
        default:
            return state
    }
}

const cguToValidate = createReducer(GET_CGU_TO_VALIDATE)

const lastAcceptedCgu = createReducer(GET_LAST_ACCEPTED_CGU)

export default combineReducers({
    cgu,
    cguToValidate,
    lastAcceptedCgu
})
