import React from 'react'
// lib
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { pure } from 'recompose'

const FormTitle = ({
    label,
    mandatory
}) => {
    return (
        <div className={classnames('form-title', { 'champ-obligatoire': !mandatory })}>
            {label}
            {
                !mandatory && (
                    <FormattedMessage id="global.optional">
                        {
                            (message) => {
                                return <span className="label"> -&nbsp;{message}</span>
                            }
                        }
                    </FormattedMessage>
                )
            }
        </div>
    )
}

FormTitle.propTypes = {
    label: PropTypes.node.isRequired,
    mandatory: PropTypes.bool,
}

FormTitle.defaultProps = {
    mandatory: true
}

export default pure(FormTitle)