import { NOTIFY } from './notificationsActions'

const notifications = (state = [], action) => {
	switch (action.type) {
		case NOTIFY:
			return action.payload
		default:
			return state
	}
}
export default notifications

