import { canCreateGesteProximite } from 'data4-commons/rights'
import { DEMANDE_TYPE, isSocieteClient } from 'data4-commons/global'

export const LIVRAISON = 1
const ENLEVEMENT = 2

export const VALUE_SUIVI_CONSIGNES = 1
export const VALUE_TIERCE_PERSONNE = 2
export const VALUE_PRISE_EN_CHARGE = 3

export const CATGORIES = [LIVRAISON, ENLEVEMENT]

const SUIVI_CONSIGNES = {
    value: VALUE_SUIVI_CONSIGNES
}

const TIERCE_PERSONNE = {
    value: VALUE_TIERCE_PERSONNE
}

const PRISE_EN_CHARGE = {
    value: VALUE_PRISE_EN_CHARGE,
    access: (user, selectedCompany) => isSocieteClient(selectedCompany.type) && canCreateGesteProximite(user)
}

const PLANNED = 2

export const EMERGENCY_VALUES = [PLANNED]

export const DEFAULT_DELIVERY_REQUEST = {
    criticite: PLANNED,
    type: DEMANDE_TYPE.LIVRAISON
}

export const INSTRUCTIONS = [SUIVI_CONSIGNES, TIERCE_PERSONNE, PRISE_EN_CHARGE]

export const LIVRAISON_FORM_SECTION = 'livraison'
export const CONTACT_FORM_SECTION = 'contact'
export const CONTACT_MANDATORY_FIELDS = ['nom', 'prenom', 'telephone']
