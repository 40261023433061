export const SHORT_FLOAT = 'short_float'
export const NO_DECIMALS = 'no_decimals'

export default {
	number: {
        [SHORT_FLOAT]: {
            maximumFractionDigits: 2
        },
        [NO_DECIMALS]: {
            maximumFractionDigits: 0
        }
    }
}
