import { combineReducers } from 'redux'
import { CHECK_USER_GUIDE } from './actions'

const userGuide = (state = false, action) => {
	switch (action.type) {
		case CHECK_USER_GUIDE:
			return action.payload
		default:
			return state
	}
}
export default combineReducers({
    userGuide
})

