import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'RECURRENT_REMOTE_HANDS/'

export const GET_RECURRENT_REMOTE_HANDS = `${prefix}GET_RECURRENT_REMOTE_HANDS`

export const getRecurrentRemoteHands = () => middle(GET_RECURRENT_REMOTE_HANDS, {
    api: '/api',
    url: '/recurrent_remote_hands',
    method: 'GET'
})
