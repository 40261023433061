import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import Modal from './Modal'
import { Button, Form } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { SectionContext } from '../../app/SectionContextProvider'

const formPropNamespace = 'form'

// TODO error ?
class FormModal extends Component {
	static contextType = SectionContext
	render() {
		const {
			[formPropNamespace]: {
				submitting,
				handleSubmit,
                asyncValidating,
                // error
                invalid
            },
            keyboard,
            backdrop,
			modal,
			onClose,
			showCancelButton,
			cancelLabel,
            onCancel,
			submitLabel,
			cssModule,
			children,
            submitDisabledIfError,
            disableSubmit,
			...props
		} = this.props
        const reduxFormInAction = submitting || !!asyncValidating
		return (
			<Modal
				{...props}
                keyboard={!reduxFormInAction && keyboard}
                backdrop={reduxFormInAction ? 'static' : backdrop}
				cssModule={cssModule}
				modal={modal || reduxFormInAction}
				onClose={onClose}
				footer={() => <React.Fragment>
					{
						showCancelButton && (
							<Button
								color="primary"
								outline
								onClick={onCancel || onClose}
								disabled={reduxFormInAction}
								className={`btn-uppercase btn-${this.context.section.toLowerCase()}-outline`}
								cssModule={cssModule}
							>
								{cancelLabel ? cancelLabel : <FormattedMessage id="btn.cancel" />}
							</Button>
						)
					}
					<Button
						color="primary"
						onClick={handleSubmit}
						disabled={reduxFormInAction || (submitDisabledIfError && invalid) || disableSubmit}
						className={`btn-uppercase btn-${this.context.section.toLowerCase()}`}
						cssModule={cssModule}
					>
						{submitLabel ? submitLabel : <FormattedMessage id="btn.send" />}
					</Button>
				</React.Fragment>}
			>
				<Form
					cssModule={cssModule}
					onSubmit={handleSubmit}
					noValidate
				>
					{children}
					{/*{error && <FormErrorMessage message={error} cssModule={cssModule} />}*/}
				</Form>
			</Modal>
		)
	}
}

FormModal = reduxForm({
	propNamespace: formPropNamespace
})(FormModal)

/**
 * Wrapper de modal qui sert à démonter le composant à sa fermeture et qui permet
 * par exemple de réinitialiser les formulaires redux-form.
 */
class SelfDestructModal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			destroyed: false
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.open) {
			this.setState({
				destroyed: false
			})
		}
	}

	destroy = () => {
		this.setState({
			destroyed: true
		})
	}

	render() {
		if (this.state.destroyed) {
			return null
		}
		return <FormModal
			{...this.props}
			onClosed={this.destroy}
		/>
	}
}

SelfDestructModal.propTypes = {
	onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    /**
     * Si le bouton submit est désactivé s'il y a des erreurs de validation
     */
    submitDisabledIfError: PropTypes.bool,
    disableSubmit: PropTypes.bool
}

export default SelfDestructModal
