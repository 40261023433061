import { REQUEST_STATUS } from '../modules/demandes/const'
import { DRAFT_REQUEST_STATUS } from '../modules/draftrequests/const'

const openClassName = 'open'
const inProgressClassName = 'in-progress'
const closeClassName = 'closed'

export const getStatusClassName = (status) => {
    switch(status) {
        case REQUEST_STATUS.OPEN :
        case DRAFT_REQUEST_STATUS.TO_VALIDATE :
            return openClassName
        case REQUEST_STATUS.IN_PROGRESS :
        case DRAFT_REQUEST_STATUS.DENIED :
            return inProgressClassName
        case REQUEST_STATUS.CLOSE :
        case DRAFT_REQUEST_STATUS.VALIDATED :
            return closeClassName
        default:
            return null
    }

}
