import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { compose, withPropsOnChange, setPropTypes, defaultProps } from 'recompose'
import { FormGroup } from 'reactstrap'
import InputMask from 'react-input-mask'
import Datetime from 'react-datetime'
import FormLabel from '../FormLabel'
import { LOCALE_FR, LOCALE_EN } from '../../i18n/supportedLocales'
import { metaShape, inputShape, sizeShape } from './shapes'
import { hasText } from 'data4-commons/utils/stringUtils'
import TimeZoneInformation from '../time/TimeZoneInformation'

const supportedDateTimeFormat = {
    [LOCALE_FR]: {
        dateFormat: 'DD/MM/YYYY',
        timeFormat: 'HH:mm'
    },
    [LOCALE_EN]: {
        dateFormat: 'MM/DD/YYYY',
        timeFormat: 'HH:mm'
    }
}

class DateTimePicker extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleBlur = (value) => {
        if (!this.props.input.value) {
            this.props.input.onBlur(this.props.input.value)
            return
        }
        const date = moment(this.props.input.value, this.props.dateTimeFormat)
        if (date.isValid()) {
            this.props.input.onBlur(date)
            this.setState({
                prevValue: date
            })
        } else {
            this.props.input.onBlur(this.state.prevValue)
        }
    }
    handleChange = (value) => {
        if (!value) {
            this.props.input.onChange(value)
            return
        }
        if (value instanceof moment) {
            const date = moment(value, this.props.dateTimeFormat)
            if (date.isValid()) {
                this.props.input.onChange(date)
                this.setState({
                    prevValue: date
                })
            } else {
                this.props.input.onChange(this.state.prevValue)
            }
        }
    }
    renderInput = ({
        value,
        ...props
    }) => {
        const {
            meta: {
                touched,
                error
            },
            input,
            placeholder
        } = this.props
        const mask = placeholder.replace(/[a-zA-Z]/g, '9')
        return (
            <Fragment>
                <InputMask
                    {...props}
                    value={!input.value ? '' : value}
                    className="form-control"
                    placeholder={placeholder}
                    mask={mask}
                    maskChar={null}
                    autoComplete="off"
                />
                {
                    touched && error && (
                        <div className="error">{error}</div>
                    )
                }
            </Fragment>
        )
    }
    render() {
        const {
            size,
            label,
            mandatory,
            inputProps: customInputProps,
            meta: {
                touched,
                error
            },
            input: {
                name,
                onChange,
                ...input
            },
            dateFormat,
            timeFormat,
            fixed,
            timeZoneLabel,
            timeZone,
            ...props
        } = this.props
        const inputProps = {
            ...customInputProps,
            onChange,
            name
        }
        return (
            <FormGroup
                className={classnames('form-input form-date-picker', {
                    'fixed-position': fixed,
                    [`form-${size}`]: size,
                    'form-error': error && touched
                })}
            >
                {
                    label && (
                        <FormLabel
                            label={label}
                            mandatory={mandatory}
                        />
                    )
                }
                <div className="group-form-control">
                    <Datetime
                        {...props}
                        dateFormat={dateFormat}
                        timeFormat={timeFormat}
                        inputProps={inputProps}
                        {...input}
                        renderInput={this.renderInput}
                        onClose={this.handleBlur}
                        onChange={this.handleChange}
                        closeOnSelect
                    />
                </div>
                {timeZone && timeZoneLabel && (
                    <TimeZoneInformation
                        date={input.value}
                        timeZone={timeZone}
                        tooltip={timeZoneLabel}
                    />
                )}
            </FormGroup>
        )
    }
}

DateTimePicker.propTypes = {
    size: sizeShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    input: inputShape.isRequired,
    meta: metaShape.isRequired,
    inputProps: PropTypes.object,
    /**
     * Indiquer si la position de la popin du datepciker est "fixed" (par défaut c'est "absolute")
     */
    fixed: PropTypes.bool,
    /**
     * Label du fuseau horaire affichées à coté du DateTimePicker.
     * Seulement si la prop timeZone et celle-ci sont à true, les informations sont affichées
     */
    timeZoneLabel: PropTypes.node,
    timeZone: PropTypes.string
}

DateTimePicker.defaultProps = {
    size: 'md',
    timeConstraints: { minutes: { step: 30 } }
}

export default compose(
    setPropTypes({
        /**
         * Si le timepicker est activé, true par défaut
         */
        timePicker: PropTypes.bool,
        /**
         * Si le datepicker est activé, true par défaut
         */
        datePicker: PropTypes.bool
    }),
    defaultProps({
        timePicker: true,
        datePicker: true
    }),
    injectIntl,
    withPropsOnChange(
        ['intl', 'timePicker', 'datePicker'],
        ({ intl, timePicker, datePicker }) => {
            const locale = intl.locale
            let timeFormat = supportedDateTimeFormat[locale].timeFormat
            let dateFormat = supportedDateTimeFormat[locale].dateFormat
            let timeFormatPlaceholder = intl.formatMessage({ id: 'global.time_format' })
            let dateFormatPlaceholder = intl.formatMessage({ id: 'global.date_format' })
            if (!timePicker) {
                timeFormat = false
                timeFormatPlaceholder = ''
            }
            if (!datePicker) {
                dateFormat = false
                dateFormatPlaceholder = ''
            }
            return {
                locale,
                timeFormat,
                dateFormat,
                placeholder: [dateFormatPlaceholder, timeFormatPlaceholder].filter(hasText).join(' '),
                dateTimeFormat: [dateFormat, timeFormat].filter(format => !!format).join(' ')
            }
        }
    )
)(DateTimePicker)

