import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pure } from 'recompose'
import { Field } from 'redux-form'
import { FormGroup, Input } from 'reactstrap'
import FormLabel from '../../FormLabel'
import { metaShape, alignShape } from '../shapes'

const FakeCheckbox = ({
    input,
    checked
}) => (
    <Input
        {...input}
        checked={checked}
        type="checkbox"
    />
)

const ButtonCheckboxGroup = ({
    align,
    label,
    options,
    mandatory,
    fields,
    meta: {
        form,
        error
    },
    ...others
}) => {
    return (
        <FormGroup className="form-checkbox">
            {
                label && (
                    <FormLabel
                        label={label}
                        mandatory={mandatory}
                    />
                )
            }
            <div className={classnames('form-button-group', { 'inline': align === 'row' })}>
                {
                    fields.map((field, index, fields2) => {
                        const option = fields2.get(index)
                        const key = `${form}-${field}-${option.value}`
                        const checked = !!option.checked
                        return (
                            <div
                                key={key}
                                className={classnames('selectable-button btn-circle pretty', { checked })}
                            >
                                <Field
                                    name={field}
                                    component={FakeCheckbox}
                                    checked={checked}
                                    normalize={(value) => ({
                                        ...option,
                                        checked: value
                                    })}
                                />
                                <div className="label">{option.label}</div>
                            </div>
                        )
                    })
                     }
            </div>
            {
                error && (
                    <div className="error">{error}</div>
                )
            }
        </FormGroup>
    )
}

ButtonCheckboxGroup.propTypes = {
    /**
     * Alignement des bouttons, soit vertical ("col"), soit horizontal ("row")
     */
    align: alignShape,
    label: PropTypes.node,
    mandatory: PropTypes.bool,
    meta: metaShape.isRequired
}

export default pure(ButtonCheckboxGroup)
