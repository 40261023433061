import { createSelector } from 'reselect'

const _hostingSpacesSelector = state => state.app.hostingSpaces

export const hostingSpacesLoadingSelector = state => _hostingSpacesSelector(state).loading
export const hostingSpacesErrorSelector = state => _hostingSpacesSelector(state).error
export const hostingSpacesSelector = state => _hostingSpacesSelector(state).value

/**
 * Selector de l'id de site sélectioné en cours.
 * Ce selector doit être connecté aussi aux routes par {@link withRouter} si besoin
 */
export const selectedSiteIdSelector = createSelector([
    hostingSpacesSelector,
    (state, props) => props.match.params.site
], (sites, siteId) => {
    const selectedSite = sites.find(site => site.idSite === siteId)
    if (selectedSite) {
        return selectedSite.idSite
    }
    if (sites.length > 0) {
        return sites[0].idSite
    }
})
