import { combineReducers } from 'redux'
import {
    GET_AREAS_KPI_CONFIG,
    GET_AREAS_KPI_CONFIG_ERROR,
    GET_AREAS_KPI_CONFIG_LOADING,
    CREATE_RAPPORT_ZONE
} from '../actions'

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_AREAS_KPI_CONFIG_LOADING:
            return true
        case GET_AREAS_KPI_CONFIG:
        case GET_AREAS_KPI_CONFIG_ERROR:
            return false
        default:
            return state
    }
}
const error = (state = false, action) => {
    switch (action.type) {
        case GET_AREAS_KPI_CONFIG:
            return false
        case GET_AREAS_KPI_CONFIG_ERROR:
            return true
        default:
            return state
    }
}
const value = (state = [], action) => {
    switch (action.type) {
        case GET_AREAS_KPI_CONFIG:
            return action.payload
        case CREATE_RAPPORT_ZONE:
            const newState = state.map((val) => {
                if (val.id === action.payload.id) {
                    return action.payload
                }
                return val
            })
            return reduceRapportsNbEquipements(newState)
        default:
            return state
    }
}

const reduceRapportsNbEquipements = (rapports) => {
    const dictEquipement = {}
    let buildingRapport = undefined
    const newRapports = rapports.map((rapport) => {
        if (rapport.building) {
            buildingRapport = { ...rapport }
            return buildingRapport
        } else {
            rapport.equipements.forEach(equipement => {
                if (dictEquipement[equipement.idEquipment] === undefined ) {
                    dictEquipement[equipement.idEquipment] = { ...equipement }
                } else {
                    dictEquipement[equipement.idEquipment].nbEquipements += equipement.nbEquipements
                }
            })
            return rapport
        }
    })
    buildingRapport.equipements = Object.values(dictEquipement)
    return newRapports
}

export default combineReducers({
    loading,
    error,
    value
})

